import React, { useEffect, useState } from 'react';
import { cn, Popover } from '@prenuvo/halo-web';
import { LayoutToolButton } from './LayoutToolButton';
import { LayoutSelector } from '@prenuvo/ui';
import {
  hplayout,
  ToolsToggleMenu,
  PrenuvoServices,
  DEFAULT_TOOLS_STATES,
} from '@prenuvo/extension-default';
import { ServicesManager, Types as CoreTypes, CommandsManager, HotkeysManager } from '@ohif/core';

interface LayoutToolsProps {
  servicesManager: ServicesManager;
  commandsManager: CommandsManager;
  hotkeysManager: HotkeysManager;
}

type Protocol = CoreTypes.HangingProtocol.Protocol;
type ProtocolStage = CoreTypes.HangingProtocol.ProtocolStage;
type SetProtocolOptions = CoreTypes.HangingProtocol.SetProtocolOptions;

export const LayoutTools: React.FC<LayoutToolsProps> = ({
  servicesManager,
  commandsManager,
  hotkeysManager,
}) => {
  const { hangingProtocolService, stateSyncService, userPreferencesService } =
    servicesManager.services as PrenuvoServices;
  const [activeHangingProtocol, setActiveHangingProtocol] = useState<Protocol | null>(null);
  const [protocolStage, setProtocolStage] = useState<ProtocolStage | null>(null);
  const [toolsToggleState, setToolsToggleState] = useState<object | null>(null);

  useEffect(() => {
    const { unsubscribe: protocolUnsubscribe } = hangingProtocolService.subscribe(
      hangingProtocolService.EVENTS.PROTOCOL_CHANGED,
      () => {
        const { protocol, stage } = hangingProtocolService.getActiveProtocol();
        setActiveHangingProtocol(protocol);
        setProtocolStage(stage);
      }
    );

    const { unsubscribe: preferencesUnsubscribe } = userPreferencesService.subscribe(
      userPreferencesService.EVENTS.USER_PREFERENCES_UPDATE_COMPLETE,
      () => {
        setToolsToggleState(stateSyncService?.getState().toolsToggleState);
      }
    );

    setToolsToggleState(
      Object.keys(stateSyncService?.getState().toolsToggleState ?? {}).length
        ? stateSyncService.getState().toolsToggleState
        : DEFAULT_TOOLS_STATES
    );

    return () => {
      protocolUnsubscribe();
      preferencesUnsubscribe();
    };
  }, [hangingProtocolService, userPreferencesService, stateSyncService]);

  return (
    <div className={cn('relative flex pr-3')}>
      <Popover
        className={cn('m-0 w-[280px] rounded-[8px] p-0', 'absolute left-[-272px]')} // TODO: remove hardcoded positioning after popover positioning is implemented
        trigger={
          <div>
            <LayoutToolButton
              layoutToolbarItem={{
                label: 'Layout Selector',
                icon: 'tool-layout',
                active: hplayout?.id === activeHangingProtocol?.id,
              }}
            />
          </div>
        }
      >
        <LayoutSelector
          commandsManager={commandsManager}
          layoutItems={hplayout.stages}
          layOutProtocolName={hplayout?.id}
          activeHangingProtocol={activeHangingProtocol?.id || ''}
          stageName={protocolStage?.name || ''}
          onClickHandler={(stageIndex: number) => {
            hangingProtocolService.setProtocol(hplayout?.id, {
              stageIndex,
            } as SetProtocolOptions);
          }}
        />
      </Popover>
      <span className={cn('border-common-dark mx-2 h-8 self-center border-l')} />
      <LayoutToolButton layoutToolbarItem={{ label: 'Key Image', icon: 'tool-key-image' }} />
      <span className={cn('border-common-dark mx-2 h-8 self-center border-l')} />
      <Popover
        className={cn('absolute left-[-272px] m-0 w-[280px] rounded-[8px] p-0')}
        trigger={
          <div>
            <LayoutToolButton
              layoutToolbarItem={{
                label: 'Tools Toggle Menu',
                icon: 'tool-more',
              }}
            />
          </div>
        }
      >
        <ToolsToggleMenu
          commandsManager={commandsManager}
          hotkeysManager={hotkeysManager}
          toolsToggleState={toolsToggleState}
          setToolsToggleState={setToolsToggleState}
        />
      </Popover>
    </div>
  );
};
