import { useUserAuthentication } from '@ohif/ui';
import React from 'react';

export const PrivateRoute = ({ children, handleUnauthenticated }) => {
  const [{ user, enabled }] = useUserAuthentication();

  if (enabled && !user) {
    // PVR-373 An unknown exception is seen when loading the study list
    const authHandle = handleUnauthenticated();
    if (React.isValidElement(authHandle)) {
      return authHandle;
    } else {
      return null;
    }
  }

  return children;
};

export default PrivateRoute;
