export function subscribeOnce(
  eventTarget: EventTarget,
  eventType: string,
  callback: () => void
): void {
  const onceCallback = () => {
    callback();
    eventTarget.removeEventListener(eventType, onceCallback);
  };
  eventTarget.addEventListener(eventType, onceCallback);
}

export function subscribeCancellable(
  eventTarget: EventTarget,
  eventType: string,
  callback: () => void
): () => void {
  const eventCallback = () => {
    callback();
  };

  eventTarget.addEventListener(eventType, eventCallback);
  return () => {
    eventTarget.removeEventListener(eventType, eventCallback);
  };
}
