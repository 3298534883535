import * as Halo from '@prenuvo/halo-icon';

export const VIEWPORT_TITLE_BAR_TOOL_MAP = {
  'tool-key-image': Halo.ImageAdd,
  'tool-expand2': Halo.Expand2Lg,
  'tool-crossLg': Halo.CrossLg,
  'tool-chevronDownLg': Halo.ChevronDownLg,
  'tool-chevronRightLg': Halo.ChevronRightLg,
  'tool-swap': Halo.Grab,
};
