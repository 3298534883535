// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolsToggleActiveSwitch,
.toolsToggleInactiveSwitch {
  width: 52px;
  height: 32px;
  border-radius: 100px;
  border-color: #262626;
  border-width: 1px;
  outline: none;
}

.toolsToggleActiveSwitch span,
.toolsToggleInactiveSwitch span {
  width: 24px;
  height: 24px;
  top: 12px;
  left: 12px;
  border-radius: 24px;
  -webkit-transition: margin-left 0.2s ease;
  transition: margin-left 0.2s ease;
  background-color: white;
}

.toolsToggleActiveSwitch {
  background-color: #00a5eb;
}

.toolsToggleInactiveSwitch {
  background-color: '';
}

.toolsToggleActiveSwitch span {
  margin-left: 23px;
}

.toolsToggleInactiveSwitch span {
  margin-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./../../../prenuvo-extensions/default/src/components/ToolsToggleMenu/ToolsToggleMenu.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,qBAAqB;EACrB,iBAAiB;EACjB,aAAa;AACf;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,yCAAiC;EAAjC,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".toolsToggleActiveSwitch,\n.toolsToggleInactiveSwitch {\n  width: 52px;\n  height: 32px;\n  border-radius: 100px;\n  border-color: #262626;\n  border-width: 1px;\n  outline: none;\n}\n\n.toolsToggleActiveSwitch span,\n.toolsToggleInactiveSwitch span {\n  width: 24px;\n  height: 24px;\n  top: 12px;\n  left: 12px;\n  border-radius: 24px;\n  transition: margin-left 0.2s ease;\n  background-color: white;\n}\n\n.toolsToggleActiveSwitch {\n  background-color: #00a5eb;\n}\n\n.toolsToggleInactiveSwitch {\n  background-color: '';\n}\n\n.toolsToggleActiveSwitch span {\n  margin-left: 23px;\n}\n\n.toolsToggleInactiveSwitch span {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
