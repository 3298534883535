import { LOCAL_STORAGE_ITEMS } from '../../utils';
import { ProtocolJar } from './types/protocol-jar';

export function loadUserDefinedProtocolJar(): Promise<ProtocolJar[]> {
  return new Promise(resolve => {
    const userDefinedPJ = localStorage.getItem(LOCAL_STORAGE_ITEMS.USER_DEFINED_PROTOCOL_JAR);
    if (userDefinedPJ) {
      try {
        const parsedJar = JSON.parse(userDefinedPJ);
        resolve(parsedJar);
      } catch (e) {
        console.error(e.message);
        resolve([]);
      }
    } else {
      resolve([]);
    }
  });
}
