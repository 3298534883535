import { ServicesManager } from '@ohif/core';
import { PrenuvoServices } from '@prenuvo/extension-default';
import { getHangingProtocols } from '../apis';
import _ from 'lodash';

export const initPrenuvoHangingProtocols = async (servicesManager: ServicesManager) => {
  const { hangingProtocolService } = servicesManager.services as PrenuvoServices;
  const hangingProtocols = await getHangingProtocols();
  if (hangingProtocols) {
    const clonedHangingProtocols = _.cloneDeep(hangingProtocols);
    clonedHangingProtocols.forEach(hpItem => {
      hangingProtocolService.addProtocol(hpItem.name, hpItem.protocol);
    });
  }

  if (!hangingProtocolService?.prenuvoHangingProtocols) {
    Object.assign(hangingProtocolService, {
      prenuvoHangingProtocols: hangingProtocols,
    });
  }
};
