import * as React from 'react';
export const PrenuvoMark = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={20}
    fill="none"
    aria-label="Prenuvo Mark"
    role="img"
    {...props}
  >
    <path
      fill="#F9F9F9"
      d="M0 9.443c0 5.205 4.317 9.44 9.624 9.443H.568a.563.563 0 0 0-.568.556c0 .308.255.558.568.558h31.864c.313 0 .568-.25.568-.558a.563.563 0 0 0-.569-.557H9.636c5.307-.003 9.624-4.238 9.624-9.442 0-5.205-4.32-9.443-9.63-9.443C4.32 0 0 4.236 0 9.443Zm18.122 0c0 4.592-3.81 8.328-8.493 8.328s-8.493-3.736-8.493-8.328c0-4.592 3.81-8.328 8.493-8.328s8.493 3.736 8.493 8.328Z"
    />
  </svg>
);

PrenuvoMark.displayName = 'PrenuvoMark';
