import { DisplaySetService, Types } from '@ohif/core';
import { SR_SERIES_DESCRIPTION, PrenuvoTypes, MODALITIES } from '@prenuvo/extension-default';

export function findSRInSameStudy(
  StudyInstanceUID: string,
  displaySetService: DisplaySetService
): Types.SeriesMetadata {
  const sameDisplayset = displaySetService.getDisplaySetsBy(
    ds => ds.Modality === MODALITIES.SR && ds.StudyInstanceUID === StudyInstanceUID
  )[0] as PrenuvoTypes.SRDisplaySet;

  if (sameDisplayset) {
    const { instance } = sameDisplayset;
    const { SeriesInstanceUID, SeriesDescription, SeriesDate, SeriesTime, SeriesNumber, Modality } =
      instance;
    return {
      SeriesInstanceUID,
      SeriesDescription,
      SeriesDate,
      SeriesTime,
      SeriesNumber,
      Modality,
      InstanceNumber: sameDisplayset.instances.length + 1,
    };
  }
  return { SeriesDescription: SR_SERIES_DESCRIPTION.NON_KEY_IMAGE_MEASUREMENT, SeriesNumber: 0 };
}
