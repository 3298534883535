import React from 'react';
import { cn } from '@prenuvo/halo-web';
import { ViewportToolButtonIcons } from './ViewportToolButtonIcons';
import { ViewportToolsProps } from './ViewportTitleBar.types';

export const ViewportTitleBarButton = ({
  onButtonClick,
  viewportToolbarItems = [],
}: ViewportToolsProps) => {
  return (
    <div
      className={cn('relative flex h-[32px] cursor-pointer items-center justify-center gap-2.5')}
    >
      {viewportToolbarItems?.length > 0 &&
        viewportToolbarItems.map(item => (
          <ViewportToolButtonIcons
            key={item.label}
            viewportToolbarItem={{ ...item, onButtonClick }}
          />
        ))}
    </div>
  );
};
