import type { Types } from '@cornerstonejs/core';
import { utilities as csUtils, getEnabledElement } from '@cornerstonejs/core';
import {
  annotation,
  AnnotationTool,
  Types as cstTypes,
  drawing,
  Enums,
  state,
  utilities,
} from '@cornerstonejs/tools';

import { SL } from '../constants';
import { InteractionEventType } from '@cornerstonejs/tools/dist/types/types/EventTypes';
import { DefaultConstants } from '@prenuvo/extension-default';
import { Direction } from '../../types';
import { TOOL_NAMES, triggerAnnotationModified } from '../../utils';

const Events = Enums.Events;
const { addAnnotation, getAnnotations, removeAnnotation } = annotation.state;
const { drawTextBox } = drawing;
const { getViewportIdsWithToolToRender } = utilities.viewportFilters;
const { triggerAnnotationRenderForViewportIds } = utilities;

export interface SpineLabelAnnotation extends cstTypes.Annotation {
  data: {
    text: string;
    label: string;
    handles: {
      points: Types.Point3[];
      activeHandleIndex: number | null;
      textBox: {
        hasAnnotationMoved: boolean;
        worldPosition: Types.Point3;
        worldBoundingBox: {
          topLeft: Types.Point3;
          topRight: Types.Point3;
          bottomLeft: Types.Point3;
          bottomRight: Types.Point3;
        };
      };
    };
  };
}

class SpineLabelTool extends AnnotationTool {
  static toolName;
  static labelPositions: {
    nextTopIndex: number | null;
    nextBottomIndex: number | null;
    firstLabelCoords: Types.Point3;
    lastLabelCoords: Types.Point3;
  } = {
    nextTopIndex: 0,
    nextBottomIndex: 0,
    firstLabelCoords: [0, 0, 0],
    lastLabelCoords: [0, 0, 0],
  };
  annotationDataToUpdate: {
    annotation: cstTypes.Annotation;
    viewportIdsToRender: string[];
    handleIndex?: number;
    isMovingTextBox?: boolean;
    isNewAnnotation?: boolean;
    hasAnnotationMoved?: boolean;
  };
  isHandleOutsideImage: boolean;

  constructor(
    toolProps: cstTypes.PublicToolProps = {},
    defaultToolProps: cstTypes.ToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        preventHandleOutsideImage: false,
        selectedSpineLabel: '',
      },
    }
  ) {
    super(toolProps, defaultToolProps);
  }

  /**
   * Creates a new spine label annotation at the given point.
   * @param event The interaction event that triggered the annotation creation.
   * @returns The newly created SpineLabelAnnotation object.
   */
  public addNewAnnotation = (
    event: cstTypes.EventTypes.InteractionEventType
  ): SpineLabelAnnotation => {
    event.stopPropagation();
    const eventDetail = event.detail;
    const { currentPoints, element } = eventDetail;
    const currentWorldPosition = currentPoints.world;
    const enabledElement = getEnabledElement(element);
    const { viewport, renderingEngine } = enabledElement;
    const camera = viewport.getCamera();
    const { viewPlaneNormal, viewUp } = camera;

    const referencedImageId = this.getReferencedImageId(
      viewport,
      currentWorldPosition,
      viewPlaneNormal,
      viewUp
    );

    const FrameOfReferenceUID = viewport.getFrameOfReferenceUID();
    const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());
    const existingAnnotations = getAnnotations(this.getToolName(), element);

    const annotation = this.getAnnotation(
      FrameOfReferenceUID,
      referencedImageId,
      currentWorldPosition,
      viewUp,
      viewPlaneNormal
    );

    if (!this.configuration.selectedSpineLabel) {
      this.configuration.showWarning(
        SL.WARNINGS.T_UNABLE_LABELLING,
        SL.WARNINGS.SELECT_SL_TO_PROCEED
      );
      return annotation;
    }

    if (this.isPointOutsideImageBounds(currentWorldPosition, viewport.getImageData())) {
      this.configuration.showWarning(
        SL.WARNINGS.T_UNABLE_LABELLING,
        SL.WARNINGS.SL_OUTSIDE_BOUNDARIES
      );
      return annotation;
    }

    addAnnotation(annotation, element);

    this.setNextSpineLabel(existingAnnotations, annotation, viewportIdsToRender, event);
    event.preventDefault();
    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    return annotation;
  };

  private getAnnotation(
    FrameOfReferenceUID,
    referencedImageId,
    currentWorldPosition,
    viewUp,
    viewPlaneNormal
  ) {
    return {
      highlighted: true,
      invalidated: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: [...viewPlaneNormal] as Types.Point3,
        viewUp: [...viewUp] as Types.Point3,
        FrameOfReferenceUID,
        referencedImageId,
      },
      data: {
        handles: {
          points: [
            [...currentWorldPosition] as Types.Point3,
            [...currentWorldPosition] as Types.Point3,
          ],
          activeHandleIndex: null,
          textBox: {
            hasAnnotationMoved: false,
            worldPosition: [0, 0, 0] as Types.Point3,
            worldBoundingBox: {
              topLeft: [0, 0, 0] as Types.Point3,
              topRight: [0, 0, 0] as Types.Point3,
              bottomLeft: [0, 0, 0] as Types.Point3,
              bottomRight: [0, 0, 0] as Types.Point3,
            },
          },
        },
        text: '',
        label: '',
      },
    };
  }

  /**
   * Sets the initial label index and updates the next top and bottom label indices.
   *
   * @param {string} currentLabel - The current spine label to set as the initial label.
   * @returns {void}
   *
   * @description
   * This method initializes the spine labeling process by setting the next top and bottom
   * label indices based on the provided current label. It handles four cases:
   *
   * 1. Non-existent label: Both nextTopLabelIndex and nextBottomLabelIndex are set to null.
   * 2. First label (index 0): nextTopLabelIndex is set to null, nextBottomLabelIndex to 1.
   * 3. Last label (index 28): nextTopLabelIndex is set to 27, nextBottomLabelIndex to null.
   * 4. Middle labels: nextTopLabelIndex is set to currentIndex - 1, nextBottomLabelIndex to currentIndex + 1.
   *
   * The method uses the SL.LABELS array to determine the index of the current label.
   */
  private setInitialLabelIndex(currentLabel: string) {
    const currentLabelIndex = SL.LABELS.indexOf(currentLabel);
    if (currentLabelIndex === 0) {
      // First label
      SpineLabelTool.labelPositions.nextTopIndex = null;
      SpineLabelTool.labelPositions.nextBottomIndex = 1;
    } else if (currentLabelIndex === SL.LABELS.length - 1) {
      // Last label
      SpineLabelTool.labelPositions.nextTopIndex = currentLabelIndex - 1;
      SpineLabelTool.labelPositions.nextBottomIndex = null;
    } else {
      // Middle labels
      SpineLabelTool.labelPositions.nextTopIndex = currentLabelIndex - 1;
      SpineLabelTool.labelPositions.nextBottomIndex = currentLabelIndex + 1;
    }
  }

  /**
   * Sets the next spine label for the given annotation based on existing annotations and current tool state.
   *
   * @param {cstTypes.Annotation[]} existingAnnotations - Array of existing spine label annotations.
   * @param {cstTypes.Annotation} annotation - The current annotation being labeled.
   * @param {string[]} viewportIdsToRender - Array of viewport IDs to render.
   * @param {InteractionEventType} event - The interaction event that triggered this method.
   * @returns {void}
   */
  private setNextSpineLabel(
    existingAnnotations: cstTypes.Annotation[],
    annotation: cstTypes.Annotation,
    viewportIdsToRender: string[],
    event: InteractionEventType
  ) {
    if (existingAnnotations.length === 0) {
      this.handleFirstAnnotation(annotation);
    } else {
      this.handleSubsequentAnnotation(annotation);
    }

    this.updateAnnotationData(annotation, viewportIdsToRender);
    this.onInteractionEnd(event);
  }

  private handleFirstAnnotation(annotation: cstTypes.Annotation) {
    if (this.configuration.selectedSpineLabel) {
      annotation.data.text = this.configuration.selectedSpineLabel;
      annotation.data.label = this.configuration.selectedSpineLabel;
      this.setInitialLabelIndex(this.configuration.selectedSpineLabel);
      SpineLabelTool.labelPositions.firstLabelCoords = annotation.data.handles.points[0];
      SpineLabelTool.labelPositions.lastLabelCoords = annotation.data.handles.points[0];
    }
  }

  private handleSubsequentAnnotation(annotation: cstTypes.Annotation) {
    const currentLabelPosition = annotation.data.handles.points[0];
    const positionRelativeToLast = this.getDirectionFromCoordinates(
      currentLabelPosition,
      SpineLabelTool.labelPositions.lastLabelCoords as Types.Point3
    );

    if (positionRelativeToLast === DefaultConstants.DIRECTIONS.TOP) {
      this.handleTopAnnotation(annotation, currentLabelPosition);
    } else if (positionRelativeToLast === DefaultConstants.DIRECTIONS.BOTTOM) {
      this.handleBottomAnnotation(annotation, currentLabelPosition);
    } else {
      this.showUnableLabellingWarning();
    }
  }

  private handleTopAnnotation(annotation: cstTypes.Annotation, currentLabelPosition: Types.Point3) {
    const positionRelativeToFirst = this.getDirectionFromCoordinates(
      currentLabelPosition,
      SpineLabelTool.labelPositions.firstLabelCoords as Types.Point3
    );

    if (
      positionRelativeToFirst === DefaultConstants.DIRECTIONS.TOP &&
      SpineLabelTool.labelPositions.nextTopIndex !== null
    ) {
      this.setTopLabel(annotation, currentLabelPosition);
    } else {
      this.showUnableLabellingWarning();
    }
  }

  private handleBottomAnnotation(
    annotation: cstTypes.Annotation,
    currentLabelPosition: Types.Point3
  ) {
    const positionRelativeToLast = this.getDirectionFromCoordinates(
      currentLabelPosition,
      SpineLabelTool.labelPositions.lastLabelCoords as Types.Point3
    );

    if (
      positionRelativeToLast === DefaultConstants.DIRECTIONS.BOTTOM &&
      SpineLabelTool.labelPositions.nextBottomIndex !== null
    ) {
      this.setBottomLabel(annotation, currentLabelPosition);
    } else {
      this.showUnableLabellingWarning();
    }
  }

  private setTopLabel(annotation: cstTypes.Annotation, currentLabelPosition: Types.Point3) {
    annotation.data.text = SL.LABELS[SpineLabelTool.labelPositions.nextTopIndex!];
    annotation.data.label = SL.LABELS[SpineLabelTool.labelPositions.nextTopIndex!];
    if (SpineLabelTool.labelPositions.nextTopIndex! - 1 >= 0) {
      SpineLabelTool.labelPositions.nextTopIndex! -= 1;
      SpineLabelTool.labelPositions.firstLabelCoords = currentLabelPosition;
    } else {
      SpineLabelTool.labelPositions.nextTopIndex = null;
    }
  }

  private setBottomLabel(annotation: cstTypes.Annotation, currentLabelPosition: Types.Point3) {
    annotation.data.text = SL.LABELS[SpineLabelTool.labelPositions.nextBottomIndex!];
    annotation.data.label = SL.LABELS[SpineLabelTool.labelPositions.nextBottomIndex!];
    if (SpineLabelTool.labelPositions.nextBottomIndex! + 1 <= SL.LABELS.length - 1) {
      SpineLabelTool.labelPositions.nextBottomIndex! += 1;
      SpineLabelTool.labelPositions.lastLabelCoords = currentLabelPosition;
    } else {
      SpineLabelTool.labelPositions.nextBottomIndex = null;
    }
  }

  private updateAnnotationData(annotation: cstTypes.Annotation, viewportIdsToRender: string[]) {
    this.annotationDataToUpdate = {
      annotation,
      viewportIdsToRender,
      hasAnnotationMoved: true,
    };
  }

  private showUnableLabellingWarning() {
    this.configuration.showWarning(SL.WARNINGS.T_UNABLE_LABELLING, SL.WARNINGS.UNABLE_LABELLING);
  }

  /**
   * Compares the z-axis (reference axis) values of two 3D points and determines
   * if the new point is above, below, or at the same level as the old point.
   *
   * @param newPoint - The new 3D point to compare.
   * @param oldPoint - The old 3D point to compare against.
   * @returns 'top' if newPoint is higher, 'bottom' if lower, or 'same' if both points are on the same level.
   */
  private getDirectionFromCoordinates(newPoint: Types.Point3, oldPoint: Types.Point3): Direction {
    const referenceAxis = DefaultConstants.COORDINATE_INDICES.Z;
    if (newPoint[referenceAxis] > oldPoint[referenceAxis]) {
      return DefaultConstants.DIRECTIONS.TOP;
    } else if (newPoint[referenceAxis] < oldPoint[referenceAxis]) {
      return DefaultConstants.DIRECTIONS.BOTTOM;
    }
    return DefaultConstants.DIRECTIONS.SAME;
  }

  // function required in AnnotationTool descendants
  public isPointNearTool = (element, annotation, canvasCoords, proximity) => {
    return false;
  };

  /**
   * Callback function when the tool is selected.
   * @param event The interaction event that triggered the selection.
   * @param annotation The selected annotation.
   */
  public toolSelectedCallback = (
    event: cstTypes.EventTypes.InteractionEventType,
    annotation: SpineLabelAnnotation
  ): void => {
    const eventDetail = event.detail;
    const { element } = eventDetail;
    const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

    annotation.highlighted = true;
    this.annotationDataToUpdate = {
      annotation,
      viewportIdsToRender,
    };

    this.enableAnnotationModification(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
    event.preventDefault();
  };

  /**
   * Callback function when a handle of the annotation is selected.
   * @param event The interaction event that triggered the selection.
   * @param annotation The annotation whose handle was selected.
   * @param handle The selected handle.
   */
  public handleSelectedCallback(
    event: cstTypes.EventTypes.InteractionEventType,
    annotation: SpineLabelAnnotation,
    handle: cstTypes.ToolHandle
  ): void {
    const eventDetail = event.detail;
    const { element } = eventDetail;
    const { data } = annotation;
    let isMovingTextBox = false;
    let handleIndex;

    annotation.highlighted = true;

    if ((handle as cstTypes.TextBoxHandle).worldPosition) {
      isMovingTextBox = true;
    } else {
      handleIndex = data.handles.points.findIndex(p => p === handle);
    }

    const viewportIdsToRender = getViewportIdsWithToolToRender(element, this.getToolName());

    this.annotationDataToUpdate = {
      annotation,
      viewportIdsToRender,
      handleIndex,
      isMovingTextBox,
    };
    this.enableAnnotationModification(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
    event.preventDefault();
  }

  /**
   * Handles the dragging interaction for the annotation.
   * @param event The interaction event containing drag information.
   */
  private onInteractionDrag = (event: cstTypes.EventTypes.InteractionEventType): void => {
    const eventDetail = event.detail;
    const { element } = eventDetail;

    const { annotation, viewportIdsToRender, handleIndex } = this.annotationDataToUpdate;
    const { data } = annotation;
    const enabledElement = getEnabledElement(element);
    const { renderingEngine, viewport } = enabledElement;

    if (handleIndex === undefined) {
      // Drag mode - moving handle
      const { deltaPoints } = eventDetail as cstTypes.EventTypes.MouseDragEventDetail;
      const worldPosDelta = deltaPoints.world;
      const points = data.handles.points;

      const newPoints = points.map(point => [
        point[DefaultConstants.COORDINATE_INDICES.X] +
          worldPosDelta[DefaultConstants.COORDINATE_INDICES.X],
        point[DefaultConstants.COORDINATE_INDICES.Y] +
          worldPosDelta[DefaultConstants.COORDINATE_INDICES.Y],
        point[DefaultConstants.COORDINATE_INDICES.Z] +
          worldPosDelta[DefaultConstants.COORDINATE_INDICES.Z],
      ]);

      if (this.isPointOutsideImageBounds(newPoints[0], viewport.getImageData())) {
        annotation.invalidated = true;
      } else {
        data.handles.points = newPoints as Types.Point3[];
        annotation.invalidated = true;
      }
    } else {
      // Move mode - after double click, and mouse move to draw
      const { currentPoints } = eventDetail;
      const currentWorldPosition = currentPoints.world;

      data.handles.points[handleIndex] = [...currentWorldPosition];
      annotation.invalidated = true;
    }

    this.annotationDataToUpdate.hasAnnotationMoved = true;

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);
  };

  /**
   * Handles the end of an interaction with the annotation.
   * @param event The interaction event signaling the end of the interaction.
   */
  private onInteractionEnd = (event: cstTypes.EventTypes.InteractionEventType): void => {
    const eventDetail = event.detail;
    const { element } = eventDetail;

    if (!this.annotationDataToUpdate) {
      return;
    }

    const { annotation, isNewAnnotation, hasAnnotationMoved } = this.annotationDataToUpdate;
    const { data } = annotation;

    if (isNewAnnotation && !hasAnnotationMoved) {
      // when user starts the drawing by click, and moving the mouse, instead
      // of click and drag
      return;
    }

    data.handles.activeHandleIndex = null;

    this.disableAnnotationModification(element);

    if (this.isHandleOutsideImage && this.configuration.preventHandleOutsideImage) {
      removeAnnotation(annotation.annotationUID);
    }
    triggerAnnotationModified(annotation, element);
    this.annotationDataToUpdate = null;
  };

  /**
   * Cancels the current annotation modification.
   * @param element The HTML element containing the viewport.
   * @returns The unique identifier of the canceled annotation.
   */
  public cancel = (element: HTMLDivElement) => {
    const { annotation, viewportIdsToRender } = this.annotationDataToUpdate;
    const { data } = annotation;

    annotation.highlighted = false;
    data.handles.activeHandleIndex = null;

    const { renderingEngine } = getEnabledElement(element);

    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender);

    this.annotationDataToUpdate = null;
    return annotation.annotationUID;
  };

  /**
   * Checks if a given point is outside the bounds of the image.
   * @param currentWorldPosition The world coordinates of the point to check.
   * @param image The image data to check against.
   * @returns True if the point is outside the image bounds, false otherwise.
   */
  private isPointOutsideImageBounds(currentWorldPosition, image) {
    if (!image) {
      return false;
    }

    const { imageData, dimensions } = image;
    const index1 = csUtils.transformWorldToIndex(imageData, currentWorldPosition);

    return !csUtils.indexWithinDimensions(index1, dimensions);
  }

  /**
   * Enables modification of the annotation by adding event listeners.
   * @param element The HTML element to attach the event listeners to.
   */
  private enableAnnotationModification = (element: HTMLDivElement) => {
    state.isInteractingWithTool = true;
    element.addEventListener(Events.MOUSE_UP, this.onInteractionEnd as EventListener);
    element.addEventListener(Events.MOUSE_DRAG, this.onInteractionDrag as EventListener);
    element.addEventListener(Events.MOUSE_CLICK, this.onInteractionEnd as EventListener);
    element.addEventListener(Events.TOUCH_END, this.onInteractionEnd as EventListener);
    element.addEventListener(Events.TOUCH_DRAG, this.onInteractionDrag as EventListener);
    element.addEventListener(Events.TOUCH_TAP, this.onInteractionEnd as EventListener);
  };

  /**
   * Disables modification of the annotation by removing event listeners.
   * @param element The HTML element to remove the event listeners from.
   */
  private disableAnnotationModification = (element: HTMLDivElement) => {
    state.isInteractingWithTool = false;
    element.removeEventListener(Events.MOUSE_UP, this.onInteractionEnd as EventListener);
    element.removeEventListener(Events.MOUSE_DRAG, this.onInteractionDrag as EventListener);
    element.removeEventListener(Events.MOUSE_CLICK, this.onInteractionEnd as EventListener);
    element.removeEventListener(Events.TOUCH_END, this.onInteractionEnd as EventListener);
    element.removeEventListener(Events.TOUCH_DRAG, this.onInteractionDrag as EventListener);
    element.removeEventListener(Events.TOUCH_TAP, this.onInteractionEnd as EventListener);
  };

  /**
   * Renders the annotation on the viewport.
   * @param enabledElement The enabled element containing the viewport.
   * @param svgDrawingHelper Helper object for SVG drawing.
   * @returns True if the annotation was rendered successfully, false otherwise.
   */
  public renderAnnotation = (
    enabledElement: Types.IEnabledElement,
    svgDrawingHelper: cstTypes.SVGDrawingHelper
  ): boolean => {
    const { viewport } = enabledElement;
    const { element } = viewport;

    const annotations = getAnnotations(this.getToolName(), element);

    if (!annotations?.length) {
      return false;
    }

    const styleSpecifier: cstTypes.AnnotationStyle.StyleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };
    // Draw SVG for each annotation
    annotations.forEach(annotation => {
      const { annotationUID, data } = annotation as SpineLabelAnnotation;
      const { handles, label } = data;

      if (!label) {
        return;
      }

      // Check if rendering engine is available
      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');

        return false;
      }

      const imageData = viewport.getImageData();
      const isPointOutsideImageBounds = this.isPointOutsideImageBounds(
        handles.points[0],
        imageData
      );

      if (isPointOutsideImageBounds) {
        return false;
      }

      const canvasCoordinates = handles.points.map(p => viewport.worldToCanvas(p));
      const renderOptions = this.getLinkedTextBoxStyle(styleSpecifier, annotation);

      data.handles.points[1] = viewport.canvasToWorld(canvasCoordinates[0]);
      const labelPosition: Types.Point2 = [
        canvasCoordinates[0][0] - SL.ANNOTATION_CONFIG.LABEL_OFFSET.X,
        canvasCoordinates[0][1] - SL.ANNOTATION_CONFIG.LABEL_OFFSET.Y,
      ];

      // Update labelText box position if it hasn't moved
      if (!data.handles.textBox.hasAnnotationMoved) {
        data.handles.textBox.worldPosition = viewport.canvasToWorld(labelPosition);
      }

      const boundingBox = drawTextBox(
        svgDrawingHelper,
        annotationUID,
        `sl_label_${annotationUID}`,
        [label],
        labelPosition,
        renderOptions
      );

      const { x: left, y: top, width, height } = boundingBox;
      const right = left + width;
      const bottom = top + height;

      data.handles.textBox.worldBoundingBox = {
        topLeft: viewport.canvasToWorld([left, top]),
        topRight: viewport.canvasToWorld([
          right + SL.ANNOTATION_CONFIG.OFFSET[DefaultConstants.COORDINATE_INDICES.Y],
          top,
        ]),
        bottomLeft: viewport.canvasToWorld([
          left,
          bottom + SL.ANNOTATION_CONFIG.OFFSET[DefaultConstants.COORDINATE_INDICES.Y],
        ]),
        bottomRight: viewport.canvasToWorld([
          right + SL.ANNOTATION_CONFIG.OFFSET[DefaultConstants.COORDINATE_INDICES.Z],
          bottom + SL.ANNOTATION_CONFIG.OFFSET[DefaultConstants.COORDINATE_INDICES.Z],
        ]),
      };
    });

    return true;
  };
}

SpineLabelTool.toolName = TOOL_NAMES.SPINELABEL;
export { SpineLabelTool };
