import { ServicesManager, Types } from '@ohif/core';
import { addTool } from '@cornerstonejs/tools';
import { UserPreferencesService } from '../services/UserPreferencesService';
import { PointCrossSyncTool, SpineLabelTool, SpineLabel } from '../tools';
import { ProtocolJarService } from '../services/ProtocolJarService';
import { PatientStudyListService } from '../services/PatientStudyListService';
import { initPrenuvoHangingProtocols } from '../hp';
import { DisplaySet } from '../types';
import { getDisplaySetsMatchingHangingProtocols, isDisplaySetFromSelection } from '../utils';
import { PrenuvoServices } from '../services';
import { _getDisplaySets, setServicesManager } from '../utils/serviceExtensions';
import { adaptersSR } from '@cornerstonejs/adapters';
const { MeasurementReport } = adaptersSR.Cornerstone3D;

function addCustomTools() {
  addTool(PointCrossSyncTool);
  addTool(SpineLabelTool);
  MeasurementReport.registerTool(SpineLabel);
}

function addHPCustomAttributes(servicesManager: ServicesManager) {
  const { hangingProtocolService, viewportGridService, displaySetService } =
    servicesManager.services;
  hangingProtocolService.addCustomAttribute(
    'isDisplaySetFromSelection',
    'DisplaySet From Active displayset Selection',
    displayset =>
      isDisplaySetFromSelection(displayset as DisplaySet, viewportGridService, displaySetService)
  );
}

/**
 * Loads config point configuration from the
 * public/theme/<themeName> directory, as specified by the "theme" url parameter.
 *
 * @returns async result to load config point registration
 */
export async function preRegistration({
  servicesManager,
}: Types.Extensions.ExtensionParams): Promise<void> {
  addCustomTools();
  addHPCustomAttributes(servicesManager);
  [
    ProtocolJarService.REGISTRATION,
    PatientStudyListService.REGISTRATION,
    UserPreferencesService.REGISTRATION,
  ].forEach(serviceObj => servicesManager.registerService(serviceObj));
  const { protocolJarService, hangingProtocolService, studyPrefetcherService } =
    servicesManager.services as PrenuvoServices;

  initPrenuvoHangingProtocols(servicesManager);

  // Attaching getDisplaySetsMatchingHangingProtocols into the hangingProtocolService without modifying platform code.
  hangingProtocolService.getDisplaySetsMatchingHangingProtocols =
    getDisplaySetsMatchingHangingProtocols;
  setServicesManager(servicesManager);
  studyPrefetcherService._getDisplaySets = _getDisplaySets;
  protocolJarService.loadProtocolJars();

  const { userPreferencesService } = servicesManager.services as PrenuvoServices;
  // TODO: when a radiologist can sign in, then we can use their userId for this new service
  // which hasn't been built yet - for now simply load the default user preferences which returns static data
  //await userPreferencesService.loadUserPreferences(userId);
  await userPreferencesService.loadDefaultUserPreferences();

  return;
}
