import { LOCAL_STORAGE_ITEMS } from '../utils';

type _CustomLayoutData = {
  maxCols: number;
  maxRows: number;
  rowCountPerCol: {
    id: string;
    count: number;
  }[];
};

type _ViewportLayout = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type _Viewport = {
  viewportOptions: {
    toolGroupId: string;
  };
  displaySets: [
    {
      matchedDisplaySetsIndex: number;
      id: string;
    },
  ];
};

const customLayoutDataFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_ITEMS.CUSTOM_LAYOUTS);

const customLayoutData: _CustomLayoutData[] = customLayoutDataFromLocalStorage
  ? JSON.parse(customLayoutDataFromLocalStorage)
  : [];

const displaySets = [
  {
    id: 'defaultDisplaySetId',
    seriesMatchingRules: [],
  },
];

const viewportOptions = {
  toolGroupId: 'default',
};

function getViewportLayouts(layoutData: _CustomLayoutData) {
  const layouts: _ViewportLayout[] = [];
  for (let i = 0; i < layoutData?.maxCols; i++) {
    for (let j = 0; j < layoutData?.rowCountPerCol[i].count; j++) {
      const layout = {
        x: i / layoutData?.maxCols,
        y: j / layoutData?.rowCountPerCol[i].count,
        width: 1 / layoutData?.maxCols,
        height: 1 / layoutData?.rowCountPerCol[i].count,
      };
      layouts.push(layout);
    }
  }
  return layouts;
}

function calculateTotalViewports(layoutData: _CustomLayoutData) {
  if (!layoutData?.rowCountPerCol) {
    return 0;
  }
  let total = 0;

  layoutData.rowCountPerCol.forEach(col => {
    total += col.count || 0;
  });

  return total;
}

function getViewportsData(layoutData: _CustomLayoutData) {
  const totalViewports = calculateTotalViewports(layoutData);
  const viewports: _Viewport[] = [];

  for (let i = 0; i < totalViewports; i++) {
    viewports.push({
      viewportOptions: viewportOptions,
      displaySets: [
        {
          matchedDisplaySetsIndex: i,
          id: 'defaultDisplaySetId',
        },
      ],
    });
  }
  return viewports;
}

const customProtocol = customLayoutData?.map(layoutData => ({
  name: 'custom',
  viewportStructure: {
    type: 'grid',
    layoutType: 'grid',
    properties: {
      rows: layoutData?.maxRows,
      columns: layoutData?.maxCols,
      layoutOptions: getViewportLayouts(layoutData),
    },
  },
  displaySets: displaySets,
  viewports: getViewportsData(layoutData),
}));

export { customProtocol };
