import React from 'react';
import { Stack } from '@prenuvo/halo-web';
import { SeriesInfo } from './SeriesInfo';
import { ViewportTitleBarProps } from './ViewportTitleBar.types';
import { ViewportTitleBarButton } from './ViewportTitleBarButton';

export const ViewportTitleBar = ({
  className,
  onButtonClick,
  seriesDescription,
  date,
  viewportToolbarItems,
  priorPrefix,
  studyColor,
  ...props
}: ViewportTitleBarProps) => {
  return (
    <Stack className="flex h-[36px] items-center justify-between rounded-t border border-[#2B2B2B] bg-stone-900 px-[4px] py-[12px]">
      <SeriesInfo
        seriesDescription={seriesDescription}
        date={date}
        priorPrefix={priorPrefix}
        studyColor={studyColor}
      />
      <Stack className="flex h-[32px] justify-end pr-[4px]">
        <ViewportTitleBarButton
          onButtonClick={onButtonClick}
          viewportToolbarItems={viewportToolbarItems}
        />
      </Stack>
    </Stack>
  );
};
