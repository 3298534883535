// function to compare two number arrays
export const equalsCheck = (a, b): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

// function to add two vectors
export function arraySum(array1: number[], array2: number[]): number[] {
  if (array1.length !== array2.length) {
    throw new Error('Input arrays must have the same length');
  }
  const arraySum = [...array1];
  for (let i = 0; i < array1.length; i++) {
    arraySum[i] += array2[i];
  }
  return arraySum;
}
