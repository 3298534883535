import axios from 'axios';
import { ProtocolJar } from '../services/ProtocolJarService/types/protocol-jar';

export const getHangingProtocols = async () => {
  try {
    const response = await axios.get('prenuvoHangingProtocols.json');
    return response.data.hangingProtocols;
  } catch (error) {
    console.error('Error', error);
    return [];
  }
};
export const getProtocolJars: () => Promise<ProtocolJar[]> = async () => {
  try {
    const response = await axios.get('protocol-jars.json');
    return response.data.protocolJars;
  } catch (error) {
    console.error('Error', error);
    return [];
  }
};
