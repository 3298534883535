import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { Stack, cn, Typography } from '@prenuvo/halo-web';
import { CursorTooltipProps } from './cursor-tooltip.type';

const TOOLTIP_DELAY = 500;
const TOOLTIP_BACKGROUND_COLOR = '#323234';
const TOOLTIP_TEXT_COLOR = '#F9F9F9';

export const CursorTooltip: React.FC<CursorTooltipProps> = ({ children, content }) => {
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const stackRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = useCallback((event: React.MouseEvent) => {
    const { clientX, clientY } = event;
    setCursorPosition({ x: clientX, y: clientY });

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setTooltipVisible(true);
    }, TOOLTIP_DELAY);

    setTooltipVisible(false);
  }, []);

  const handleDisableTooltip = useCallback(() => {
    setTooltipVisible(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const position = useMemo(() => {
    if (tooltipVisible && stackRef.current) {
      const rect = stackRef.current.getBoundingClientRect();
      return {
        top: cursorPosition.y - rect.top + window.scrollY + 20,
        left: cursorPosition.x - rect.left + window.scrollX + 10,
      };
    }
    return null;
  }, [tooltipVisible, cursorPosition]);

  return (
    <Stack
      ref={stackRef}
      onMouseLeave={handleDisableTooltip}
      onMouseMove={handleMouseMove}
      className={cn('relative cursor-pointer')}
    >
      {tooltipVisible && position && content && (
        <Stack
          className={cn(
            `pointer-events-none absolute z-10 w-[85px] gap-[10px] rounded-[4px] bg-[${TOOLTIP_BACKGROUND_COLOR}] py-[4px] px-[8px] text-white`
          )}
          style={{ top: position.top, left: position.left }}
          role="tooltip"
          aria-hidden={!tooltipVisible}
        >
          <Stack className={cn('h-auto w-[70px] items-center justify-center')}>
            <Typography
              className={cn(
                `font-lato size-[12px] font-[500] leading-[18px] text-[${TOOLTIP_TEXT_COLOR}]`
              )}
            >
              {content}
            </Typography>
          </Stack>
        </Stack>
      )}
      {children}
    </Stack>
  );
};
