import React, { useEffect, useState } from 'react';
import './styles.css';
import { Thumbnail } from '@prenuvo/ui';
import { cn } from '@prenuvo/halo-web';
import { ThumbnailListPropType } from '../../types/ThumbnailListPropTypes';

const HORIZONTAL_ORIENTATION = 'horizontal';

const ThumbnailList = ({
  thumbnails,
  onThumbnailDoubleClick,
  activeDisplaySetInstanceUIDs = [],
  orientation,
  customClassName,
  studyPrefetcherService,
  ...props
}: ThumbnailListPropType) => {
  const isHorizontal = orientation === HORIZONTAL_ORIENTATION;
  const [displaySetsLoadingState, setDisplaySetsLoadingState] = useState({});

  useEffect(() => {
    const { unsubscribe: unsubscribeProgress } = studyPrefetcherService.subscribe(
      studyPrefetcherService.EVENTS.DISPLAYSET_LOAD_PROGRESS,
      updatedDisplaySetLoadingState => {
        const { displaySetInstanceUID, loadingProgress } = updatedDisplaySetLoadingState;

        setDisplaySetsLoadingState(prevState => ({
          ...prevState,
          [displaySetInstanceUID]: loadingProgress,
        }));
      }
    );

    const { unsubscribe: unsubscribeComplete } = studyPrefetcherService.subscribe(
      studyPrefetcherService.EVENTS.DISPLAYSET_LOAD_COMPLETE,
      updatedDisplaySetLoadingState => {
        const { displaySetInstanceUID } = updatedDisplaySetLoadingState;

        setDisplaySetsLoadingState(prevState => ({
          ...prevState,
          [displaySetInstanceUID]: 1,
        }));
      }
    );

    return () => {
      unsubscribeProgress();
      unsubscribeComplete();
    };
  }, [studyPrefetcherService]);

  return (
    <div
      className={cn('mx-2 overflow-hidden rounded-lg bg-[#161618] py-3', customClassName, {
        'ohif-scrollbar-h flex flex-row overflow-x-hidden overflow-y-hidden': isHorizontal,
        'ohif-scrollbar-h flex w-[160px] flex-col items-center overflow-y-auto': !isHorizontal,
      })}
    >
      {thumbnails.map(
        ({
          displaySetInstanceUID,
          description,
          dragData,
          imageSrc,
          imageAltText,
          isHydratedForDerivedDisplaySet,
          componentType,
        }) => {
          const isActive = activeDisplaySetInstanceUIDs.includes(displaySetInstanceUID);
          const loadingProgress: number = displaySetsLoadingState[displaySetInstanceUID]; // number btween 0 and 1 indicating the progress of prefetch for the displayset
          return (
            <Thumbnail
              isActive={isActive}
              imageSrc={imageSrc}
              imageAltText={imageAltText}
              key={displaySetInstanceUID}
              displaySetInstanceUID={displaySetInstanceUID}
              dragData={dragData}
              description={description}
              isHydratedForDerivedDisplaySet={isHydratedForDerivedDisplaySet}
              onDoubleClick={() => {
                const preventOperation =
                  typeof props.shouldPreventLoadThumb === 'function'
                    ? props.shouldPreventLoadThumb(displaySetInstanceUID)
                    : false;

                if (preventOperation) {
                  onThumbnailDoubleClick(displaySetInstanceUID);
                }
              }}
              canDrag={(uid: string) => props.shouldPreventLoadThumb(uid)}
              componentType={componentType}
              loadingProgress={loadingProgress}
              {...props}
            />
          );
        }
      )}
    </div>
  );
};

export { ThumbnailList };
