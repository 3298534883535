import i18n from 'i18next';
import { KeyImagePanel } from '../components/KeyImagePanel';

export function getPanelModule({ commandsManager, extensionManager, servicesManager }) {
  return [
    {
      name: 'keyImages',
      iconName: 'tool-capture',
      iconLabel: 'Key Images',
      label: i18n.t('SidePanel:Key Images'),
      secondaryLabel: i18n.t('SidePanel:Key Images'),
      component: KeyImagePanel.bind(null, {
        commandsManager,
        extensionManager,
        servicesManager,
      }),
    },
  ];
}
