import { Layout } from '../types';

const displaySets = [
  {
    id: 'defaultDisplaySetId',
    seriesMatchingRules: [],
  },
];

const viewportOptions = {
  toolGroupId: Layout.Layout_1x1,
};

const viewportStructureTemplate = {
  type: 'grid',
  layoutType: 'grid',
  displaySets: displaySets,
};

const getViewports = (count: number) => {
  return {
    displaySets: displaySets,
    viewports: Array(count)
      .fill(1) // need to be filled with some value for the map to return properly
      .map((_, index: number) => ({
        viewportOptions: viewportOptions,
        displaySets: [
          {
            id: 'defaultDisplaySetId',
            matchedDisplaySetsIndex: index,
          },
        ],
      })),
  };
};

const hplayout = {
  id: 'predefinedLayout',
  hasUpdatedPriorsInformation: false,
  name: 'predefinedLayoutHP',
  protocolMatchingRules: [
    {
      id: 'OneOrTwoMoreSeries',
      weight: 10,
      attribute: 'numberOfDisplaySetsWithImages',
      constraint: {
        greaterThan: 1,
      },
    },
  ],
  toolGroupIds: [Layout.Layout_1x1],
  displaySetSelectors: {
    defaultDisplaySetId: {
      findAll: true,
      seriesMatchingRules: [],
    },
  },
  stages: [
    {
      name: Layout.Layout_1x1,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 1,
          columns: 1,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(1),
    },
    {
      name: Layout.Layout_1x2,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 1,
          columns: 2,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 2,
              height: 1,
            },
            {
              x: 1 / 2,
              y: 0,
              width: 1 / 2,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(2),
    },
    {
      name: Layout.Layout_2x1,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 2,
          columns: 1,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1,
              height: 1 / 2,
            },
            {
              x: 0,
              y: 1 / 2,
              width: 1,
              height: 1 / 2,
            },
          ],
        },
      },
      ...getViewports(2),
    },
    {
      name: Layout.Layout_1x3,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 1,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(3),
    },
    {
      name: Layout.Layout_2_1_1,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 3,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 0,
              y: 1 / 2,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(4),
    },
    {
      name: Layout.Layout_2_2_1,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 3,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 0,
              y: 1 / 2,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 1 / 3,
              y: 1 / 2,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(5),
    },
    {
      name: Layout.Layout_2_2_2,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 2,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 0,
              y: 1 / 2,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 1 / 3,
              y: 1 / 2,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 2 / 3,
              y: 1 / 2,
              width: 1 / 3,
              height: 1 / 2,
            },
          ],
        },
      },
      ...getViewports(6),
    },
    {
      name: Layout.Layout_3_2_1,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 3,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 0,
              y: 1 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 0,
              y: 2 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 1 / 3,
              y: 1 / 2,
              width: 1 / 3,
              height: 1 / 2,
            },
            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(6),
    },
    {
      name: Layout.Layout_3_1_1,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 3,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 0,
              y: 1 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 0,
              y: 2 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },

            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(5),
    },
    {
      name: Layout.Layout_3_3_1,
      viewportStructure: {
        ...viewportStructureTemplate,
        properties: {
          rows: 3,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 0,
              y: 1 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 0,
              y: 2 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 1 / 3,
              y: 1 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 1 / 3,
              y: 2 / 3,
              width: 1 / 3,
              height: 1 / 3,
            },
            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
          ],
        },
      },
      ...getViewports(7),
    },
  ],
  numberOfPriorsReferenced: 0,
  defaultViewport: {
    viewportOptions: {
      viewportType: 'stack',
    },
  },
};

export { hplayout };
