import * as React from 'react';
export const Collapse = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="white"
      fillRule="evenodd"
      d="M27.94 13.022a.725.725 0 0 1-.722.728h-7.905a1.067 1.067 0 0 1-1.063-1.07V4.395c0-.402.324-.728.723-.728.4 0 .723.326.723.728v6.912l7.82-7.844a.72.72 0 0 1 1.023 0 .73.73 0 0 1-.001 1.03l-7.778 7.802h6.458c.399 0 .723.326.723.727ZM4.06 18.978c0-.402.323-.728.722-.728h7.905c.587 0 1.063.48 1.063 1.07v8.285a.725.725 0 0 1-.723.728.725.725 0 0 1-.723-.728v-6.913l-7.82 7.845a.72.72 0 0 1-1.023 0 .73.73 0 0 1 .001-1.03l7.778-7.802H4.782a.725.725 0 0 1-.723-.727Z"
      clipRule="evenodd"
    />
  </svg>
);
