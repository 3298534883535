import { UINotificationService } from '@ohif/core';

export const notifyMeasurementAddedSuccess = (
  uiNotificationService: UINotificationService,
  reportType: string
) => {
  uiNotificationService.show({
    title: 'Create or Update Report',
    message: `Measurements saved to ${reportType}`,
    type: 'success',
  });
};

export const notifyMeasurementAddedFailed = (
  uiNotificationService: UINotificationService,
  reportType: string,
  error: Error
) => {
  uiNotificationService.show({
    title: 'Create or Update Report',
    message: error.message || `Failed to store ${reportType}`,
    type: 'error',
  });
};
