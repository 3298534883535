import React from 'react';
import { cn } from '@prenuvo/halo-web';
import { tabItemVariants } from './study-tab-item.cva';
import { StudyTabItemProps } from './study-tabs.types';
import { Stack } from '@prenuvo/halo-web';

export const StudyTabItem = ({
  model: { id, label },
  selectedTabId,
  onClick,
  color,
}: StudyTabItemProps) => {
  const isSelected = selectedTabId === id;

  // TODO: Ask Design if our current format is fine or if we need to change it
  // Figma shows the label formatted like 07/30/2022 but we're passing it in as 30-Feb-13

  return (
    <Stack
      data-id={isSelected ? 'studyTabSelected' : 'studyTabUnselected'}
      className={cn('flex h-full flex-col items-center', tabItemVariants({ isSelected }))}
    >
      <button
        className="h-full w-full cursor-pointer text-[neutral-50]"
        onClick={() => onClick(String(id))}
      >
        <label
          className="select-none px-2"
          style={{ color }}
        >
          {label}
        </label>
      </button>
    </Stack>
  );
};

StudyTabItem.displayName = 'StudyTabItem';
