import { utils } from '@ohif/core';
import { StudyInfo } from '../components/StudyTabs';

export function formatDateInStudies(studies: StudyInfo[]) {
  return studies.map(study => {
    study.date = utils.formatDate(study.date);
    study.StudyInstanceUID = study.StudyInstanceUID ?? study.studyInstanceUid;
    return study;
  });
}
