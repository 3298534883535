import { UserPreferences } from './types/userPreferences';
import hotkeyBindings from './defaults/hotkeyBindings';
import textOverlayPreferences from './defaults/textOverlayPreferences';
import mouseBindings from './defaults/mouseBindings';
import hangingProtocolPreferences from './defaults/hangingProtocolPreferences';

export const getDefaultUserPreferences = (): UserPreferences => {
  return {
    language: 'en',
    hotkeyBindings,
    mouseBindings,
    hangingProtocolPreferences,
    textOverlayPreferences,
  };
};
