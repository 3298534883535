import { ViewportGridService } from '@ohif/core';
import { Axis, Dimension, Direction, NeighborInfo, ViewportGetState } from '../../types';
import { getImmediateNeighbor } from '../getImmediateNeighbor';
import { AXIS, DIMENSIONS, DIRECTIONS } from '@prenuvo/extension-default';

/**
 * Finds the nearest neighboring viewport(s) according to priority (bottom, top, left, right)
 * from the currently active viewport, adjusting for size if necessary.
 *
 * @param viewportGridService - The service managing the viewport grid state.
 * @param viewportId - Selected viewport Id.
 * @returns An array of NeighborInfo objects containing direction and viewportId, or null if no active viewport.
 */
export const findNearestNeighbor = (
  viewportGridService: ViewportGridService,
  viewportId: string
): NeighborInfo[] | null => {
  const { viewports } = viewportGridService.getState() as ViewportGetState;
  const currentViewport = viewports.get(viewportId);

  if (!currentViewport) {
    return null;
  }

  const result: NeighborInfo[] = [];

  // Helper function to find neighbors in a given direction
  const findNeighbor = (direction: Direction, dimension: Dimension, axis: Axis) => {
    const immediateNeighbor = getImmediateNeighbor(viewports, currentViewport, direction);
    if (immediateNeighbor) {
      if (immediateNeighbor[dimension] === currentViewport[dimension]) {
        return [{ direction, viewportId: immediateNeighbor.viewportId }];
      }

      const requiredSize = currentViewport[dimension];
      let currentNeighbor = immediateNeighbor;
      const sizeAdjustedNeighbor = { ...currentViewport };
      let accumulatedSize = 0;

      while (accumulatedSize !== requiredSize) {
        if (accumulatedSize + currentNeighbor[dimension] <= requiredSize) {
          accumulatedSize += currentNeighbor[dimension];
          result.push({ direction, viewportId: currentNeighbor.viewportId });
        }
        if (accumulatedSize > requiredSize) {
          break;
        }

        if (accumulatedSize === requiredSize) {
          return result;
        }

        sizeAdjustedNeighbor[axis] += currentNeighbor[dimension];
        sizeAdjustedNeighbor[dimension] -= currentNeighbor[dimension];

        currentNeighbor = getImmediateNeighbor(viewports, sizeAdjustedNeighbor, direction);
      }
    }
    return [];
  };

  const immediateBottom = findNeighbor(DIRECTIONS.BOTTOM, DIMENSIONS.WIDTH, AXIS.X);
  if (immediateBottom.length) {
    return immediateBottom;
  }

  const immediateTop = findNeighbor(DIRECTIONS.TOP, DIMENSIONS.WIDTH, AXIS.X);
  if (immediateTop.length) {
    return immediateTop;
  }

  const immediateLeft = findNeighbor(DIRECTIONS.LEFT, DIMENSIONS.HEIGHT, AXIS.Y);
  if (immediateLeft.length) {
    return immediateLeft;
  }

  const immediateRight = findNeighbor(DIRECTIONS.RIGHT, DIMENSIONS.HEIGHT, AXIS.Y);
  if (immediateRight.length) {
    return immediateRight;
  }

  return [];
};
