import { CommandsManager, ServicesManager } from '@ohif/core';
import { PrenuvoServices, Unsubscribe } from '../types';
import { TOOLS_TOGGLE_STATE } from '../utils';

const IMAGE_SLICE_SYNC_ID = 'IMAGE_SLICE_SYNC';
const IMAGE_SLICE_SYNC_TYPE = 'imageSlice';

interface InitImageSliceSyncProps {
  commandsManager: CommandsManager;
  servicesManager: ServicesManager;
  unsubscriptions: Unsubscribe[];
}

export function initImageSliceSync({
  commandsManager,
  servicesManager,
  unsubscriptions,
}: InitImageSliceSyncProps): void {
  const { cornerstoneViewportService, viewportGridService, stateSyncService } =
    servicesManager.services as PrenuvoServices;

  const toggleSynchronizer = () => {
    commandsManager.runCommand('toggleSynchronizer', {
      type: IMAGE_SLICE_SYNC_TYPE,
      syncId: IMAGE_SLICE_SYNC_ID,
      retainSyncScrollState: false,
    });
  };

  const { unsubscribe: unsubscribeViewportsReady } = viewportGridService.subscribe(
    viewportGridService.EVENTS.VIEWPORTS_READY,
    () => {
      toggleSynchronizer();
      const { unsubscribe: unsubscribeViewportsDataChanged } = cornerstoneViewportService.subscribe(
        cornerstoneViewportService.EVENTS.VIEWPORT_DATA_CHANGED,
        () => {
          const { stackSyncToolState } = stateSyncService.getState().toolsToggleState || {
            stackSyncToolState: TOOLS_TOGGLE_STATE.SHOW,
          };
          if (stackSyncToolState === TOOLS_TOGGLE_STATE.SHOW) {
            toggleSynchronizer();
          }
        }
      );
      unsubscriptions.push(unsubscribeViewportsDataChanged);
      unsubscribeViewportsReady();
    }
  );
}
