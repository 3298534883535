import { Enums, annotation, cancelActiveManipulations } from '@cornerstonejs/tools';
import {
  CommandsManager,
  ExtensionManager,
  Types as OhifCoreTypes,
  ServicesManager,
  log,
} from '@ohif/core';
import { reuseCachedLayout } from '@ohif/extension-default';
import {
  DefaultConstants,
  toggleToolState,
  PrenuvoServices,
  PrenuvoTypes,
} from '@prenuvo/extension-default';
import { PrenuvoAppConfig } from '@prenuvo/extension-dicom-ko';
import { HangingProtocolParams } from 'extensions/default/src/commandsModule';
import { LayoutOption, NeighborInfo, SplitType } from '../types';
import {
  findSRInSameStudy,
  getActiveViewPortDisplaySet,
  getNextIndex,
  notifyMeasurementAddedFailed,
  notifyMeasurementAddedSuccess,
  notifyUnsupportedOperationClosing,
  notifyUnsupportedOperationSplitting,
} from '../utils';
import { SR_SERIES_DESCRIPTION, TOGGLE_TOOLS } from '../utils/constants';
import {
  getSplitConfig,
  hasPrior,
  runProtocolForComparison,
  updateLayout,
} from './commandsModuleUtils';
import { findNearestNeighbor, getLayoutOptionsAfterClose } from './utils';
import { setInitialLabel } from '../utils/uiModals';
import { handleMeasurementsToggle } from '../utils/handleMeasurementsToggle/handleMeasurementsToggle';
import { getToolState } from '../utils/toolsToggle';

const defaultContext = 'CORNERSTONE';

interface CommandsModuleInterface {
  servicesManager: ServicesManager;
  commandsManager: CommandsManager;
  extensionManager?: ExtensionManager;
  appConfig?: PrenuvoAppConfig;
}

type _Command = {
  commandName: string;
  commandOptions?: Record<string, unknown>;
  context?: string;
};
type ToolbarServiceRecordInteraction = {
  itemId: string;
  interactionType: string;
  commands: (_Command | string) | (_Command | string)[];
};

const commandsModule = ({
  servicesManager,
  commandsManager,
  extensionManager,
  appConfig,
}: CommandsModuleInterface) => {
  const {
    toolbarService,
    keyObjectDisplaySetService,
    viewportGridService,
    toolGroupService,
    protocolJarService,
    uiNotificationService,
    hangingProtocolService,
    measurementService,
    displaySetService,
    stateSyncService,
    userPreferencesService,
    uiModalService,
  } = servicesManager.services as PrenuvoServices;

  // variable of control to prevent a given action to run more than once when keyboard is pressed
  let isKeyDown = undefined;

  const actions = {
    /**
     * shiftting protocol stages using hotkeys with wrap around functionality.
     * @param  {direction} {1|-1} defines the shift direction, 1 for next and -1 for previous
     */
    deltaStage: ({ direction }: { direction: 1 | -1 }) => {
      const { protocolId, stageIndex: currentStageIndex } = hangingProtocolService.getState();
      const { protocol } = hangingProtocolService.getActiveProtocol();

      function seekNext(
        startIndex: number,
        direction: 1 | -1,
        length: number,
        predicate: (index: number) => boolean
      ) {
        let index = startIndex;
        for (let i = 0; i < length; i++) {
          index = getNextIndex(index, direction, length);
          if (predicate(index)) {
            return index;
          }
        }
        return -1;
      }

      const newStageIndex = seekNext(
        currentStageIndex,
        direction,
        protocol.stages.length,
        index => protocol.stages[index].status !== 'disabled'
      );

      if (newStageIndex !== -1) {
        return commandsManager.runCommand('setHangingProtocol', {
          protocolId,
          stageIndex: newStageIndex,
        });
      }
    },

    /**
     * Erases all the measurements and broadcasts the removed event.
     */
    eraseAllMeasurements: (): void => {
      measurementService.clearMeasurements();
      measurementService._broadcastEvent('event::measurement_removed', {});
    },

    /**
     * Creates the interaction configuration for the menu item based on the toolbar
     * buttons
     * @param props.commands Commands to be executed once you click the menu item
     */
    toolbarServiceRecordInteraction: (props: ToolbarServiceRecordInteraction): void => {
      toolbarService.recordInteraction(props.commands[0].commandOptions);
    },

    /**
     * Shows the series selected from the series submenu in RCCM to the viewport
     * @param SeriesInstanceUID contains the SeriesInstanceUID of the selected series
     */
    showSeriesSelectedFromContextMenu: ({
      SeriesInstanceUID,
    }: {
      SeriesInstanceUID: string;
    }): void => {
      const viewportId: string = viewportGridService.getActiveViewportId();
      const displaySet = displaySetService.getDisplaySetsForSeries(SeriesInstanceUID)?.[0];
      const viewportUpdateData = {
        viewportId: viewportId,
        displaySetInstanceUIDs: [displaySet.displaySetInstanceUID],
      };
      viewportGridService.setDisplaySetsForViewport(viewportUpdateData);
    },

    /**
     * For switching protocols with corresponding hotkey bindings.
     * @param shift {1 | -1} number of indices to be moved
     * @returns void | null
     */
    shiftProtocolWithHotKey: ({ shift }: { shift: -1 | 1 }) => {
      const { protocol } = hangingProtocolService.getActiveProtocol();
      const currentIndex = protocolJarService.selectedHPs.findIndex(item =>
        item.commands.some(command => command.commandOptions.protocolId === protocol.id)
      );
      if (currentIndex === -1) {
        return null;
      }
      const nextIndex = getNextIndex(currentIndex, shift, protocolJarService.selectedHPs.length);
      commandsManager.run(protocolJarService.selectedHPs[nextIndex]);
    },

    // invert toolName configuration when keyDown/keyUp pressed
    invertToolsStrategy({ toolGroupId, keyDown, toolNames = [] }) {
      // cut in case second pass or there is no toolNames to invert
      if (isKeyDown === keyDown || keyDown === undefined || !toolNames.length) {
        return;
      }

      const toolGroup = commandsManager.runCommand('getToolGroup', {
        toolGroupId,
      });

      if (!toolGroup) {
        return;
      }

      toolNames.forEach(toolName => {
        const currentInvertProp = toolGroup.getToolConfiguration(toolName, 'invert');
        const configuration = {
          invert: !currentInvertProp,
        };

        toolGroup.setToolConfiguration(toolName, configuration);
      });

      isKeyDown = keyDown;
    },
    resetToDefaultToolState({
      groupId = 'WindowLevel',
      itemId = 'WindowLevel',
      toolName = 'WindowLevel',
    }) {
      // change to default tool selected
      toolbarService.recordInteraction({
        groupId,
        itemId,
        interactionType: 'tool',
        commands: [
          { commandName: 'setPrimaryMouseButton' },
          {
            commandName: 'setToolActive',
            commandOptions: { toolName },
            context: 'CORNERSTONE',
          },
        ],
      });

      // cancel any annotation in progress
      const activeViewportEnabledElement = commandsManager.runCommand(
        'getActiveViewportEnabledElement'
      );

      if (!activeViewportEnabledElement) {
        return;
      }

      const { viewport = {} } = activeViewportEnabledElement;
      const { element } = viewport;

      if (!element) {
        return;
      }

      const annotationUID = cancelActiveManipulations(element);

      if (annotationUID) {
        annotation.state.removeAnnotation(annotationUID);
      }
    },

    toggleCommandWithHotKey: ({ commandName, otherOptions = undefined }) => {
      try {
        commandsManager.runCommand(commandName, {
          ...otherOptions,
        });
      } catch (error) {
        console.warn(error);
      }
    },

    // For the point cross tool, we need to toggle the tool state to on and off when the user clicks on the
    // selected tool icon. For that, we have written a generic function.

    // For the point cross tool, we need to toggle the tool state to on and off when the user clicks on the
    // selected tool icon. For that, we have written a generic function.
    toggleSelectedToolState: ({ value, itemId, toolGroupIds = [] }) => {
      const toolName = itemId || value;

      toolGroupIds = toolGroupIds.length ? toolGroupIds : toolGroupService.getToolGroupIds();

      toolGroupIds.forEach(toolGroupId => {
        actions.toggleToolState({ toolName, toolGroupId });
      });
    },

    toggleToolState: ({ toolName, toolGroupId = null }) => {
      const { viewports } = viewportGridService.getState();

      if (!viewports.size) {
        return;
      }

      const toolGroup = toolGroupService.getToolGroup(toolGroupId);

      if (!toolGroup) {
        return;
      }

      if (!toolGroup.hasTool(toolName)) {
        return;
      }

      const activeToolName = toolGroup.getActivePrimaryMouseButtonTool();
      if (activeToolName !== toolName) {
        if (activeToolName) {
          const activeToolOptions = toolGroup.getToolConfiguration(activeToolName);
          activeToolOptions?.disableOnPassive
            ? toolGroup.setToolDisabled(activeToolName)
            : toolGroup.setToolPassive(activeToolName);
        }

        toolGroup.setToolActive(toolName, {
          bindings: [
            {
              mouseButton: Enums.MouseBindings.Primary,
            },
          ],
        });
      } else {
        toolGroup.setToolDisabled(activeToolName);
      }
    },

    /**
     * Invoke dataSource to reject given studyInstanceUID/seriesInstanceUID
     *
     * @privateRemarks
     * The integration of dataSource does not produce robust response detail, unless there is an exception the request is always considered successfully.
     *
     * @param param0
     * @returns
     */
    rejectSeries: async ({ dataSource, seriesInstanceUID, studyInstanceUID }) => {
      log.info('[DICOMSR] rejectSeries');

      if (!dataSource?.reject?.series) {
        log.error('[DICOMSR] datasource has no dataSource.reject.series endpoint!');
        return;
      }

      try {
        await dataSource.reject.series(studyInstanceUID, seriesInstanceUID);
      } catch (e) {
        log.error('Unable reject series', e);
      }
    },

    notifyUserOperationInvalid: ({ operationName, context }) => {
      const { uiNotificationService } = servicesManager.services;

      function getMessage() {
        let message = operationName ? `Operation ${operationName} ` : 'Current operation ';
        message += 'is invalid for ';
        message += context ? `${context}!` : 'this context!';

        return message;
      }
      uiNotificationService.show({
        title: 'Operation invalid',
        message: getMessage(),
        type: 'warning',
      });
    },
    /**
     * Return the list of names for must be disabled HP.
     * It has the rule:
     * - Disable all if none is selected
     * - Disable key image if there is no KO
     *
     * @param param0
     * @returns
     */
    getHPTabsDisabled: ({ hpItems, protocol, studyInstanceUID }) => {
      // ks the functionality
      if (!appConfig.keyImage?.allowDisableKeyImageHP) {
        return [];
      }

      const hasKO = !!keyObjectDisplaySetService.getLatestKeyObjectDisplaySet(studyInstanceUID);

      const anyActive = hpItems.find(
        ({ name }) => name === protocol.name || name === protocol.stageName
      );
      if (!anyActive) {
        return hpItems.map(({ name }) => name);
      }

      return !hasKO ? ['Key Images'] : [];
    },

    shiftProtocolJarWithHotKey: ({ shift }: { shift: 1 | -1 }) => {
      const totalJars = protocolJarService.matchingProtocolJars.length;
      if (totalJars > 1) {
        const nextIndex = getNextIndex(
          protocolJarService.selectedProtocolJarIndex,
          shift,
          totalJars
        );
        protocolJarService.setSelectedProtocolJarIndex(nextIndex);
      } else {
        uiNotificationService.show({
          title: 'Protocol Jar Navigation',
          message:
            totalJars === 1
              ? 'There is only one protocol jar that matches the study'
              : 'There is no matching protocol jar for the study',
          type: 'error',
        });
      }
    },

    /**
     * Create or update a report with the provided measurement data.
     * This function attempts to store the measurements and shows a notification on success or failure.
     *
     * @param createReportProps - An object containing measurement data, data source, and options
     * @param reportType - The type of report, defaulting to 'Prenuvo Annotations 0'
     * @returns A Promise that resolves when the operation is complete
     */
    createReport: async (
      createReportProps: {
        dataSource: Record<string, unknown>;
      },
      reportType: string = SR_SERIES_DESCRIPTION.NON_KEY_IMAGE_MEASUREMENT
    ): Promise<void> => {
      try {
        const {
          viewportGridService,
          displaySetService,
          measurementService,
          patientStudyListService,
        } = servicesManager.services as PrenuvoServices;
        const activeViewportId: string = viewportGridService.getActiveViewportId();

        if (activeViewportId) {
          const measurementData: PrenuvoTypes.Measurement[] = measurementService.oldMeasurements
            ? [...measurementService.oldMeasurements, ...measurementService.getMeasurements()]
            : measurementService.getMeasurements();

          const options: OhifCoreTypes.SeriesMetadata = findSRInSameStudy(
            patientStudyListService.activeStudyUID,
            displaySetService
          );

          await commandsManager.runCommand(
            DefaultConstants.COMMANDS.STORE_MEASUREMENTS,
            {
              ...createReportProps,
              measurementData,
              options,
              additionalFindingTypes: ['ArrowAnnotate'],
            },
            'CORNERSTONE_STRUCTURED_REPORT'
          );
          notifyMeasurementAddedSuccess(uiNotificationService, reportType);
        }
      } catch (error) {
        notifyMeasurementAddedFailed(uiNotificationService, reportType, error);
        throw new Error(
          `Failed to store ${reportType}. Error: ${error.message || 'Unknown error'}`
        );
      }
    },

    toggleComparisonModeHangingProtocol: ({
      protocolId,
      stageIndex,
    }: HangingProtocolParams): boolean => {
      const {
        protocol,
        stageIndex: desiredStageIndex,
        activeStudy,
      } = hangingProtocolService.getActiveProtocol();
      const { patientStudyListService } = servicesManager.services as PrenuvoServices;
      const { toggleHangingProtocol } = stateSyncService.getState();
      const storedHanging = `${activeStudy.StudyInstanceUID}:${protocolId}:${stageIndex | 0}`;
      const activeDisplaySet = getActiveViewPortDisplaySet(viewportGridService, displaySetService);
      const hasPriorSeries = hasPrior(activeDisplaySet, displaySetService, uiNotificationService);
      const state = viewportGridService.getState();
      const stateSyncReduce = reuseCachedLayout(state, hangingProtocolService, stateSyncService);
      const { viewportGridStore } = stateSyncReduce;

      if (
        protocol.id === protocolId &&
        (stageIndex === undefined || stageIndex === desiredStageIndex)
      ) {
        // Toggling off - restore to previous state
        const previousState = toggleHangingProtocol[storedHanging] || {
          protocolId: 'default',
        };
        // Restore the protocol which was set before toggling comparison mode and reset the viewports which is stored in viewportGridStore.
        commandsManager.runCommand('setHangingProtocol', previousState);
        viewportGridService.set(viewportGridStore[storedHanging]);
      } else {
        if (!hasPriorSeries) {
          return;
        }
        stateSyncService.store({
          toggleHangingProtocol: {
            ...toggleHangingProtocol,
            [storedHanging]: {
              protocolId: protocol.id,
              stageIndex: desiredStageIndex,
            },
          },
        });

        if (patientStudyListService.studies.length > 0) {
          runProtocolForComparison(
            patientStudyListService,
            hangingProtocolService,
            displaySetService
          );
        }
      }

      stateSyncService.store(stateSyncReduce);
    },

    handleMeasurementsToggle: async params => {
      await handleMeasurementsToggle({
        ...params,
        servicesManager,
        extensionManager,
        appConfig,
        toggleToolsKey: null,
      });
    },

    toggleLoadMeasurements: async params => {
      await handleMeasurementsToggle({
        ...params,
        servicesManager,
        extensionManager,
        appConfig,
        toggleToolsKey: TOGGLE_TOOLS.MEASUREMENTS_VISIBILITY_STATE,
      });
    },

    toggleLoadSpineLabels: async params => {
      await handleMeasurementsToggle({
        ...params,
        servicesManager,
        extensionManager,
        appConfig,
        toggleToolsKey: TOGGLE_TOOLS.SPINE_LABELS_VISIBILITY_STATE,
      });
    },

    toggleStackSyncScroll: () => {
      toggleToolState(stateSyncService, userPreferencesService, TOGGLE_TOOLS.STACK_SYNC_TOOL_STATE);
      const toolsState = getToolState(stateSyncService, TOGGLE_TOOLS.STACK_SYNC_TOOL_STATE);

      commandsManager.run({
        commandName: 'toggleSynchronizer',
        commandOptions: {
          type: 'imageSlice',
          syncId: 'IMAGE_SLICE_SYNC',
          toggleState: toolsState,
        },
      });
    },

    splitViewport: ({ splitType, viewportId }: { splitType: SplitType; viewportId: string }) => {
      const viewportState = viewportGridService.getState();
      const { viewports, layout } = viewportState;
      const layoutOptions = viewportGridService.getLayoutOptionsFromState(
        viewportState
      ) as LayoutOption[];

      if (!viewportId) {
        viewportId = viewportGridService?.getActiveViewportId();
      }

      const viewportArray = Array.from(viewports.keys());
      const activeViewportIndex = viewportArray.indexOf(viewportId);

      const splitConfig = getSplitConfig(splitType, layout, layoutOptions[activeViewportIndex]);

      if (!splitConfig) {
        notifyUnsupportedOperationSplitting(uiNotificationService, splitType);
        return;
      }

      const { numRows, numCols, newLayoutEntries } = splitConfig;

      layoutOptions.splice(activeViewportIndex, 1, ...newLayoutEntries);

      updateLayout(
        viewportGridService,
        stateSyncService,
        hangingProtocolService,
        layoutOptions,
        numRows,
        numCols
      );
    },

    closeViewport: ({ viewportId }: { viewportId: string }) => {
      const { viewportGridService } = servicesManager.services as PrenuvoServices;
      if (!viewportId) {
        viewportId = viewportGridService?.getActiveViewportId();
      }
      const neighbors: NeighborInfo[] = findNearestNeighbor(viewportGridService, viewportId);
      const viewportState = viewportGridService.getState();
      const layoutOptions: LayoutOption[] =
        viewportGridService.getLayoutOptionsFromState(viewportState);
      const { viewports, layout } = viewportState;

      // If there's only one viewport or no neighboring viewports for the selected one to close (locking condition).
      if (layoutOptions.length === 1 || !neighbors.length) {
        notifyUnsupportedOperationClosing(uiNotificationService);
        return;
      }

      const viewportArray = Array.from(viewports.keys()) as string[];
      const viewportIndexToClose: number = viewportArray.indexOf(viewportId);

      const newLayoutOptions = getLayoutOptionsAfterClose(
        viewportIndexToClose,
        layoutOptions,
        neighbors,
        viewportArray
      );

      updateLayout(
        viewportGridService,
        stateSyncService,
        hangingProtocolService,
        newLayoutOptions,
        layout.numRows,
        layout.numCols
      );
    },

    handleSpineLabelToolActive: () => {
      const toolName = DefaultConstants.TOOL_NAMES.SPINELABEL;
      const toolGroupIds = toolGroupService.getToolGroupIds();

      toolGroupIds.forEach(toolGroupId => {
        const toolGroup = toolGroupService.getToolGroup(toolGroupId) || null;

        const activeToolName = toolGroup.getActivePrimaryMouseButtonTool();

        if (activeToolName !== toolName) {
          if (!toolGroup || !toolGroup.hasTool(toolName)) {
            return;
          }
          if (!stateSyncService.getState().spineLabelStates.isFirstVertebraSelected) {
            setInitialLabel(uiModalService, toolGroup, toolName, stateSyncService);
          }
          commandsManager.runCommand('setToolActive', {
            toolName,
            toolGroupId,
          });
        }
      });
    },

    selectHPTab: hp => {
      protocolJarService.switchProtocolTab(hp);
    },
  };
  const definitions = {
    //nextStage and previousStage replaces the platform core implementation for wrap around functionality
    nextStage: {
      commandFn: actions.deltaStage,
      options: { direction: 1 },
    },
    previousStage: {
      commandFn: actions.deltaStage,
      options: { direction: -1 },
    },
    eraseAllMeasurements: {
      commandFn: actions.eraseAllMeasurements,
    },
    showSeriesSelectedFromContextMenu: {
      commandFn: actions.showSeriesSelectedFromContextMenu,
    },
    toolbarServiceRecordInteraction: {
      commandFn: actions.toolbarServiceRecordInteraction,
    },
    invertToolsStrategy: {
      commandFn: actions.invertToolsStrategy,
    },
    resetToDefaultToolState: {
      commandFn: actions.resetToDefaultToolState,
    },
    toggleCommandWithHotKey: {
      commandFn: actions.toggleCommandWithHotKey,
    },
    rejectSeries: {
      commandFn: actions.rejectSeries,
    },
    notifyUserOperationInvalid: {
      commandFn: actions.notifyUserOperationInvalid,
    },
    getHPTabsDisabled: {
      commandFn: actions.getHPTabsDisabled,
    },
    toggleSelectedToolState: {
      commandFn: actions.toggleSelectedToolState,
    },
    toggleToolState: {
      commandFn: actions.toggleToolState,
    },
    shiftProtocolWithHotKey: {
      commandFn: actions.shiftProtocolWithHotKey,
    },
    shiftProtocolJarWithHotKey: {
      commandFn: actions.shiftProtocolJarWithHotKey,
    },
    createReport: {
      commandFn: actions.createReport,
    },
    toggleComparisonModeHangingProtocol: {
      commandFn: actions.toggleComparisonModeHangingProtocol,
    },
    handleMeasurementsToggle: {
      commandFn: actions.handleMeasurementsToggle,
    },
    toggleLoadMeasurements: {
      commandFn: actions.toggleLoadMeasurements,
    },
    toggleLoadSpineLabels: {
      commandFn: actions.toggleLoadSpineLabels,
    },
    toggleStackSyncScroll: {
      commandFn: actions.toggleStackSyncScroll,
    },
    splitViewport: {
      commandFn: actions.splitViewport,
    },
    closeViewport: {
      commandFn: actions.closeViewport,
    },
    handleSpineLabelToolActive: {
      commandFn: actions.handleSpineLabelToolActive,
    },
    selectHPTab: {
      commandFn: actions.selectHPTab,
    },
  };

  return { actions, defaultContext, definitions };
};

export { commandsModule };
