import { UINotificationService } from '@ohif/core';

export const uiNotificationWarning = (
  uiNotificationService: UINotificationService,
  title: string,
  message: string
) => {
  uiNotificationService.show({
    title,
    message,
    type: 'warning',
  });
};
