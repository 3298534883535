import React from 'react';
import { cn, Stack } from '@prenuvo/halo-web';
import type {
  HangingProtocolListProps,
  HangingProtocolListChangeEventType,
} from './hanging-protocol-list.types';
import { listClass, scrollClass } from './hanging-protocol-list.classnames';
import { HangingProtocolListItem } from './hanging-protocol-list-item';

export const HangingProtocolList = ({
  items,
  onChange,
  selectedParentItemId,
  selectedChildItemId,
  hpToolTips,
}: HangingProtocolListProps) => {
  const onItemClick = (changeEvent: HangingProtocolListChangeEventType) => {
    onChange(changeEvent);
  };

  return (
    <Stack
      className={cn(listClass, scrollClass)}
      data-testid="hanging-protocol-list"
      gap={0}
      alignment="start"
    >
      {items
        ? items.map(({ id, subItems, label, iconSource }) => {
            return (
              <HangingProtocolListItem
                key={id}
                id={id}
                subItems={subItems}
                onClick={onItemClick}
                selectedParentItemId={selectedParentItemId}
                selectedChildItemId={selectedChildItemId}
                label={label}
                iconSource={iconSource}
                toolTipLabel={hpToolTips[id]}
              />
            );
          })
        : null}
    </Stack>
  );
};

HangingProtocolList.displayName = 'HangingProtocolList';
