import { PrenuvoLayout } from '../components/PrenuvoLayout/PrenuvoLayout';
/*
- Define layout for the viewer in mode configuration.
- Pass in the viewport types that can populate the viewer.
- Init layout based on the displaySets and the objects.
*/
/**
 * Function that returns the layout template definition.
 *
 * @param {object} externalProps
 * @param {object} externalProps.extensionManager
 * @param {object} externalProps.commandsManager
 * @param {object} externalProps.hotkeysManager
 *
 * @returns list of template definition object
 */
export function getLayoutTemplateModule(externalProps) {
  function PrenuvoLayoutWithServices(props) {
    return PrenuvoLayout({
      ...externalProps,
      ...props,
    });
  }

  return [
    // Layout Template Definition
    {
      name: 'prenuvoLayout',
      id: 'prenuvoLayout',
      component: PrenuvoLayoutWithServices,
    },
  ];
}
