import React, { useContext } from 'react';
import moment from 'moment';
import { ViewportTitleBar } from '@prenuvo/ui';
import { CommandsManager, Types as coreType, HotkeysManager } from '@ohif/core';
import { DisplaySet } from '../../../types';
import { runCommand } from '../../../utils';
import { StudyContext } from '../ViewportPane/ViewportPaneWrapper';

export interface ViewportHeaderComponentProps {
  displaySets: DisplaySet[];
  commandsManager: CommandsManager;
  viewportId: string;
  hotkeysManager: HotkeysManager;
}

const viewportToolbarItems = [
  {
    commands: [
      {
        commandName: 'addActiveInstanceToKeyObject',
        commandOptions: {},
      },
    ],
    icon: 'tool-key-image',
    id: 'key-image',
    label: 'Key Image',
  },
  {
    commands: [
      {
        commandName: 'toggleOneUp',
        commandOptions: {},
      },
    ],
    icon: 'tool-expand2',
    id: 'expand',
    label: 'Expand',
  },
  // Below are temporary buttons and will be changed to the actual ones when design is available
  {
    commands: [
      {
        commandName: 'splitViewport',
        commandOptions: {
          splitType: 'vertical',
        },
      },
    ],
    icon: 'tool-chevronRightLg',
    id: 'verticalSplit',
    label: 'Vertical Split',
  },
  {
    commands: [
      {
        commandName: 'splitViewport',
        commandOptions: {
          splitType: 'horizontal',
        },
      },
    ],
    icon: 'tool-chevronDownLg',
    id: 'horizontalSplit',
    label: 'Horizontal Split',
  },
  {
    commands: [
      {
        commandName: 'closeViewport',
        commandOptions: {},
      },
    ],
    icon: 'tool-crossLg',
    id: 'closeViewport',
    label: 'Close Viewport',
  },
];

const addHotkeysToViewportToolbarLabels = (hotkeysManager: HotkeysManager) => {
  const { hotkeyDefinitions } = hotkeysManager as HotkeysManager & {
    hotkeyDefinitions: coreType.Hotkey[];
  };

  return viewportToolbarItems.map(item => {
    let labelWithHotkey = item.label;

    if (hotkeyDefinitions) {
      const matchingHotkey = Object.values(hotkeyDefinitions).find(h => h.label === item.label);

      if (matchingHotkey?.keys) {
        labelWithHotkey += ' (' + matchingHotkey.keys.join(' + ') + ')';
      }
    }

    return {
      ...item,
      label: labelWithHotkey,
    };
  });
};

const ViewportHeaderComponent: React.FC<ViewportHeaderComponentProps> = ({
  displaySets,
  commandsManager,
  viewportId,
  hotkeysManager,
}) => {
  const { studyColor, priorPrefix } = useContext(StudyContext);

  const displaySet = displaySets[0] || ({} as DisplaySet);
  const { SeriesDescription = '', SeriesDate = '' } = displaySet;
  const formattedSeriesDate = SeriesDate
    ? moment(SeriesDate, 'YYYYMMDD').format('MMM D, YYYY')
    : null;

  //TODO: Implement functionality for highlighting the key image button when an image is a key image
  const handleButtonClick = (toolId: string, commands: coreType.ComplexCommand[]) => {
    commands.forEach(command => {
      command.commandOptions = {
        ...command.commandOptions,
        viewportId,
      };
      runCommand(command, commandsManager);
    });
  };

  const viewportToolbarItemsWithHotkey = addHotkeysToViewportToolbarLabels(hotkeysManager);

  return (
    <ViewportTitleBar
      date={formattedSeriesDate}
      onButtonClick={handleButtonClick}
      seriesDescription={SeriesDescription}
      viewportToolbarItems={viewportToolbarItemsWithHotkey}
      priorPrefix={priorPrefix}
      studyColor={studyColor}
    />
  );
};

export { ViewportHeaderComponent };
