import React from 'react';
import type { ContextMenuProps } from './context-menu.types';
import { Icon, Stack } from '@prenuvo/halo-web';
import { CONTEXT_MENU_TOOL_MAP } from './context-menu-tool-map';

export const ContextMenu = ({ items, ...props }: ContextMenuProps) => {
  if (!items) {
    return null;
  }

  const onClick = (item: any, props: any) => {
    if (item?.action) {
      item?.action(item, props);
    }
  };

  return (
    <div
      data-cy="context-menu"
      className="ohif-scrollbar-h relative z-50 block max-h-[450px] w-[264px] max-w-[264px] overflow-y-auto rounded rounded-sm bg-[#1C1C1E] [&_p]:truncate" // PV-274 added ohif scroll bar style and text overflow ellipsis
      onContextMenu={e => e.preventDefault()}
    >
      {/* PV-275: It will render the context menu item only if there is a label. */}
      {items
        .filter(item => !!item.label)
        .map((item, index) => (
          <div
            key={index}
            data-cy="context-menu-item"
            onClick={() => onClick(item, props)}
            style={{ justifyContent: 'space-between' }}
            className="flex w-[258px] cursor-pointer items-center overflow-x-hidden border-b border-neutral-900 bg-[#1C1C1E] px-4 py-3 transition duration-300 last:border-b-0 hover:bg-neutral-900"
          >
            {!item.imageSource ? (
              <Stack
                gap="4"
                justification="center"
              >
                <Icon
                  aria-label={'hello'}
                  // if the label is not known tool option it will show image icon
                  // TODO: update CONTEXT_MENU_TOOL_MAP with missing icons such as reference lines etc...
                  source={CONTEXT_MENU_TOOL_MAP[item?.label] ?? CONTEXT_MENU_TOOL_MAP['Image']}
                  className={'flex h-[24px] w-[24px] fill-[#A8A8A8]'}
                  size="sm"
                />
                <label
                  className="w-[186px] truncate pt-[3px] text-sm text-neutral-50"
                  title={item.label}
                >
                  {item.label}
                </label>
              </Stack>
            ) : (
              <Stack
                gap="4"
                justification="left"
              >
                <img
                  src={item?.imageSource}
                  alt={'NA'}
                  className="h-[24px] w-[24px] rounded-[2px] object-none"
                />
                <label
                  className="w-[186px] truncate pt-[3px] text-sm text-neutral-50"
                  title={item.label}
                >
                  {item.label}
                </label>
              </Stack>
            )}
            {item?.iconRight && (
              <Icon
                aria-label={'open tool'}
                source={CONTEXT_MENU_TOOL_MAP[item?.iconRight]}
                className={'flex h-[16px] w-[16px] fill-[#a8a8a8]'}
                size="sm"
              />
            )}
          </div>
        ))}
    </div>
  );
};

ContextMenu.displayName = 'ContextMenu';
