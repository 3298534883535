import { ViewportGridService } from '@ohif/core';
import { DisplaySet, PrenuvoDisplaySetService } from '../types';

export function getActiveViewPortDisplaySet(
  viewportGridService: ViewportGridService,
  displaySetService: PrenuvoDisplaySetService
): DisplaySet {
  let activeDisplayset = {};
  const { activeViewportId, viewports } = viewportGridService.getState();
  const viewportIds = Array.from(viewports.keys());
  const activeViewportIndex = viewportIds.indexOf(activeViewportId);

  if (Number.isNaN(Number(activeViewportIndex)) || !viewports.size) {
    return;
  }

  const activeViewport = viewports.get(activeViewportId);
  const currentDisplayInstanceUID = activeViewport.displaySetInstanceUIDs[0];
  const displaysetOnDoubleClick = displaySetService.primaryStudyDisplaysetToCompare;
  if (displaysetOnDoubleClick) {
    activeDisplayset = displaysetOnDoubleClick;
  } else {
    activeDisplayset = displaySetService.getDisplaySetByUID(currentDisplayInstanceUID);
  }
  return activeDisplayset as DisplaySet;
}
