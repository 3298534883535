import { CommandsManager, Types as coreType } from '@ohif/core';

export const runCommand = (
  command: string | coreType.Command,
  commandsManager: CommandsManager
) => {
  if (typeof command === 'string') {
    commandsManager.run(command);
  } else if (typeof command === 'object') {
    commandsManager.run({
      ...command,
      commandOptions: {
        ...command.commandOptions,
      },
    });
  }
};
