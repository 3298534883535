import React, { useState } from 'react';
import { StudyList } from './StudyList';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModeProps, StudyListWrapperProps } from './StudyListTypes';
import { getSeriesDetails, sortLoadedModes, buildQuery } from './StudyListUtils';
import { StudyListActionBar } from './StudyListActionBar';

/**
 * StudyListWrapper component serves as a container for the StudyList component.
 * It handles the selection of series and navigation based on user interactions.
 *
 * Props:
 * - studyInstanceUid: Unique identifier for the study.
 * - appConfig: Application configuration settings.
 * - filterValues: Object containing filter values such as configUrl.
 * - study: Study information.
 * - seriesInStudiesMap: Map containing series data for studies.
 */
export function StudyListWrapper({
  studyInstanceUid,
  appConfig,
  filterValues,
  study,
  seriesInStudiesMap,
}: StudyListWrapperProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedSeriesUIds, setSelectedSeriesUIds] = useState<string[]>([]);

  const handleOpenSelectedSeries = (mode: ModeProps) => {
    const { query } = buildQuery(mode, study, filterValues);
    query.append('include', selectedSeriesUIds.join(','));

    const path = `${mode.routeName}?${query.toString()}`;

    setSelectedSeriesUIds([]);
    navigate(path);
  };

  const showOpenSelectedButton = selectedSeriesUIds.length > 0;

  const seriesTableDataSource = getSeriesDetails(
    seriesInStudiesMap.get(studyInstanceUid) ?? [],
    selectedSeriesUIds
  );

  return (
    <div>
      <StudyList
        seriesTableColumns={{
          description: t('StudyList:Description'),
          seriesNumber: t('StudyList:Series'),
          modality: t('StudyList:Modality'),
          instances: t('StudyList:Instances'),
        }}
        seriesTableDataSource={seriesTableDataSource}
        selectedSeriesUIds={selectedSeriesUIds}
        setSelectedSeriesUIds={setSelectedSeriesUIds}
      >
        <div className="flex flex-row gap-2">
          {(appConfig.groupEnabledModesFirst
            ? sortLoadedModes(appConfig.loadedModes, study)
            : appConfig.loadedModes
          ).map((mode, index) => {
            const { isValidMode, query } = buildQuery(mode, study, filterValues);
            return (
              mode.displayName && (
                <StudyListActionBar
                  key={index}
                  mode={mode}
                  isValidMode={isValidMode}
                  query={query}
                  handleOpenSelectedSeries={handleOpenSelectedSeries}
                  showOpenSelectedButton={showOpenSelectedButton}
                  studyInstanceUid={studyInstanceUid}
                  setSelectedSeriesUIds={setSelectedSeriesUIds}
                />
              )
            );
          })}
        </div>
      </StudyList>
    </div>
  );
}
