// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CUSTOM OHIF SCROLLBAR */
.ohif-scrollbar-h:hover {
  overflow-x: auto;
}
.ohif-scrollbar-h::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5em;
}
.ohif-scrollbar-h::-webkit-scrollbar-track {
  border-radius: 0.25rem;
  background-color: hsla(240, 3%, 11%, 1);
}
.ohif-scrollbar-h::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: hsla(0, 0%, 51%, 1);
}
.ohif-scrollbar-h::-webkit-scrollbar-thumb:window-inactive {
  background-color: hsla(240, 3%, 11%, 1);
}
`, "",{"version":3,"sources":["webpack://./../../../prenuvo-extensions/default/src/components/ThumbnailList/styles.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,gBAAgB;AAClB;AAGE;EAAA,cAAU;EACV;AADU;AAKV;EAAA,sBAAc;EACd;AADc;AAKd;EAAA,sBAAc;EACd;AADc;AAKd;EAAA;AAAmB","sourcesContent":["/* CUSTOM OHIF SCROLLBAR */\n.ohif-scrollbar-h:hover {\n  overflow-x: auto;\n}\n\n.ohif-scrollbar-h::-webkit-scrollbar {\n  @apply h-2;\n  width: 0.5em;\n}\n\n.ohif-scrollbar-h::-webkit-scrollbar-track {\n  @apply rounded;\n  @apply bg-stone-900;\n}\n\n.ohif-scrollbar-h::-webkit-scrollbar-thumb {\n  @apply rounded;\n  @apply bg-neutral-600;\n}\n\n.ohif-scrollbar-h::-webkit-scrollbar-thumb:window-inactive {\n  @apply bg-stone-900;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
