import React, { useState, useEffect } from 'react';
import { Icon, Stack, Tooltip, Typography, cn } from '@prenuvo/halo-web';
import type { HangingProtocolListItemProps } from './hanging-protocol-list.types';
import { motion, AnimatePresence } from 'framer-motion';
import { HangingProtocolListSubItems } from './hanging-protocol-list-sub-items';

export const HangingProtocolListItem = ({
  id,
  iconSource,
  label,
  subItems,
  onClick,
  selectedParentItemId,
  selectedChildItemId,
  toolTipLabel,
}: HangingProtocolListItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isParentSelected = selectedParentItemId === id;

  const toggleOpen = itemId => {
    if (subItems?.length > 0) {
      setIsOpen(!isOpen);
      onClick({
        type: isOpen ? 'close item' : 'open item',
        payload: { selectedParentItemId: itemId, selectedChildItemId },
      });
    } else {
      onClick({ type: 'item', payload: { selectedParentItemId: itemId, selectedChildItemId } });
    }
  };

  useEffect(() => {
    // auto close any root item when a different root item is selected
    if (selectedParentItemId !== id) {
      setIsOpen(false);
    }
  }, [id, selectedParentItemId]);

  return (
    <Stack
      variant="vertical"
      gap={0}
      className={cn({ 'rounded-md bg-[#323234]': isOpen }, 'w-full')}
    >
      <button
        onClick={() => toggleOpen(id)}
        className={cn('group/HP m-0 w-full rounded-sm p-2 text-[#A8A8A8] outline-none', {
          'bg-stone-700': isParentSelected,
        })}
      >
        <Tooltip
          content={<Typography className="rounded bg-white px-2">{toolTipLabel}</Typography>}
        >
          <Stack
            className="w-full"
            alignment="center"
            gap={0}
          >
            <Icon
              aria-label={label}
              source={iconSource}
              size="md"
              className={cn('h-[16px] w-[16px] fill-[#A8A8A8] group-hover/HP:fill-[#F9F9F9]')}
            />
            <label
              className={cn(
                'lato ml-2 select-none text-sm text-[#A8A8A8] group-hover/HP:text-[#F9F9F9]',
                {
                  'text-[#F9F9F9]': isParentSelected,
                }
              )}
              style={{ fontFamily: 'Lato', fontWeight: 300 }}
            >
              {label}
            </label>
          </Stack>
        </Tooltip>
      </button>
      <AnimatePresence>
        <motion.div
          key={id}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0 }}
          className="w-full"
        >
          {isOpen && subItems?.length > 0 ? (
            <Stack
              justification="start"
              alignment="start"
              className="w-full"
              variant="vertical"
              gap={0}
            >
              {subItems ? (
                <HangingProtocolListSubItems
                  subItems={subItems}
                  onChange={onClick}
                  selectedParentItemId={selectedParentItemId}
                  selectedChildItemId={selectedChildItemId}
                />
              ) : null}
            </Stack>
          ) : null}
        </motion.div>
      </AnimatePresence>
    </Stack>
  );
};

HangingProtocolListItem.displayName = 'HangingProtocolListItem';
