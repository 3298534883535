import {
  Head,
  Neck,
  Shoulders,
  Abdonimals,
  Pelvis,
  Spine,
  Skleleton,
  SkleletonLg,
  Image,
} from '@prenuvo/halo-icon';

import { Types as CoreTypes } from '@ohif/core';

type Protocol = CoreTypes.HangingProtocol.Protocol & {
  protocol: CoreTypes.HangingProtocol.Protocol;
};

export function convertHangingProtocols(protocols: Protocol[], stagesToSkip: string[] = []) {
  const iconMap = {
    Head: Head,
    Neck: Neck,
    Chest: Shoulders,
    Abdomen: Abdonimals,
    Pelvis: Pelvis,
    Spine: Spine,
    Skleleton: SkleletonLg,
    MSK: Skleleton,
    'Key Images': Image,
  };

  return protocols.flatMap(protocol => {
    const { protocol: protocolDetails } = protocol;
    const id = protocol.name;
    const stages = protocolDetails.stages.filter(stage => !stagesToSkip.includes(stage.name));

    return stages.map((stage, index) => ({
      protocolId: id,
      id: `${id}${index + 1}`,
      name: stage.name,
      label: stage.name,
      iconSource: iconMap[protocolDetails.name] || Head, // Default to Head if no matching icon
      commands: [
        {
          commandName: 'setHangingProtocol',
          commandOptions: {
            protocolId: protocolDetails.id,
            stageId: undefined,
            stageIndex: index,
            reset: true,
          },
          context: 'DEFAULT',
        },
      ],
      stage,
    }));
  });
}
