import React from 'react';
import { Button, ButtonEnums, Icon } from '@ohif/ui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StudyListActionBarProps } from './StudyListTypes';

export function StudyListActionBar({
  mode,
  isValidMode,
  query,
  handleOpenSelectedSeries,
  showOpenSelectedButton,
  studyInstanceUid,
  setSelectedSeriesUIds,
}: StudyListActionBarProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-2">
      <Link
        className={isValidMode ? '' : 'cursor-not-allowed'}
        to={`${mode.routeName}?${query.toString()}`}
        onClick={event => {
          if (!isValidMode) {
            event.preventDefault();
          }
        }}
      >
        <Button
          type={ButtonEnums.type.primary}
          size={ButtonEnums.size.medium}
          disabled={!isValidMode}
          startIcon={
            <Icon
              className="!h-[20px] !w-[20px] text-black"
              name={isValidMode ? 'launch-arrow' : 'launch-info'}
            />
          }
          dataCY={`mode-${mode.routeName}-${studyInstanceUid}`}
          className={isValidMode ? 'text-[13px]' : 'bg-[#222d44] text-[13px]'}
        >
          {mode.displayName}
        </Button>
      </Link>
      {showOpenSelectedButton && (
        <Button
          onClick={() => handleOpenSelectedSeries(mode)}
          startIcon={
            <Icon
              className="!h-[20px] !w-[20px] text-black"
              name={isValidMode ? 'launch-arrow' : 'launch-info'}
            />
          }
          endIcon={
            <Icon
              onClick={event => {
                event.stopPropagation();
                setSelectedSeriesUIds([]);
              }}
              className="text-primary-active stroke-primary-active ml-1 w-4"
              name="cancel"
            />
          }
          className={isValidMode ? 'text-[13px]' : 'bg-[#222d44] text-[13px]'}
        >
          {t('Open Selected')}
        </Button>
      )}
    </div>
  );
}
