import React, { useState, useEffect } from 'react';
import { useViewportGrid } from '@ohif/ui';
import { createStudyMetadata, DicomMetadataStore, ServicesManager } from '@ohif/core';
import { SeriesList } from '../components/SeriesList';
import { getStudyUidFromUrl, subscribeOnce } from '../utils';
import { PrenuvoServices } from '../types';
import { PatientStudyListService } from '../services/PatientStudyListService';
import { notifyViewportUpdateFailed } from '../utils/uiNotifications';
import { Enums as cs3DEnums, eventTarget } from '@cornerstonejs/core';
import { DisplaySet, StudyInfo } from '../types/StudyListItem';

type _Viewport = {
  viewportId: string;
  displaySetInstanceUIDs: [string];
};

type PanelStudyBrowserProp = {
  servicesManager: ServicesManager;
};

function PanelStudyBrowser({ servicesManager, ...otherProps }: PanelStudyBrowserProp) {
  const {
    hangingProtocolService,
    uiNotificationService,
    patientStudyListService,
    viewportGridService,
  } = servicesManager.services as PrenuvoServices;

  const [StudyInstanceUID, setStudyInstanceUID] = useState(getStudyUidFromUrl());
  const { activeViewportId, viewports } = useViewportGrid()[0];
  const [patientStudy, setPatientStudy] = useState<StudyInfo>();

  const onDoubleClickThumbnailHandler = displaySetInstanceUID => {
    let updatedViewports: _Viewport[] = [];
    const viewportId: string = activeViewportId;
    try {
      updatedViewports = hangingProtocolService.getViewportsRequireUpdate(
        viewportId,
        displaySetInstanceUID
      );
    } catch (error) {
      console.warn(error);
      notifyViewportUpdateFailed(uiNotificationService);
    }

    viewportGridService.setDisplaySetsForViewports(updatedViewports);
  };

  useEffect(() => {
    const studySelectionChangeListener = patientStudyListService.subscribe(
      patientStudyListService.EVENTS.ACTIVE_STUDY_CHANGED,
      ({ activeStudyUID }: { activeStudyUID: string }) => {
        setStudyInstanceUID(activeStudyUID);
        setStudyDetails(activeStudyUID);
      }
    );
    const displaysetChangeListener = patientStudyListService.subscribe(
      patientStudyListService.EVENTS.THUMBNAIL_LOADED_FOR_ACTIVE_STUDY,
      () => {
        setStudyDetails(StudyInstanceUID);
      }
    );

    return () => {
      displaysetChangeListener.unsubscribe();
      studySelectionChangeListener.unsubscribe();
    };
  }, []);

  const setStudyDetails = (studyInstanceUID: string) => {
    const displaySetsOfActiveStudy = patientStudyListService.getSeriesPanelItems();
    const createdStudyData = createStudyMetadata(studyInstanceUID);
    const createdStudyDataMap: StudyInfo = {
      StudyInstanceUID: createdStudyData.StudyInstanceUID,
      studyInstanceUid: createdStudyData.StudyInstanceUID,
      description: createdStudyData.StudyDescription,
      modalities: createdStudyData.ModalitiesInStudy.toString(),
    };
    const patientStudy =
      patientStudyListService.studies.find(study => study.studyInstanceUid === studyInstanceUID) ||
      createdStudyDataMap;

    if (patientStudy && displaySetsOfActiveStudy?.length > 0) {
      const displaySet = _mapDisplaySets(displaySetsOfActiveStudy, patientStudyListService);
      setPatientStudy({ ...patientStudy, displaySet: displaySet });
    }
  };

  useEffect(() => {
    subscribeOnce(eventTarget, cs3DEnums.Events.STACK_VIEWPORT_NEW_STACK, () => {
      DicomMetadataStore.subscribe(DicomMetadataStore.EVENTS.INSTANCES_ADDED, data => {
        if (data.StudyInstanceUID === patientStudyListService.activeStudyUID) {
          setStudyDetails(StudyInstanceUID);
        }
      });
    });
  }, []); // Here we used these events to ensure the SR will be shown immediately after creating a new SR.

  const activeDisplaySetInstanceUIDs: [string] =
    viewports.get(activeViewportId)?.displaySetInstanceUIDs;

  return (
    patientStudy && (
      <SeriesList
        study={patientStudy}
        onDoubleClickThumbnail={onDoubleClickThumbnailHandler}
        activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
        servicesManager={servicesManager}
        {...otherProps}
      />
    )
  );
}

export { PanelStudyBrowser };

function _mapDisplaySets(
  displaySets: DisplaySet[],
  patientStudyListService: PatientStudyListService
) {
  const thumbnailDisplaySets = [];
  const thumbnailNoImageDisplaySets = [];

  displaySets
    .filter(ds => !ds.excludeFromThumbnailBrowser)
    .forEach(ds => {
      const imageSrc = patientStudyListService.thumbnailImageSrcMap.get(ds.displaySetInstanceUID);
      const componentType = _getComponentType(ds);

      const array =
        componentType === 'thumbnail' ? thumbnailDisplaySets : thumbnailNoImageDisplaySets;

      array.push({
        id: ds.displaySetInstanceUID,
        displaySetInstanceUID: ds.displaySetInstanceUID,
        description: ds.SeriesDescription || '',
        seriesNumber: ds.SeriesNumber,
        modality: ds.Modality,
        seriesDate: ds.SeriesDate,
        seriesTime: ds.SeriesTime,
        numInstances: ds.numImageFrames,
        countIcon: ds.countIcon,
        studyInstanceUid: ds.StudyInstanceUID,
        messages: ds.messages,
        componentType,
        imageSrc,
        dragData: {
          type: 'displayset',
          displaySetInstanceUID: ds.displaySetInstanceUID,
        },
        isHydratedForDerivedDisplaySet: ds.isHydrated,
      });
    });

  return [...thumbnailDisplaySets, ...thumbnailNoImageDisplaySets];
}

const thumbnailNoImageModalities = ['SR', 'SEG', 'SM', 'RTSTRUCT', 'RTPLAN', 'RTDOSE', 'KO'];

function _getComponentType(ds: DisplaySet) {
  if (thumbnailNoImageModalities.includes(ds.Modality) || ds?.unsupported) {
    return 'thumbnailNoImage';
  }

  return 'thumbnail';
}
