/*
TODO: Team Trenser has been working on the Hanging Protocol schema
and we can import them here when its ready
*/

const hangingProtocolPreferences = {
  layouts: [],
};

export default hangingProtocolPreferences;
