import React, { useEffect, useState } from 'react';
import {
  HangingProtocolList,
  HangingProtocolListItemType,
  HangingProtocolListChangeEventType,
} from '@prenuvo/ui';
import { PrenuvoServices } from '@prenuvo/extension-default';
import { setHotKeysForHPs } from '../../../utils';
import { HPTabsProp } from '../../../types';

function HPTabs({ commandsManager, servicesManager, hotkeysManager }: HPTabsProp) {
  const { protocolJarService } = servicesManager.services as PrenuvoServices;
  const [isProtocolsLoading, setIsProtocolsLoading] = useState(true);
  const [selectedParentItemId, setSelectedParentItemId] = useState<string | undefined>(undefined);
  const [selectedChildItemId, setSelectedChildItemId] = useState<string | undefined>(undefined);
  const [hpToolTips, setHpToolTips] = useState<Record<string, string>>({});

  useEffect(() => {
    const { unsubscribe: unSubscribeProtocolJarServiceMatchingPJS } = protocolJarService.subscribe(
      protocolJarService.EVENTS.PROTOCOLJAR_MATCHING_COMPLETE,
      () => {
        // this could either be parent or child item
        setSelectedParentItemId(protocolJarService.selectedHPs[0]?.id);
        setIsProtocolsLoading(false);

        setHotKeysForHPs(hotkeysManager, protocolJarService.selectedHPs);
        setHpToolTips(
          protocolJarService.selectedHPs.reduce((acc, hp, i) => ({ ...acc, [hp.id]: i + 1 }), {})
        );
      }
    );
    const { unsubscribe: unSubscribeProtocolJarServiceSwitchTab } = protocolJarService.subscribe(
      protocolJarService.EVENTS.PROTOCOL_TAB_CHANGED,
      (hp: HangingProtocolListItemType) => {
        onChange({ payload: { selectedParentItemId: hp.id } });
      }
    );
    return () => {
      unSubscribeProtocolJarServiceMatchingPJS();
      unSubscribeProtocolJarServiceSwitchTab();
    };
  }, []);

  const onChange = (event: HangingProtocolListChangeEventType) => {
    const { selectedParentItemId, selectedChildItemId } = event.payload;
    setSelectedParentItemId(selectedParentItemId);
    setSelectedChildItemId(selectedChildItemId);
    const protocol = protocolJarService.selectedHPs.find(hp => hp.id === selectedParentItemId);
    if (protocol) {
      commandsManager.run(protocol.commands);
    }
  };

  return (
    <>
      {!isProtocolsLoading && (
        <HangingProtocolList
          items={protocolJarService.selectedHPs}
          selectedParentItemId={selectedParentItemId}
          selectedChildItemId={selectedChildItemId}
          onChange={onChange}
          hpToolTips={hpToolTips}
        />
      )}
    </>
  );
}

export { HPTabs };
