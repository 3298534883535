import { UINotificationService } from '@ohif/core';

export const notifyUnsupportedOperationClosing = (uiNotificationService: UINotificationService) => {
  uiNotificationService.show({
    title: 'Unsupported Operation',
    message: 'Closing not supported in this viewport.',
    type: 'warning',
  });
};

export const notifyUnsupportedOperationSplitting = (
  uiNotificationService: UINotificationService,
  splitType: string
) => {
  uiNotificationService.show({
    title: 'Unsupported Operation',
    message: `Current viewport ${splitType} splitting limit reached.`,
    type: 'warning',
  });
};
