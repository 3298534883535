/*
TODO: connect and confirm with Design on the button state styles
https://prenuvo-jira.atlassian.net/browse/PV-333
*/
import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon as HaloIcon } from '@prenuvo/halo-web';
import { TOOL_MAP } from './tool-map';

import { Icon, IconButton, Tooltip } from '@ohif/ui';

export const ToolbarButton = ({
  id,
  icon,
  label,
  commands,
  onInteraction,
  dropdownContent = null,
  //
  className,
  disabled,
  disabledText,
  size,
  toolTipClassName,
  disableToolTip = false,
  isAlignedBottom = true,
  ...rest
  //
}) => {
  const shouldShowDropdown = !!dropdownContent;
  const { isActive } = rest;

  const buttonRef = useRef(null);

  const iconEl = useMemo(() => {
    const getValidIconEl = (icon: string) => {
      if (icon) {
        if (TOOL_MAP[icon]) {
          return (
            <div className="flex h-[40px] w-[40px] items-center justify-center">
              <HaloIcon
                aria-label={icon}
                source={TOOL_MAP[icon]}
                className={classNames('flex h-[24px] w-[24px]', className, {
                  'text-blue-500': isActive,
                })}
                size="lg"
              />
            </div>
          );
        } else {
          // fallback to the OHIF Icon
          return <Icon name={icon} />;
        }
      }
      return <div>{label || 'Missing icon and label'}</div>;
    };
    return getValidIconEl(icon);
  }, [label, icon, isActive, className]);

  const sizeToUse = size ?? 'toolbar';
  const toolTipClassNameToUse =
    toolTipClassName !== undefined
      ? toolTipClassName
      : sizeToUse === 'toolbar'
        ? 'w-[40px] h-[40px]'
        : 'w-[32px] h-[32px]';

  return (
    <div
      key={id}
      ref={buttonRef}
    >
      <Tooltip
        isSticky={shouldShowDropdown}
        content={shouldShowDropdown ? dropdownContent : label}
        secondaryContent={disabled ? disabledText : null}
        tight={shouldShowDropdown}
        className={toolTipClassNameToUse}
        isDisabled={disableToolTip}
        position={isAlignedBottom ? 'bottom' : 'top'}
      >
        <IconButton
          size={sizeToUse}
          className={classNames(disabled ? 'ohif-disabled' : '')}
          onClick={() => {
            onInteraction({
              itemId: id,
              commands,
            });
          }}
          name={label}
          key={id}
          id={id}
          {...rest}
        >
          {iconEl}
        </IconButton>
      </Tooltip>
    </div>
  );
};

ToolbarButton.displayName = 'ToolbarButton';

ToolbarButton.propTypes = {
  /* Influences background/hover styling */
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  commands: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  onInteraction: PropTypes.func,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /** Tooltip content can be replaced for a customized content by passing a node to this value. */
  dropdownContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  size: PropTypes.string,
  toolTipClassName: PropTypes.string,
  disableToolTip: PropTypes.bool,
  disabled: PropTypes.bool,
  isAlignedBottom: PropTypes.bool,
  disabledText: PropTypes.string,
};
