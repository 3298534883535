import { cache, StackViewport } from '@cornerstonejs/core';

/**
 * Gets the imageIds list based on the type of the viewport
 * @param baseViewport
 */
export function getImageIds(baseViewport) {
  let imageIds;
  if (baseViewport instanceof StackViewport) {
    imageIds = baseViewport.getImageIds();
  } else {
    const defaultActor = baseViewport.getDefaultActor();
    if (defaultActor) {
      const { uid: defaultActorUID } = defaultActor;
      const volume = cache.getVolume(defaultActorUID);
      imageIds = volume.imageIds;
    }
  }
  return imageIds;
}
