import {
  MatchOptions,
  MatchResult,
  ProtocolJar,
  StudyDetail,
} from '../services/ProtocolJarService/types/protocol-jar';
import { HPMatcher } from '@ohif/core';

export function getStudyUidFromUrl() {
  const urlParams = new URLSearchParams(window.location.href.split('?')[1] ?? '');
  const studyInstId = urlParams.get('StudyInstanceUIDs');
  return studyInstId;
}

export function withScore(jar: ProtocolJar, studyDetails: StudyDetail, option: MatchOptions) {
  const customAttributeRetrievalCallbacks = {};
  const result: MatchResult = HPMatcher.match(
    studyDetails,
    jar.matchingRules,
    customAttributeRetrievalCallbacks,
    option
  );
  if (result.score > 0) {
    return {
      result,
      protocolJar: jar,
    };
  }
  return null;
}
