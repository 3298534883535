import React from 'react';
import type { ThumbnailTextProps } from './thumbnail.types';
import { cn, Stack, Typography, Button, Tooltip, Icon } from '@prenuvo/halo-web';
import { Checkmark } from '@prenuvo/halo-icon';

// TODO: We have requested that Design provide a ThumbnailText treatment
// so that we can update this component to match the design once it is available
// https://prenuvo-jira.atlassian.net/browse/PD-105

export const ThumbnailText = ({
  hasImage,
  isActive,
  description,
  isHorizontal,
  loadingProgress,
}: ThumbnailTextProps) => {
  return (
    <Tooltip
      className="rounded-lg bg-[black] p-2 text-neutral-50"
      content={<Typography>{description}</Typography>}
    >
      <Stack
        alignment={!isHorizontal ? 'start' : 'end'}
        className="flex items-center justify-between"
      >
        <Button
          size="xs"
          variant="text"
          className={{ root: 'm-0 p-0' }}
        >
          <div
            className={cn('w-[100px] truncate text-neutral-50 dark:text-neutral-50', {
              'rounded bg-black px-3 py-2': !hasImage,
              'border-2 border-blue-500': isActive && !hasImage,
            })}
          >
            <Typography
              as="span"
              className="pointer-events-none text-xs font-bold text-neutral-50 dark:text-neutral-50"
            >
              {description}
            </Typography>
          </div>
        </Button>
        <div className="text-sm text-[#A8A8A8]">
          {loadingProgress && loadingProgress < 1 && <>{Math.round(loadingProgress * 100)}%</>}
          {loadingProgress && loadingProgress === 1 && (
            <Icon
              aria-label="add layout"
              source={Checkmark}
              size="md"
              className={cn('h-[14px] w-[14px] fill-[#A8A8A8]')}
            />
          )}
        </div>
      </Stack>
    </Tooltip>
  );
};

ThumbnailText.displayName = 'ThumbnailText';
