import { DicomMetadataStore, ExtensionManager, PubSubService, ServicesManager } from '@ohif/core';
import { getStudiesForPatientByMRN } from '@ohif/extension-default';
import { PrenuvoServices } from '@prenuvo/extension-default';
import { DisplaySet, StudyInfo } from '../../types';
import {
  getStudyUidFromUrl,
  sortStudiesByDate,
  getImageSrcFromImageId,
  formatDateInStudies,
  reorderActiveDisplaySet,
} from '../../utils';

type DisplaySetCollection = {
  displaySetsAdded: DisplaySet[];
};

export const EVENTS = {
  PATIENT_STUDIES_ADDED: 'event::patient_studies_added',
  ACTIVE_STUDY_CHANGED: 'event::active_study_changed', // fired when the selection of study tab has been changed
  THUMBNAIL_LOADED_FOR_ACTIVE_STUDY: 'event::thumbnail_loaded_for_active_study', // fired when the thumbnail image of a study has been downloaded this updates the series list component
};

class PatientStudyListService extends PubSubService {
  static EVENTS = EVENTS;
  public activeStudyUID: string;
  public thumbnailImageSrcMap: Map<string, string> = new Map();
  private _studies: StudyInfo[] = [];

  public static REGISTRATION = {
    name: 'patientStudyListService',
    altName: 'PatientStudyListService',
    create: ({ servicesManager, extensionManager }) => {
      return new PatientStudyListService(servicesManager, extensionManager);
    },
  };
  private services: PrenuvoServices;
  private extensionManger: ExtensionManager;

  constructor(servicesManager: ServicesManager, extensionManager: ExtensionManager) {
    super(EVENTS);
    this.services = servicesManager.services as PrenuvoServices;
    this.extensionManger = extensionManager;
  }

  public get studies(): StudyInfo[] {
    return this._studies;
  }
  private set studies(studies) {
    this._studies = studies;
  }

  async fetchPatientStudies() {
    // get the details of study from DicomMetadataStore
    const selectedStudy = { ...DicomMetadataStore.getStudy(this.activeStudyUID) };

    // the above object is added to the studies object as its being read by the PanelStudyBrowser setStudyDetails()
    // this will be enable to show the thumbnail list without waiting to fetch the backend calls in the following lines
    this.studies = [{ ...selectedStudy, studyInstanceUid: selectedStudy.StudyInstanceUID }];

    // get the all studies from data source for the patient
    const dataSource = this.extensionManger.getActiveDataSource()[0];
    const _getStudiesForPatientByMRN = getStudiesForPatientByMRN.bind(null, dataSource);
    const activeStudyQueriedInfo: StudyInfo = await dataSource.query.studies.search({
      studyInstanceUid: this.activeStudyUID,
    });
    const studies = await _getStudiesForPatientByMRN(activeStudyQueriedInfo);

    // Add the studies object to this.studies.
    // But this.studies[0] obtained from DicomMetadataStore will already have the series and instances array.
    // In that case only do a merge
    this.studies = studies.map(study => {
      if (study.studyInstanceUid === this.activeStudyUID) {
        return { ...this.studies[0], ...study };
      } else {
        return study;
      }
    });

    if (this.studies.length > 1) {
      this.studies = sortStudiesByDate(this.studies);
      this.studies.forEach(async study => {
        // The study obtained from DicomMetadataStore will already have series array so that can be skipped
        if (!study?.series) {
          const dataSource = this.extensionManger.getActiveDataSource()[0];
          const series = await dataSource.retrieve.series.metadata({
            StudyInstanceUID: study.studyInstanceUid,
          });
          study.series = series;
        }
      });
    }
    this.studies = formatDateInStudies(this.studies);
    this.broadcastPatientStudiesAdded();
    this.startPrefetchingBasedOnHP();
    this.initListeners();
  }

  initListeners() {
    const { hangingProtocolService } = this.services;
    hangingProtocolService.subscribe(hangingProtocolService.EVENTS.PROTOCOL_CHANGED, () => {
      this.startPrefetchingBasedOnHP();
    });
  }

  private startPrefetchingBasedOnHP() {
    const {
      hangingProtocolService,
      protocolJarService,
      displaySetService,
      studyPrefetcherService,
    } = this.services;
    const currentHP = hangingProtocolService.protocol;
    const displaySetInstanceUIDsForCurrentHP =
      hangingProtocolService.getDisplaySetsMatchingHangingProtocols(currentHP?.stages) || [];
    const displaysetInstanceUidList = hangingProtocolService.getDisplaySetsMatchingHangingProtocols(
      protocolJarService.selectedHPs
        .filter(hp => hp.protocolId !== currentHP.id)
        .map(hps => hps.stage)
    );
    displaySetService.activeDisplaySets = reorderActiveDisplaySet(
      displaySetService,
      displaysetInstanceUidList,
      displaySetInstanceUIDsForCurrentHP
    );
    // Object.assign(,studyPrefetcherService)
    studyPrefetcherService._stopPrefetching();
    studyPrefetcherService?._startPrefetching();
  }

  saveThumbnailSrcForSeries() {
    const dataSource = this.extensionManger.getActiveDataSource()[0];
    const studyUidFromUrl = getStudyUidFromUrl();
    this.setActiveStudyUID(studyUidFromUrl);
    const { displaySetService } = this.services;
    displaySetService.subscribe(
      displaySetService.EVENTS.DISPLAY_SETS_ADDED,
      ({ displaySetsAdded }: DisplaySetCollection) => {
        displaySetsAdded.forEach(async dSet => {
          const displaySet = dSet;
          if (displaySet?.unsupported) {
            return;
          }

          const imageIds: string[] = dataSource.getImageIdsForDisplaySet(displaySet);
          const imageIdOfMiddleImageInSeries = imageIds[Math.floor(imageIds.length / 2)];
          if (!imageIdOfMiddleImageInSeries) {
            return;
          }
          getImageSrcFromImageId(
            imageIdOfMiddleImageInSeries,
            dSet.StudyInstanceUID === this.activeStudyUID,
            this.extensionManger
          )
            .then((thumbnailSrc: string) => {
              this.thumbnailImageSrcMap.set(dSet.displaySetInstanceUID, thumbnailSrc);
              if (displaySet.StudyInstanceUID === this.activeStudyUID) {
                this._broadcastEvent(EVENTS.THUMBNAIL_LOADED_FOR_ACTIVE_STUDY, {});
              }
            })
            .catch(error => {
              console.error(error);
            });
        });
      }
    );
  }

  private broadcastPatientStudiesAdded() {
    this._broadcastEvent(EVENTS.PATIENT_STUDIES_ADDED, {
      studies: this.studies,
    });
  }

  getSeriesPanelItems() {
    return this.services.displaySetService.getDisplaySetsBy(
      ds => ds.StudyInstanceUID === this.activeStudyUID && ds.Modality !== 'KO'
      // TODO: Add below condition to prevent display of unsupported displayset thumbnails in series list
      // && !ds?.unsupported
    ) as DisplaySet[];
  }

  setActiveStudyUID(uid: string) {
    if (!uid) {
      return false;
    }

    if (this.activeStudyUID === uid) {
      return false;
    }

    const previousActiveStudyUID = this.activeStudyUID;
    this.activeStudyUID = uid;
    this._broadcastEvent(EVENTS.ACTIVE_STUDY_CHANGED, {
      activeStudyUID: this.activeStudyUID,
      previousActiveStudyUID,
    });
    this.services.studyPrefetcherService._stopPrefetching();
    this.services.studyPrefetcherService._startPrefetching();
  }

  clearPatientStudies() {
    this.studies = [];
    this.thumbnailImageSrcMap.clear();
    this.activeStudyUID = '';
  }
}

export { PatientStudyListService };
