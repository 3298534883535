import React from 'react';
import { ThumbnailList } from '../ThumbnailList';
import { ServicesManager } from '@ohif/core';
import { PrenuvoHangingProtocolIds } from '../../utils';
import { hasPrior } from '../../commandsModule';
import { StudyInfo } from '../../types/StudyListItem';
import { ThumbnailProps } from '@prenuvo/ui';

type SeriesListProp = {
  activeDisplaySetInstanceUIDs: string[];
  study: StudyInfo;
  servicesManager: ServicesManager;
  onDoubleClickThumbnail: (displaySetInstanceUID: string) => void;
};

const SeriesList = ({
  study,
  activeDisplaySetInstanceUIDs,
  onDoubleClickThumbnail = (displaySetInstanceUID: string) => {},
  servicesManager,
  ...otherProps
}: SeriesListProp) => {
  const shouldPreventLoadThumb = (displaySetInstanceUID: string) => {
    const { hangingProtocolService, displaySetService, uiNotificationService } =
      servicesManager.services;
    const { protocol } = hangingProtocolService.getActiveProtocol();
    if (PrenuvoHangingProtocolIds.COMPARISON_MODE === protocol.id) {
      const displayset = displaySetService.getDisplaySetByUID(displaySetInstanceUID);
      return hasPrior(displayset, displaySetService, uiNotificationService);
    }
    return true;
  };

  const thumbnails = study?.displaySet as unknown as ThumbnailProps[];

  return (
    <React.Fragment>
      <div className={`flex flex-1 flex-col overflow-auto bg-black`}>
        <ThumbnailList
          orientation={undefined}
          customClassName={undefined}
          thumbnails={thumbnails}
          activeDisplaySetInstanceUIDs={activeDisplaySetInstanceUIDs}
          onThumbnailDoubleClick={onDoubleClickThumbnail}
          shouldPreventLoadThumb={shouldPreventLoadThumb}
          studyPrefetcherService={servicesManager.services.studyPrefetcherService}
          {...otherProps}
        />
      </div>
    </React.Fragment>
  );
};

export { SeriesList };
