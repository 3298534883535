import { DisplaySetService, ViewportGridService } from '@ohif/core';
import { DisplaySet } from '../types';
import { getActiveViewPortDisplaySet } from '../utils';

export function isDisplaySetFromSelection(
  displaySet: DisplaySet,
  viewportGridService: ViewportGridService,
  displaySetService: DisplaySetService
) {
  const activeDisplaySet: DisplaySet = getActiveViewPortDisplaySet(
    viewportGridService,
    displaySetService
  );
  return (
    activeDisplaySet.SeriesDescription === displaySet.SeriesDescription &&
    displaySet.SeriesDate <= activeDisplaySet.SeriesDate
  );
}
