import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HPTabs } from '../Common/HPTabs/HpTabs';
import { HaloProvider } from '@prenuvo/halo-web';

import {
  ErrorBoundary,
  UserPreferences,
  AboutModal,
  useModal,
  LoadingIndicatorProgress,
} from '@ohif/ui';
import i18n from '@ohif/i18n';
import { HangingProtocolService, hotkeys } from '@ohif/core';
import { useAppConfig } from '@state';
import { Header, PatientType, StudyModelType } from '@prenuvo/ui';
import { createHeaderData } from './headerDataHelper';
import { PrenuvoServices } from '../../types';
import { LayoutTools } from '../Common/LayoutTools';
import { Icon, IconButton, Dropdown } from '@ohif/ui';
import { PanelStudyBrowser } from '../../Panels';
import { UserPreferencesState } from '../../services/UserPreferencesService/types/userPreferences';
import { PrenuvoLayoutProps } from '../../types/PrenuvoLayoutProp';
import { DefaultExtensionUtilityModule } from '../../types/ModuleExports';
import { EN_LOCALES } from '../../assets/locales/en';
import { SidePanelWithServices } from '@ohif/extension-default';

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

export function PrenuvoLayout(props: PrenuvoLayoutProps) {
  const {
    // From Extension Module Paramsl-pom0ojn o
    extensionManager,
    servicesManager,
    hotkeysManager,
    commandsManager,
    // From Modes
    viewports,
    ViewportGridComp,
  } = props;
  const [appConfig] = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const utilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-default.utilityModule.common'
  ) as DefaultExtensionUtilityModule;
  const { Toolbar } = utilityModule.exports;

  const onClickReturnButton = () => {
    const { pathname } = location;
    const dataSourceIdx = pathname.indexOf('/', 1);
    const search =
      dataSourceIdx === -1 ? undefined : `datasources=${pathname.substring(dataSourceIdx + 1)}`;
    navigate({
      pathname: '/',
      search,
    });
  };

  const { t } = useTranslation();
  const { show, hide } = useModal();

  const [tabData, setTabData] = useState<{
    studyModels: StudyModelType[];
    patient: PatientType;
  }>({ studyModels: [], patient: undefined });

  const [selectedTabId, setSelectedTabId] = useState('');
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(appConfig.showLoadingIndicator);
  const fetchPatientStudiesCalled = useRef(false);

  const {
    hangingProtocolService,
    patientStudyListService,
    displaySetService,
    protocolJarService,
    userPreferencesService,
  } = servicesManager.services as PrenuvoServices;

  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const versionNumber = process.env.VERSION_NUMBER;
  const buildNumber = process.env.BUILD_NUM;

  const userPreferencesState: UserPreferencesState = userPreferencesService.getState();
  // TODO: when the API is ready, uncoment this line to use the remote hotkeys instead of local storage
  // const customHotkeys = userPreferencesState.custom.hotkeyBindings;
  const defaultHotkeys = userPreferencesState.default.hotkeyBindings;

  // TODO: Add type for userPreferences and remove unknown once its defined
  const updateUserPreferences = async (userId: string, newUserPreferences: unknown) => {
    try {
      await userPreferencesService.updateUserPreferences(userId, newUserPreferences);
    } catch (error) {
      console.warn('Failed to update updateUserPreferences:', error);
    }
  };

  const resetUserPreferences = async (userId: string) => {
    try {
      await userPreferencesService.resetUserPreferences(userId);
    } catch (error) {
      console.warn('Failed to reset updateUserPreferences:', error);
    }
  };

  if (!hotkeys?.initialize && hotkeys.pause && hotkeys.unpause && hotkeys.record) {
    // FIXME: This is a temporary solution to remove the console warning where the hotkeys module is missing a required `initialized` prop
    // 'initialize' was renamed to 'init' so we can patch it here to silence the warning
    hotkeys['initialize'] = hotkeys.init;
  }

  hotkeysManager.setDefaultHotKeys(defaultHotkeys);

  const menuOptions = [
    {
      title: t('Header:About'),
      icon: 'info',
      onClick: () =>
        show({
          content: AboutModal,
          title: 'About OHIF Viewer',
          contentProps: { versionNumber, buildNumber },
        }),
    },
    {
      title: t('Header:Preferences'),
      icon: 'settings',
      onClick: () =>
        show({
          title: t('UserPreferencesModal:User Preferences'),
          content: UserPreferences,
          contentProps: {
            hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
            hotkeyDefinitions,
            currentLanguage: currentLanguage(),
            availableLanguages,
            defaultLanguage,
            onCancel: () => {
              hotkeys.stopRecord();
              hotkeys.unpause();
              hide();
            },
            onSubmit: ({ hotkeyDefinitions, language }) => {
              i18n.changeLanguage(language.value);
              hotkeysManager.setHotkeys(hotkeyDefinitions);
              // optimistically update the hotkeys in the manager
              hotkeysManager.setHotkeys(hotkeyDefinitions);

              const existingUserPreferences = userPreferencesState.custom;

              const newUserPreferences = {
                ...existingUserPreferences,
                hotkeyBindings: Object.values(hotkeyDefinitions),
                language: language.value,
              };
              // TODO: update the service to use the userId of an authenticated radiologist
              // since we don't have login yet, use a placeholder userId for lcoalstorage
              // where the id can be the same for each browser until we have real authentication
              updateUserPreferences('userId', newUserPreferences);
              hide();
            },
            onReset: () => {
              hotkeysManager.restoreDefaultBindings();
              resetUserPreferences('userId');
            },
            hotkeysModule: hotkeys,
          },
        }),
    },
  ];

  if (appConfig.oidc) {
    menuOptions.push({
      title: t('Header:Logout'),
      icon: 'power-off',
      onClick: async () => {
        navigate(`/logout?redirect_uri=${encodeURIComponent(window.location.href)}`);
      },
    });
  }
  useEffect(() => {
    document.body.classList.add('bg-gray-900');
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('bg-gray-900');
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const getComponent = id => {
    const entry = extensionManager.getModuleEntry(id);

    if (!entry || !entry.component) {
      throw new Error(
        `${id} is not valid for an extension module or no component found from extension ${id}. Please verify your configuration or ensure that the extension is properly registered. It's also possible that your mode is utilizing a module from an extension that hasn't been included in its dependencies (add the extension to the "extensionDependencies" array in your mode's index.js file). Check the reference string to the extension in your Mode configuration`
      );
    }

    return { entry, content: entry.component };
  };

  useEffect(() => {
    const { unsubscribe } = hangingProtocolService.subscribe(
      HangingProtocolService.EVENTS.PROTOCOL_CHANGED,
      async () => {
        setShowLoadingIndicator(false);
        if (!fetchPatientStudiesCalled.current) {
          fetchPatientStudiesCalled.current = true; // Call the fetchPatientStudies once for avoiding re-rendering issue
          await patientStudyListService?.fetchPatientStudies();
        }
      }
    );

    return () => {
      if (!fetchPatientStudiesCalled) {
        unsubscribe();
      }
    };
  }, [hangingProtocolService, fetchPatientStudiesCalled, patientStudyListService]);

  const updateHotKeyManager = useCallback(
    (userPreferences: {
      custom: { hotkeyBindings: unknown };
      default: { hotkeyBindings: unknown };
    }) => {
      const defaultHotkeys =
        userPreferences?.default?.hotkeyBindings ?? userPreferencesState.fallbackDefaults;
      hotkeysManager.setDefaultHotKeys(defaultHotkeys);
    },
    [hotkeysManager, userPreferencesState.fallbackDefaults]
  );
  // Update
  useEffect(() => {
    const { unsubscribe } = userPreferencesService.subscribe(
      userPreferencesService.EVENTS.USER_PREFERENCES_UPDATE_COMPLETE,
      updatedUserPreferences => {
        if (updatedUserPreferences) {
          updateHotKeyManager(updatedUserPreferences);
        }
        setShowLoadingIndicator(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [userPreferencesService, updateHotKeyManager, hotkeysManager]);

  // Reset
  useEffect(() => {
    const { unsubscribe } = userPreferencesService.subscribe(
      userPreferencesService.EVENTS.USER_PREFERENCES_RESET_COMPLETE,
      updatedUserPreferences => {
        updateHotKeyManager(updatedUserPreferences);
        setShowLoadingIndicator(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [userPreferencesService, updateHotKeyManager, hotkeysManager]);

  const getViewportComponentData = viewportComponent => {
    const { entry } = getComponent(viewportComponent.namespace);

    return {
      component: entry.component,
      displaySetsToDisplay: viewportComponent.displaySetsToDisplay,
    };
  };

  const viewportComponents = viewports.map(getViewportComponentData);

  useEffect(() => {
    const patientStudyListServiceSubscription = patientStudyListService.subscribe(
      patientStudyListService.EVENTS.PATIENT_STUDIES_ADDED,
      () => {
        setSelectedTabId(patientStudyListService.activeStudyUID);
        setTabData({ ...createHeaderData(patientStudyListService.studies) });
      }
    );
    return () => {
      patientStudyListServiceSubscription.unsubscribe();
    };
  }, []);

  function handleOnSelectTab(tabID: string) {
    setSelectedTabId(tabID);
    if (tabID !== patientStudyListService.activeStudyUID) {
      patientStudyListService.setActiveStudyUID(tabID);
    }
  }

  // PV-456 - disable the back button but we can leave this code if we need to enable it in the future
  // const isReturnEnabled = !!appConfig.showStudyList
  const isReturnEnabled = false;

  return (
    <HaloProvider
      language="en"
      locales={{ en: EN_LOCALES }}
    >
      {!showLoadingIndicator ? (
        <div className="flex">
          <Header
            isReturnEnabled={isReturnEnabled}
            isSticky={false}
            onClickReturnButton={onClickReturnButton}
            data={tabData}
            onTabChange={handleOnSelectTab}
            selectedTabId={selectedTabId}
          />
          <div className="flex flex-shrink-0 items-center justify-center">
            <Dropdown
              id="options"
              showDropdownIcon={false}
              list={menuOptions}
              alignment="right"
            >
              <IconButton
                id={'options-settings-icon'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active hover:bg-primary-dark h-full w-full"
              >
                <Icon name="icon-settings" />
              </IconButton>
            </Dropdown>
          </div>
        </div>
      ) : null}
      <div
        className="relative flex flex-row flex-nowrap items-stretch overflow-hidden bg-gray-900"
        style={{ height: 'calc(100vh - 50px' }}
      >
        <ErrorBoundary
          context="Primary Toolbar"
          isPage={false}
        >
          <div className="relative flex flex-col">
            <HPTabs
              commandsManager={commandsManager}
              servicesManager={servicesManager}
              hotkeysManager={hotkeysManager}
            ></HPTabs>
            <div className="flex flex-1 overflow-y-auto overflow-x-hidden">
              <ErrorBoundary
                context="Left Panel"
                isPage={false}
              >
                <PanelStudyBrowser {...props} />
              </ErrorBoundary>
            </div>
          </div>
        </ErrorBoundary>
        {showLoadingIndicator && <LoadingIndicatorProgress className="h-full w-full bg-gray-900" />}
        {/* TOOLBAR + GRID */}
        <div className="flex h-full flex-1 flex-col">
          <ErrorBoundary
            context="Primary Toolbar"
            isPage={false}
          >
            <div className="flex justify-between">
              <div className="relative flex">
                <Toolbar servicesManager={servicesManager} />
              </div>
              <LayoutTools
                servicesManager={servicesManager}
                commandsManager={commandsManager}
                hotkeysManager={hotkeysManager}
              />
            </div>
          </ErrorBoundary>
          <div className="relative flex h-full flex-1 items-center justify-center overflow-hidden bg-gray-900">
            <ErrorBoundary
              context="Grid"
              isPage={false}
            >
              <ViewportGridComp
                servicesManager={servicesManager}
                viewportComponents={viewportComponents}
                commandsManager={commandsManager}
                hotkeysManager={hotkeysManager}
              />
            </ErrorBoundary>
          </div>
        </div>
        <ErrorBoundary
          context="Right Panel"
          isPage={undefined}
        >
          <SidePanelWithServices
            side="right"
            activeTabIndex={null}
            servicesManager={servicesManager}
            className={''}
            tabs={undefined}
            expandedWidth={160}
          />
        </ErrorBoundary>
      </div>
    </HaloProvider>
  );
}
