const spineLabels: string[] = [
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',
  'C7',
  'T1',
  'T2',
  'T3',
  'T4',
  'T5',
  'T6',
  'T7',
  'T8',
  'T9',
  'T10',
  'T11',
  'T12',
  'L1',
  'L2',
  'L3',
  'L4',
  'L5',
  'S1',
  'S2',
  'S3',
  'S4',
  'S5',
];

const warnings = {
  // Constants prefixed with T_ are UI notification headings; others are messages.
  T_ALL_MARKED: 'All Vertebrae Marked!',
  T_UNABLE_LABELLING: 'Unable to perform labelling',
  SELECT_SL_TO_PROCEED: 'Select a spine label to proceed with labeling.',
  SL_OUTSIDE_BOUNDARIES: 'The selected point lies outside the image boundaries.',
  UNABLE_LABELLING: 'Labeling on the selected point is not possible',
  ALL_MARKED: 'You have marked all the vertebrae.',
};

const annotationConfigs = {
  OFFSET: [0, -10, -25],
  LABEL_OFFSET: {
    X: 25,
    Y: 40,
  },
};

// Spine Label constants
const SL = {
  LABELS: spineLabels,
  WARNINGS: warnings,
  ANNOTATION_CONFIG: annotationConfigs,
};

export { SL };
