import { DisplaySetService, Types } from '@ohif/core';
import { DisplaySet } from '../../types';

export function getDisplaySetsMatchingHangingProtocols(
  stages: Types.HangingProtocol.ProtocolStage[]
): string[] {
  const displaySetIdList = stages
    .flatMap(stage => {
      const { viewportMatchDetails } = this._matchAllViewports(stage, null, new Map()) as {
        viewportMatchDetails: Map<string, Types.HangingProtocol.ViewportMatchDetails>;
      };
      // get the list of displayset ids
      return Array.from(viewportMatchDetails?.values())?.flatMap(matchDetails =>
        matchDetails?.displaySetsInfo?.map(dsInfo => dsInfo.displaySetInstanceUID)
      );
    })
    ?.filter(Boolean);
  const uniqueIdList = displaySetIdList?.filter(
    (item, pos) => displaySetIdList?.indexOf(item) === pos
  );
  return uniqueIdList;
}

export function reorderActiveDisplaySet(
  displaySetService: DisplaySetService,
  displaysetInstanceUidList: string[],
  displaySetInstanceUIDsForCurrentHP: string[]
): DisplaySet[] {
  const displaySetWithPriority: DisplaySet[] = [],
    otherDSNotAppearingInHP: DisplaySet[] = [],
    displaySetsForCurrentHP: DisplaySet[] = [];

  displaySetService.activeDisplaySets?.forEach(ds => {
    if (displaySetInstanceUIDsForCurrentHP.includes(ds.displaySetInstanceUID)) {
      displaySetsForCurrentHP.push(ds);
    } else if (displaysetInstanceUidList.includes(ds.displaySetInstanceUID)) {
      displaySetWithPriority.push(ds);
    } else {
      otherDSNotAppearingInHP.push(ds);
    }
  });
  return [...displaySetsForCurrentHP, ...displaySetWithPriority, ...otherDSNotAppearingInHP];
}
