import { Axis, Dimension, Direction } from '../types';

export const LOCAL_STORAGE_ITEMS = {
  USER_DEFINED_PROTOCOL_JAR: 'protocol-jars',
  CUSTOM_LAYOUTS: 'custom-layouts',
};

export const PrenuvoHangingProtocolIds = {
  COMPARISON_MODE: 'comparison',
  PREDEFINED_LAYOUT: 'predefinedLayout',
  MPR: 'mpr',
};

export const OhifHangingProtocolIds = {
  DEFAULT: 'default',
  MPR: 'mpr',
};

export const COMMANDS = {
  STORE_MEASUREMENTS: 'storeMeasurements',
  CREATE_REPORT: 'createReport',
  TOGGLE_COMPARISON_MODE: 'toggleComparisonModeHangingProtocol',
  JUMP_TO_IMAGE: 'jumpToImage',
  HANDLE_MEASUREMENTS_TOGGLE: 'handleMeasurementsToggle',
  TOGGLE_LOAD_MEASUREMENTS: 'toggleLoadMeasurements',
  TOGGLE_STACK_SYNC_SCROLL: 'toggleStackSyncScroll',
  TOGGLE_LOAD_SPINE_LABELS: 'toggleLoadSpineLabels',
};

export const MODALITIES = {
  SR: 'SR',
  KO: 'KO',
  PR: 'PR',
};

export const SR_SERIES_DESCRIPTION = {
  KEY_IMAGE_MEASUREMENT: 'Prenuvo KO SR 0',
  KEY_IMAGE_OBJECT: 'Prenuvo KO 0',
  // All the measurements are saved into the same SR series
  NON_KEY_IMAGE_MEASUREMENT: 'Prenuvo Annotations 0',
  PRESENTATION_STATE_OBJECT: ' Prenuvo KO PR 0',
};

export const VIEWPORT_CONSTANTS = {
  STACK_VIEWPORT: 'stack',
  HORIZONTAL_SPLIT: 'horizontal',
  VERTICAL_SPLIT: 'vertical',
  // Setting the minimum width/height of a viewport to 0.25 allows a maximum of 4x4 viewports.
  MINIMUM_DIMENSION: 0.25,
};

export const DIRECTIONS: {
  TOP: Direction;
  BOTTOM: Direction;
  LEFT: Direction;
  RIGHT: Direction;
  SAME: Direction;
} = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  SAME: 'same',
};

export const AXIS: { X: Axis; Y: Axis } = {
  X: 'x',
  Y: 'y',
};

export const DIMENSIONS: { HEIGHT: Dimension; WIDTH: Dimension } = {
  HEIGHT: 'height',
  WIDTH: 'width',
};

export const TOOLGROUP_IDS = {
  DEFAULT: 'default',
};

export const TOOLS_TOGGLE_STATE = {
  SHOW: 'show',
  HIDE: 'hide',
} as const;

export const TOGGLE_TOOLS = {
  MEASUREMENTS_VISIBILITY_STATE: 'measurementsVisibilityState',
  STACK_SYNC_TOOL_STATE: 'stackSyncToolState',
  SPINE_LABELS_VISIBILITY_STATE: 'spineLabelsVisibilityState',
} as const;

type ToggleToolsValues = (typeof TOGGLE_TOOLS)[keyof typeof TOGGLE_TOOLS];

export const DEFAULT_TOOLS_STATES: Record<
  ToggleToolsValues,
  (typeof TOOLS_TOGGLE_STATE)[keyof typeof TOOLS_TOGGLE_STATE]
> = {
  measurementsVisibilityState: TOOLS_TOGGLE_STATE.SHOW,
  stackSyncToolState: TOOLS_TOGGLE_STATE.SHOW,
  spineLabelsVisibilityState: TOOLS_TOGGLE_STATE.SHOW,
};

export const COORDINATE_INDICES = {
  X: 0,
  Y: 1,
  Z: 2,
};

export const TOOL_NAMES = {
  SPINELABEL: 'SpineLabel',
  ARROW_ANNOTATE: 'ArrowAnnotate',
};

export const MEASUREMENT_GROUPS = {
  SPINELABEL: 'SpineLabel',
};

export const DEFAULT_SUPPORTED_TOOLS = [
  'Length',
  'EllipticalROI',
  'CircleROI',
  'Bidirectional',
  'ArrowAnnotate',
  'Angle',
  'CobbAngle',
  'Probe',
  'RectangleROI',
  'PlanarFreehandROI',
  'SplineROI',
  'LivewireContour',
  'Probe',
  'UltrasoundDirectionalTool',
];
