/*
Each layout is based on a 3 x 3 grid displaying a maximum of 9 viewport panes.

For example, a 3_1_1 has a total of 5 viewport panes. The first column has 3 viewport panes,
the second column has 1 viewport pane (stretching from top to bottom), and the third column
also has 1 viewport pane.
*/
export enum Layout {
  Layout_1x1 = 'Layout_1x1',
  Layout_1x2 = 'Layout_1x2',
  Layout_2x1 = 'Layout_2x1',
  Layout_2x2 = 'Layout_2x2',
  Layout_1x3 = 'Layout_1x3',
  Layout_2_1_1 = 'Layout_2_1_1',
  Layout_2_2_1 = 'Layout_2_2_1',
  Layout_2_2_2 = 'Layout_2_2_2',
  Layout_3_2_1 = 'Layout_3_2_1',
  Layout_3_1_1 = 'Layout_3_1_1',
  Layout_3_3_1 = 'Layout_3_3_1',
}
