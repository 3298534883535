import { ColorSchemes } from '@prenuvo/ui';

function indexToEnum<T>(index: number, enumObject: T): T[keyof T] {
  const values = Object.values(enumObject);
  return values[index] || values[0];
}

export function getColorByIndex(index: number) {
  return indexToEnum(index, ColorSchemes);
}
