import React, { useState } from 'react';
import { Stack, cn } from '@prenuvo/halo-web';
import { LayoutPreviewBuilderProps } from './layout-selector.type';

export const LayoutPreviewBuilder: React.FC<LayoutPreviewBuilderProps> = ({
  layoutItem,
  layOutProtocolName,
  activeHangingProtocol,
  stageName,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  /**
   * Converts viewport structure to an array of column counts.
   *
   * This function takes a layout item containing viewport structure information
   * and converts it into an array where each element represents the number of
   * items in that column position.
   *
   * @param layoutItem An object containing viewport structure information,
   *                   including properties for columns and layout options.
   * @return An array where the index represents the column number (1-based) and
   *         the value represents the count of items in that column.
   */
  const convertViewportStructure = layoutItem => {
    const { properties } = layoutItem.viewportStructure;
    const { columns, layoutOptions } = properties;
    return layoutOptions.reduce((counts, { x }) => {
      counts[Math.ceil(Number(x) * columns)] += 1;
      return counts;
    }, new Array(columns).fill(0));
  };

  const viewportCountList = convertViewportStructure(layoutItem);

  return (
    <Stack
      className={cn('flex h-[46px] w-[80px] flex-row gap-[2px]')}
      data-cy={layoutItem.name}
    >
      {viewportCountList.map((num, index) => (
        <Stack
          key={index}
          className={cn('flex-1 flex-col gap-[2px]')}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onClick={onClick}
        >
          {Array.from({ length: num }, (_, i) => (
            <Stack
              className={cn(
                'border-1 flex-1 cursor-pointer border border-[#F9F9F91A] bg-[#F9F9F90D]',
                { 'rounded-tl': index === 0 && i === 0 },
                { 'rounded-bl': index === 0 && i === viewportCountList[index] - 1 },
                { 'rounded-tr': index === viewportCountList.length - 1 && i === 0 },
                {
                  'rounded-br':
                    index === viewportCountList.length - 1 && i === viewportCountList[index] - 1,
                },
                { 'border-[#004099] bg-[#0040991A]': isHovered },
                {
                  'border-[#F9F9F933] bg-[#FFFFFF33]':
                    activeHangingProtocol === layOutProtocolName && stageName === layoutItem?.name,
                }
              )}
              key={i}
            ></Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
