import React from 'react';
import { cn } from '@prenuvo/halo-web';
import type { HangingProtocolListSubItemsProps } from './hanging-protocol-list.types';
import { motion } from 'framer-motion';

export const HangingProtocolListSubItems = ({
  subItems,
  onChange,
  selectedParentItemId,
  selectedChildItemId,
}: HangingProtocolListSubItemsProps) => {
  const onSubItemClick = (subItemId: string) => {
    onChange({
      type: 'sub item',
      payload: { selectedParentItemId, selectedChildItemId: subItemId },
    });
  };

  console.log('subItems selectedParentItemId: ', selectedParentItemId);
  console.log('subItems selectedChildItemId: ', selectedChildItemId);

  return subItems?.map(({ id, label }) => (
    <motion.div
      key={id}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0 }}
      className="w-full justify-start"
    >
      <button
        key={id}
        className={cn(
          'group/HPSub items-left flex w-full cursor-pointer justify-start rounded-none p-2',
          {
            'bg-stone-700': selectedChildItemId === id,
          }
        )}
        onClick={() => onSubItemClick(id)}
      >
        <label
          className={cn(
            'text-neutral-40 ml-2 select-none text-sm text-[#A8A8A8] group-hover/HPSub:text-[#F9F9F9]',
            { 'text-[#F9F9F9]': selectedChildItemId === id }
          )}
          style={{ fontFamily: 'Lato', fontWeight: 300 }}
        >
          {label}
        </label>
      </button>
    </motion.div>
  ));
};

HangingProtocolListSubItems.displayName = 'HangingProtocolListSubItems';
