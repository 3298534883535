import { StudyInfo } from '../../types';
import { ModeProps, SeriesTableDataSourceProps } from './StudyListTypes';
import { DicomDataset } from 'dcmjs';

export function validateMode(mode: ModeProps, modalities: string, study: StudyInfo): boolean {
  // Replace '/' with '\\' for compatibility with validation logic
  const formattedModalities = modalities.replaceAll('/', '\\');
  return mode.isValidMode({
    modalities: formattedModalities,
    study,
  }).valid;
}

export function sortLoadedModes(modes: ModeProps[], study: StudyInfo): ModeProps[] {
  return modes.sort((modeA, modeB) => {
    const isValidA = validateMode(modeA, study.modalities, study);
    const isValidB = validateMode(modeB, study.modalities, study);
    return Number(isValidB) - Number(isValidA);
  });
}

export function buildQuery(
  mode: ModeProps,
  study: StudyInfo,
  filterValues: {
    configUrl?: string;
  }
): { isValidMode: boolean; query: URLSearchParams } {
  const isValidMode = validateMode(mode, study.modalities, study);
  const query = new URLSearchParams();

  if (filterValues.configUrl) {
    query.append('configUrl', filterValues.configUrl);
  }

  query.append('StudyInstanceUIDs', study.studyInstanceUid);

  return {
    isValidMode,
    query,
  };
}

export function getSeriesDetails(
  seriesInStudies: DicomDataset[],
  selectedSeriesUIds: string[]
): SeriesTableDataSourceProps[] {
  return seriesInStudies?.map(s => ({
    description: s.description || '(empty)',
    seriesNumber: s.seriesNumber ?? '',
    modality: s.modality || '',
    instances: s.numSeriesInstances || s.instances,
    isSelected: selectedSeriesUIds.includes(s.seriesInstanceUid),
    seriesInstanceUid: s.seriesInstanceUid || '',
  }));
}
