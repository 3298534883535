import { ColorSchemes } from '@prenuvo/ui';
import { getColorByIndex } from '@prenuvo/extension-default';
import { type StudyInfo } from '../../../types/StudyListItem';

// note: we currently only support a maximum of 3 study priors
// please speak with the Product & Design Team to change this limit
const STUDY_COLOR_PREFIX_MAP = {
  [ColorSchemes.Primary]: '',
  [ColorSchemes.FirstPrior]: 'P1',
  [ColorSchemes.SecondPrior]: 'P2',
  [ColorSchemes.ThirdPrior]: 'P3',
};

export const getStudyContext = (
  patientStudies: StudyInfo[],
  displaySetStudyInstanceUID: string | undefined
) => {
  const studyColors = patientStudies.map((study, index) => ({
    ...study,
    color: getColorByIndex(index),
  }));

  const foundStudyColor = displaySetStudyInstanceUID
    ? studyColors?.find(study => study.StudyInstanceUID === displaySetStudyInstanceUID)?.color
    : ColorSchemes.Default;

  const studyColor = foundStudyColor ?? ColorSchemes.Default;

  const isColorFound = Object.values(ColorSchemes).includes(studyColor);

  if (!isColorFound) {
    throw new Error('Study color not found - we do not support more than 3 priors');
  }

  const priorPrefix = STUDY_COLOR_PREFIX_MAP[studyColor];

  return { studyColor, priorPrefix };
};
