import { AXIS, DIMENSIONS, DIRECTIONS } from '@prenuvo/extension-default';
import { Direction } from '../../types';

/**
 * Finds the nearest immediate neighboring viewport in a specified direction (left, right, top, or bottom)
 * relative to the current viewport, ensuring alignment and proximity along the cross-axis.
 *
 * @param viewports - A collection of all viewports to search through.
 * @param currentViewport - The currently active viewport to find a neighbor for.
 * @param direction - The direction to search for the neighbor (bottom, top, left, right).
 * @returns The nearest aligned viewport in the given direction, or null if no neighbor is found.
 */
export const getImmediateNeighbor = (viewports, currentViewport, direction: Direction) => {
  let immediateNeighbor = null;
  const isHorizontal = direction === DIRECTIONS.LEFT || direction === DIRECTIONS.RIGHT;
  const isPositiveDirection = direction === DIRECTIONS.RIGHT || direction === DIRECTIONS.BOTTOM;

  const searchAxis = isHorizontal ? AXIS.X : AXIS.Y;
  const alignmentAxis = isHorizontal ? AXIS.Y : AXIS.X;

  viewports.forEach(vp => {
    const isDifferentViewport = vp.viewportId !== currentViewport.viewportId;

    // When searching horizontally (left/right), we're ensuring that the viewports are aligned on the Y-axis, which is perpendicular to our search direction.
    // When searching vertically (top/bottom), we're ensuring that the viewports are aligned on the X-axis, which is perpendicular to our search direction.
    const isAlignedOnCrossAxis = vp[alignmentAxis] === currentViewport[alignmentAxis];
    const isInSearchDirection = isPositiveDirection
      ? vp[searchAxis] > currentViewport[searchAxis]
      : vp[searchAxis] < currentViewport[searchAxis];
    const isCloserThanCurrentNeighbor =
      immediateNeighbor === null ||
      (isPositiveDirection
        ? immediateNeighbor[searchAxis] > vp[searchAxis]
        : immediateNeighbor[searchAxis] < vp[searchAxis]);
    const fitsWithinCurrentViewport = isHorizontal
      ? vp[DIMENSIONS.HEIGHT] <= currentViewport[DIMENSIONS.HEIGHT]
      : vp[DIMENSIONS.WIDTH] <= currentViewport[DIMENSIONS.WIDTH];

    if (
      isDifferentViewport &&
      isAlignedOnCrossAxis &&
      isInSearchDirection &&
      isCloserThanCurrentNeighbor &&
      fitsWithinCurrentViewport
    ) {
      immediateNeighbor = vp;
    }
  });

  return immediateNeighbor;
};
