import React from 'react';
import { Tooltip } from '@ohif/ui';
import { LayoutToolbarItem } from './LayoutTools.type';
import { TOOL_MAP } from '@prenuvo/ui';
import { Icon as HaloIcon, cn } from '@prenuvo/halo-web';

interface LayoutToolButtonProps {
  layoutToolbarItem: LayoutToolbarItem;
}

export const LayoutToolButton: React.FC<LayoutToolButtonProps> = ({ layoutToolbarItem }) => {
  const { icon, label, disabled, disabledText, toolTipPosition, toolTipDisabled, active } =
    layoutToolbarItem;
  return (
    <Tooltip
      content={disabled ? disabledText || 'Button Disabled' : label || 'Missing Label'}
      position={toolTipPosition || 'bottom'}
      isDisabled={toolTipDisabled || false}
    >
      {TOOL_MAP[icon] ? (
        <div
          className={cn(
            'flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full fill-[#A8A8A8] hover:bg-[#0000001F] hover:fill-[#00A5EB]',
            { 'fill-[#00A5EB]': active }
          )}
        >
          <HaloIcon
            aria-label={icon}
            source={TOOL_MAP[icon]}
            className={cn('flex h-[24px] w-[24px]', {
              'h-[40px] w-[40px] rounded-full hover:bg-[#008EE580]': icon === 'tool-more',
            })}
            size="lg"
          />
        </div>
      ) : (
        <div>{label || 'Missing Icon'}</div>
      )}
    </Tooltip>
  );
};
