import React from 'react';

import { Icon, Stack } from '@prenuvo/halo-web';
import { ChevronLeft } from '@prenuvo/halo-icon';

import { cn } from '@prenuvo/halo-web';

import { PrenuvoMark } from './prenuvo-mark';
import { StudyTabs } from './study-tabs';
import { PatientInfo } from './patient-info';

import { type HeaderProps } from './header.types';

const stickyClasses = 'sticky top-0';
const notStickyClasses = 'relative';

export const Header = ({
  className,
  isReturnEnabled = false, // TODO: ask Design if we require this existing functionality to go back to previous page
  isSticky = false,
  onClickReturnButton, // TODO: related to above
  data,
  onTabChange,
  selectedTabId,
  ...props
}: HeaderProps) => {
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  const { studyModels, patient } = data;

  return (
    <Stack
      className={cn(
        'z-20 inline-block w-full border-black bg-stone-900 px-1',
        isSticky && stickyClasses,
        !isSticky && notStickyClasses,
        className
      )}
      data-cy="header"
    >
      <Stack
        className="relative h-[48px] bg-stone-900"
        justificaiton="center"
      >
        <Stack
          className="absolute left-0 top-1/2 -translate-y-1/2"
          alignment="center"
        >
          <Stack
            className={cn('mr-3 inline-flex', isReturnEnabled && 'cursor-pointer')}
            data-cy="return-to-work-list"
            alignment="center"
          >
            {isReturnEnabled && (
              <span onClick={onClickReturn}>
                <Icon
                  className="text-primary-active w-8 fill-[#999] stroke-1"
                  source={ChevronLeft}
                  aria-label="Chevron Left"
                />
              </span>
            )}
          </Stack>
          <Stack
            alignment="center"
            gap={4}
          >
            <Stack className="ml-1">
              <PrenuvoMark />
            </Stack>
            <Stack className="ml-1">
              <StudyTabs
                studyModels={studyModels}
                onChange={onTabChange}
                selectedId={selectedTabId}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack
          alignment="center"
          className="absolute right-0 top-1/2 -translate-y-1/2 select-none"
        >
          {patient ? <PatientInfo patient={patient} /> : null}
        </Stack>
      </Stack>
    </Stack>
  );
};
