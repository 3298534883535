import { adaptersSR } from '@cornerstonejs/adapters';
import { TOOL_NAMES, MEASUREMENT_GROUPS } from '../../utils';
import {
  Metadata,
  TID300RepresentationArguments,
  SopInstanceUIDToImageIdMap,
  ImageToWorldCoords,
  ToolState,
  WorldToImageCoords,
  Tool,
} from '../../types';

const { CORNERSTONE_3D_TAG, ArrowAnnotate } = adaptersSR.Cornerstone3D;

const SPINE_LABEL = TOOL_NAMES.SPINELABEL;
const trackingIdentifierTextValue = `${CORNERSTONE_3D_TAG}:${SPINE_LABEL}`;

class SpineLabel extends ArrowAnnotate {
  static toolType = SPINE_LABEL;
  static utilityToolType = SPINE_LABEL;

  static TID300Representation = class extends ArrowAnnotate.TID300Representation {
    contentItem() {
      const originalMeasurement = super.contentItem();

      const spineContext = {
        RelationshipType: 'HAS OBS CONTEXT',
        ValueType: 'TEXT',
        ConceptNameCodeSequence: {
          CodeValue: '125007',
          CodingSchemeDesignator: 'DCM',
          CodeMeaning: 'Measurement Group',
        },
        TextValue: MEASUREMENT_GROUPS.SPINELABEL,
      };

      return [...originalMeasurement, spineContext];
    }
  };

  static isValidCornerstoneTrackingIdentifier(TrackingIdentifier: string): boolean {
    return TrackingIdentifier === trackingIdentifierTextValue;
  }

  static getMeasurementData(
    MeasurementGroup: Record<string, unknown>,
    sopInstanceUIDToImageIdMap: SopInstanceUIDToImageIdMap,
    imageToWorldCoords: ImageToWorldCoords,
    metadata: Metadata
  ): ToolState {
    const measurementData = super.getMeasurementData(
      MeasurementGroup,
      sopInstanceUIDToImageIdMap,
      imageToWorldCoords,
      metadata
    );

    return measurementData;
  }

  static getTID300RepresentationArguments(
    tool: Tool,
    worldToImageCoords: WorldToImageCoords
  ): TID300RepresentationArguments {
    const args = super.getTID300RepresentationArguments(tool, worldToImageCoords);

    args.trackingIdentifierTextValue = trackingIdentifierTextValue;

    return args;
  }
}

export { SpineLabel };
