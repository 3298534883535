import { Types } from '@ohif/core';

import getDataSourcesModule from './getDataSourcesModule.js';
import getLayoutTemplateModule from './getLayoutTemplateModule.js';
import getPanelModule from './getPanelModule';
import getSopClassHandlerModule from './getSopClassHandlerModule.js';
import getToolbarModule from './getToolbarModule';
import getCommandsModule from './commandsModule';
import getHangingProtocolModule from './getHangingProtocolModule';
import getStudiesForPatientByMRN from './Panels/getStudiesForPatientByMRN';
import getCustomizationModule from './getCustomizationModule';
import getViewportModule from './getViewportModule';
import { id } from './id.js';
import preRegistration from './init';
import {
  ContextMenuController,
  CustomizableContextMenuTypes,
  ContextMenuItemsBuilder,
} from './CustomizableContextMenu';
import * as dicomWebUtils from './DicomWebDataSource/utils';
import { createReportDialogPrompt } from './Panels';
import createReportAsync from './Actions/createReportAsync';
import { Toolbar } from './Toolbar/Toolbar';
import findSRWithSameSeriesDescription from './utils/findSRWithSameSeriesDescription';
import StaticWadoClient from './DicomWebDataSource/utils/StaticWadoClient';
import SidePanelWithServices from './Components/SidePanelWithServices';
import reuseCachedLayout from './utils/reuseCachedLayouts';
import { cleanDenaturalizedDataset } from './DicomWebDataSource/utils';

const defaultExtension: Types.Extensions.Extension = {
  /**
   * Only required property. Should be a unique value across all extensions.
   */
  id,
  preRegistration,
  getDataSourcesModule,
  getViewportModule,
  getLayoutTemplateModule,
  getPanelModule,
  getHangingProtocolModule,
  getSopClassHandlerModule,
  getToolbarModule,
  getCommandsModule,
  getUtilityModule({ servicesManager }) {
    return [
      {
        name: 'common',
        exports: {
          getStudiesForPatientByMRN,
          Toolbar,
        },
      },
    ];
  },

  getCustomizationModule,
};

export default defaultExtension;

export {
  ContextMenuController,
  ContextMenuItemsBuilder, // PV-40 Viewport RCCM - display main menu and sub menus together
  CustomizableContextMenuTypes,
  getStudiesForPatientByMRN,
  dicomWebUtils,
  createReportDialogPrompt,
  createReportAsync,
  findSRWithSameSeriesDescription, // PV-166
  SidePanelWithServices, // PV-276 - ohif update for custom viewer layout
  StaticWadoClient,
  reuseCachedLayout, // PV-512 Reset the viewport state when toggling comparison mode
  cleanDenaturalizedDataset,
};
