import React from 'react';
import { cn } from '@prenuvo/halo-web';
import type { ThumbnailViewProps } from './thumbnail.types';
import { ThumbnailSkeleton } from './thumbnail-skeleton';
import { ThumbnailImage } from './thumbnail-image';
import { ThumbnailText } from './thumbnail-text';

export const ThumbnailView = React.forwardRef<HTMLDivElement, ThumbnailViewProps>(
  (
    {
      displaySetInstanceUID,
      className,
      imageSrc,
      imageAltText,
      description,
      isActive,
      onDoubleClick,
      isHorizontal,
      isHydratedForDerivedDisplaySet,
      componentType,
      loadingProgress,
    },
    ref
  ) => {
    const hasImage = componentType === 'thumbnail';

    // isHydratedForDerivedDisplaySet must be defined to be used to display a loading skeleton
    // so use === false not ! as it could be undefined
    if (hasImage && isHydratedForDerivedDisplaySet === false) {
      return <ThumbnailSkeleton />;
    }

    return (
      <div
        className={cn('mb-6 cursor-pointer select-none px-2 outline-none', className)}
        id={`thumbnail-${displaySetInstanceUID}`}
        data-cy={`study-browser-thumbnail`}
        onDoubleClick={onDoubleClick}
        role="button"
        tabIndex={0}
      >
        <div
          ref={ref}
          className={cn('flex', {
            'flex-col items-center': !isHorizontal,
            'items-end': isHorizontal,
          })}
        >
          <div
            className={cn('flex items-center justify-center overflow-hidden rounded-lg', {
              'border-2 border-blue-500': isActive && hasImage,
              'border-none hover:border-neutral-400': !isActive,
            })}
          >
            {hasImage ? (
              <ThumbnailImage
                imageSrc={imageSrc}
                imageAltText={imageAltText}
                isHorizontal={isHorizontal}
              />
            ) : null}
          </div>
          <ThumbnailText
            isActive={isActive}
            isHorizontal={isHorizontal}
            description={description}
            hasImage={hasImage}
            loadingProgress={loadingProgress}
          />
        </div>
      </div>
    );
  }
);

ThumbnailView.displayName = 'ThumbnailView';
