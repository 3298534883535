import { OhifHangingProtocolIds, PrenuvoHangingProtocolIds } from './utils';
import { ToolbarButton as PrenuvoToolbarButton } from '@prenuvo/ui';
import { ToolbarButtonWrapper as PrenuvoToolbarButtonWrapper } from './components';

const getToggledClassName = (isToggled: boolean) => {
  return isToggled
    ? '!text-primary-active'
    : '!text-common-bright hover:!bg-primary-dark hover:text-primary-light';
};

export function getToolbarModule({ servicesManager, hotkeysManager }: withAppTypes) {
  const { hangingProtocolService } = servicesManager.services;

  return [
    // 'ohif.radioGroup' is added here to override the object returned from \extensions\default\src\getToolbarModule.tsx
    {
      name: 'ohif.radioGroup',
      defaultComponent: props =>
        PrenuvoToolbarButtonWrapper({ ...props, hotkeysManager }) ?? PrenuvoToolbarButton,
    },
    {
      name: 'evaluate.prenuvo.comparison',
      evaluate: () => {
        const { protocol } = hangingProtocolService.getActiveProtocol();

        const isComparison = protocol?.id === PrenuvoHangingProtocolIds.COMPARISON_MODE;

        return {
          disabled: false,
          className: getToggledClassName(isComparison),
        };
      },
    },
    {
      name: 'evaluate.prenuvo.mpr',
      evaluate: () => {
        const { protocol } = hangingProtocolService.getActiveProtocol();

        const isMPR = protocol?.id === OhifHangingProtocolIds.MPR;

        return {
          disabled: false,
          className: getToggledClassName(isMPR),
        };
      },
    },
  ];
}
