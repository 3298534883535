import { StateSyncService } from '@ohif/core';
import { DEFAULT_TOOLS_STATES, TOOLS_TOGGLE_STATE } from '@prenuvo/extension-default';
import { UserPreferencesService } from '@prenuvo/extension-default/src/services';

type ToolsToggleStateProps = typeof DEFAULT_TOOLS_STATES;

export const toggleToolState = (
  stateSyncService: StateSyncService,
  userPreferencesService: UserPreferencesService,
  toolToToggle: string
): void => {
  const currentToolsToggleState: ToolsToggleStateProps =
    (stateSyncService.getState().toolsToggleState as unknown as ToolsToggleStateProps) ??
    DEFAULT_TOOLS_STATES;

  const toolsState =
    currentToolsToggleState[toolToToggle] === TOOLS_TOGGLE_STATE.SHOW
      ? TOOLS_TOGGLE_STATE.HIDE
      : TOOLS_TOGGLE_STATE.SHOW;

  stateSyncService?.store({
    toolsToggleState: {
      ...currentToolsToggleState,
      [toolToToggle]: toolsState,
    },
  });

  userPreferencesService._broadcastEvent(
    userPreferencesService.EVENTS.USER_PREFERENCES_UPDATE_COMPLETE,
    { payload: currentToolsToggleState }
  );
};

export const getToolState = (stateSyncService: StateSyncService, toolToCheck: string): string => {
  if (toolToCheck) {
    const currentToolsToggleState: ToolsToggleStateProps =
      (stateSyncService.getState().toolsToggleState as unknown as ToolsToggleStateProps) ??
      DEFAULT_TOOLS_STATES;

    return currentToolsToggleState[toolToCheck] === TOOLS_TOGGLE_STATE.SHOW
      ? TOOLS_TOGGLE_STATE.SHOW
      : TOOLS_TOGGLE_STATE.HIDE;
  }
  return TOOLS_TOGGLE_STATE.SHOW;
};
