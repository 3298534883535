import * as React from 'react';
export const ThreeVerticalDots = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="transparent"
      fillOpacity={0.5}
      d="M0 0h40v40H0z"
    />
    <path
      fill="#F9F9F9"
      fillRule="evenodd"
      d="M22.037 13.89a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0Zm0 6.157a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0Zm-1.666 7.824a1.667 1.667 0 1 0 0-3.333 1.667 1.667 0 0 0 0 3.333Z"
      clipRule="evenodd"
    />
  </svg>
);
