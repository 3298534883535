import { useEffect, useState, MouseEvent } from 'react';

export const useDragScrollbar = () => {
  const [isDraggingScrollbar, setIsDraggingScrollbar] = useState(false);

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'INPUT' && target.classList.contains('imageSlider')) {
      setIsDraggingScrollbar(true); // Disable dragging if clicking on the ImageScrollbar input
    } else {
      setIsDraggingScrollbar(false); // Enable dragging elsewhere
    }
  };

  const handleMouseUp = () => {
    setIsDraggingScrollbar(false);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return { isDraggingScrollbar, handleMouseDown };
};
