import { annotation } from '@cornerstonejs/tools';
import { hydrateStructuredReport } from '@ohif/extension-cornerstone-dicom-sr';
import {
  getStudyUidFromUrl,
  MODALITIES,
  TOOLS_TOGGLE_STATE,
  PrenuvoServices,
} from '@prenuvo/extension-default';
import { Measurement, SRDisplaySet } from '../../types';
import { toggleToolState, getToolState } from '../toolsToggle';
import { PrenuvoAppConfig } from '@prenuvo/extension-dicom-ko';
import {
  ExtensionManager,
  ServicesManager,
  StateSyncService,
  ViewportGridService,
} from '@ohif/core';
import { PrenuvoMeasurementService } from '../../services';
import { CornerstoneViewportService } from '@ohif/extension-cornerstone';

const findSRDisplaySet = (
  displaySets: SRDisplaySet[],
  activeStudyUID: string
): SRDisplaySet | undefined => {
  return displaySets.find(
    ds =>
      ds.Modality === MODALITIES.SR &&
      ds.StudyInstanceUID === (activeStudyUID || getStudyUidFromUrl())
  );
};

const reRenderViewports = (
  viewportGridService: ViewportGridService,
  cornerstoneViewportService: CornerstoneViewportService
) => {
  const { viewports } = viewportGridService.getState();
  viewports.forEach((_, key) => {
    const viewport = cornerstoneViewportService.getCornerstoneViewport(key);
    viewport?.render();
  });
};

const filterMeasurements = (
  measurements: Measurement[],
  toolsToIgnore: string[] = []
): Measurement[] => {
  return measurements.filter(measurement => !toolsToIgnore.includes(measurement.toolName));
};

const hideMeasurements = (
  stateSyncService: StateSyncService,
  measurementService: PrenuvoMeasurementService,
  toolsToIgnore: string[] = []
): void => {
  const annotationManager = annotation.state.getAnnotationManager();
  const measurementsToHide = filterMeasurements(
    measurementService.getMeasurements(),
    toolsToIgnore
  );
  stateSyncService?.store({ MeasurementSaveState: { doSaveMeasurement: false } });

  measurementsToHide.forEach(measurement => {
    annotationManager.removeAnnotation(measurement.uid);
    measurementService.remove(measurement.uid, {}, {});
    measurementService.oldMeasurements.push({
      ...measurement,
      isOldMeasurement: true,
    });
  });

  stateSyncService?.store({ MeasurementSaveState: { doSaveMeasurement: true } });
};

const showMeasurements = async (
  srDisplaySet: SRDisplaySet,
  servicesManager: ServicesManager,
  extensionManager: ExtensionManager,
  appConfig: PrenuvoAppConfig,
  toolsToIgnore: string[]
) => {
  const { measurementService } = servicesManager.services as PrenuvoServices;
  if (!srDisplaySet.isLoaded) {
    await srDisplaySet.load(srDisplaySet, servicesManager, extensionManager);
  }

  if (srDisplaySet?.measurements) {
    hydrateStructuredReport(
      { servicesManager, extensionManager, appConfig },
      srDisplaySet.displaySetInstanceUID,
      toolsToIgnore
    );

    measurementService.oldMeasurements = measurementService.oldMeasurements.filter(measurement =>
      toolsToIgnore.includes(measurement.toolName)
    );
  }
};

const toggleMeasurements = async (
  toolsState: string,
  srDisplaySet: SRDisplaySet,
  stateSyncService: StateSyncService,
  measurementService: PrenuvoMeasurementService,
  servicesManager: ServicesManager,
  extensionManager: ExtensionManager,
  appConfig: PrenuvoAppConfig,
  toolsToIgnore: string[]
): Promise<boolean> => {
  if (toolsState === TOOLS_TOGGLE_STATE.SHOW) {
    showMeasurements(srDisplaySet, servicesManager, extensionManager, appConfig, toolsToIgnore);
    return true;
  } else {
    hideMeasurements(stateSyncService, measurementService, toolsToIgnore);
    return false;
  }
};

export const handleMeasurementsToggle = async ({
  activeStudyUID,
  servicesManager,
  extensionManager,
  appConfig,
  toggleToolsKey,
  toolsToIgnore = [],
}: {
  activeStudyUID: string;
  servicesManager: ServicesManager;
  extensionManager: ExtensionManager;
  appConfig: PrenuvoAppConfig;
  toggleToolsKey: string;
  toolsToIgnore: string[];
}): Promise<void> => {
  try {
    const {
      measurementService,
      displaySetService,
      viewportGridService,
      cornerstoneViewportService,
      stateSyncService,
      userPreferencesService,
    } = servicesManager.services as PrenuvoServices;
    const displaySets = displaySetService?.getActiveDisplaySets() as SRDisplaySet[];

    if (!displaySets.length) {
      return;
    }
    const srDisplaySet = findSRDisplaySet(displaySets, activeStudyUID);

    if (!srDisplaySet) {
      return;
    }

    if (toggleToolsKey) {
      toggleToolState(stateSyncService, userPreferencesService, toggleToolsKey);
    }

    const toolsState = getToolState(stateSyncService, toggleToolsKey);
    stateSyncService?.store({ MeasurementSaveState: { doSaveMeasurement: false } });

    if (!measurementService.oldMeasurements) {
      measurementService.oldMeasurements = [];
    }

    srDisplaySet.isLoaded = await toggleMeasurements(
      toolsState,
      srDisplaySet,
      stateSyncService,
      measurementService,
      servicesManager,
      extensionManager,
      appConfig,
      toolsToIgnore
    );

    reRenderViewports(viewportGridService, cornerstoneViewportService);
  } catch (error) {
    console.error('Error loading measurements:', error);
  }
};
