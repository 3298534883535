import { ThumbnailSkeleton } from '@prenuvo/ui';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { cn } from '@prenuvo/halo-web';
import { KeyImageThumbnailProps } from './KeyImageTypes';

export const KeyImageThumbnail = ({
  imageIndex,
  thumbnail,
  handleSelectedThumbnail,
  isSelected,
  displaySetInstanceUid,
}: KeyImageThumbnailProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag({
    type: 'displayset',
    item: {
      type: 'displayset',
      displaySetInstanceUID: displaySetInstanceUid,
    },
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        handleSelectedThumbnail(imageIndex);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`${isDragging ? 'opacity-50' : ''}`}
    >
      {!isImageLoaded || !thumbnail ? <ThumbnailSkeleton /> : null}
      {thumbnail && (
        <img
          src={thumbnail}
          alt={'Key Images'}
          onLoad={() => setIsImageLoaded(true)}
          className={cn('m-2 w-36 rounded-lg', {
            'border-2 border-blue-500': isSelected,
            'border-none hover:border-neutral-400': !isSelected,
          })}
          onDoubleClick={() => {
            handleSelectedThumbnail(imageIndex);
          }}
        />
      )}
    </div>
  );
};
