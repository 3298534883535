import React from 'react';
import { Stack } from '@prenuvo/halo-web';
import { StudyTabItem } from './study-tab-item';
import { StudyTabsProps } from './study-tabs.types';
import { ColorSchemes } from '../../../utils';

function getColorByIndex(index: number) {
  const colors = Object.values(ColorSchemes);
  return colors[index] || ColorSchemes.ThirdPrior;
}

export const StudyTabs = ({ studyModels, selectedId, onChange }: StudyTabsProps) => {
  const handleTabClick = (tabId: string) => {
    onChange(tabId);
  };

  if (!studyModels || !studyModels?.length) {
    return null;
  }

  return (
    <Stack
      className="ml-[40px] h-[48px]"
      alignment="center"
      gap={0}
    >
      {studyModels.map((model, index) => (
        <StudyTabItem
          key={model.id}
          model={model}
          selectedTabId={selectedId}
          onClick={handleTabClick}
          color={getColorByIndex(index)}
        />
      ))}
    </Stack>
  );
};

StudyTabs.displayName = 'StudyTabs';
