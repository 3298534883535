import { Types } from '@ohif/core';

type ComparisonHangingProtocol = Types.HangingProtocol.Protocol & {
  protocolMatchingRules?: (Types.HangingProtocol.MatchingRule & {
    constraint: Types.HangingProtocol.Constraint & { notNull: boolean };
  })[];
};

const defaultDisplaySetSelector = {
  studyMatchingRules: [
    {
      // The priorInstance is a study counter that indicates what position this study is in
      // and the value comes from the options parameter.
      attribute: 'studyInstanceUIDsIndex',
      from: 'options',
      required: true,
      constraint: {
        equals: { value: 0 },
      },
    },
  ],
  seriesMatchingRules: [
    {
      attribute: 'numImageFrames',
      constraint: {
        greaterThan: { value: 0 },
      },
    },
    // This display set will select the specified items by preference
    // It has no affect if nothing is specified in the URL.
    {
      attribute: 'isDisplaySetFromSelection',
      weight: 10,
      constraint: {
        equals: true,
      },
    },
  ],
};

const priorDisplaySetSelector = {
  studyMatchingRules: [
    {
      // The priorInstance is a study counter that indicates what position this study is in
      // and the value comes from the options parameter.
      attribute: 'studyInstanceUIDsIndex',
      from: 'options',
      required: true,
      constraint: {
        equals: { value: 1 },
      },
    },
  ],
  seriesMatchingRules: [
    {
      attribute: 'numImageFrames',
      constraint: {
        greaterThan: { value: 0 },
      },
    },
    // This display set will select the specified items by preference
    // It has no affect if nothing is specified in the URL.
    {
      attribute: 'isDisplaySetFromSelection',
      weight: 10,
      constraint: {
        equals: true,
      },
    },
  ],
};

const currentDisplaySet = {
  id: 'defaultDisplaySetId',
};

const priorDisplaySet = {
  id: 'priorDisplaySetId',
};

const currentViewport = {
  viewportOptions: {
    viewportId: 'comparisonViewport',
    toolGroupId: 'default',
    allowUnmatchedView: true,
  },
  displaySets: [currentDisplaySet],
};

const priorViewport = {
  viewportOptions: {
    viewportId: 'priorViewport',
    toolGroupId: 'default',
    allowUnmatchedView: true,
  },
  displaySets: [priorDisplaySet],
};

/**
 * This hanging protocol can be activated by enabling the comparison mode
 */
export const hpcomparison: ComparisonHangingProtocol = {
  id: 'comparison',
  description: 'Compare two studies in various layouts',
  name: 'Compare Two Studies',
  numberOfPriorsReferenced: 1,
  protocolMatchingRules: [
    {
      id: 'Two Studies',
      weight: 1000,
      attribute: 'StudyInstanceUID',
      // The 'from' attribute says where to get the 'attribute' value from.  In this case
      // prior means the second study in the study list.
      from: 'prior',
      required: true,
      constraint: {
        notNull: true,
      },
    },
  ],
  toolGroupIds: ['default'],
  displaySetSelectors: {
    defaultDisplaySetId: defaultDisplaySetSelector,
    priorDisplaySetId: priorDisplaySetSelector,
  },
  defaultViewport: {
    viewportOptions: {
      viewportType: 'stack',
      toolGroupId: 'default',
      allowUnmatchedView: true,
    },
    displaySets: [
      {
        id: 'defaultDisplaySetId',
        matchedDisplaySetsIndex: -1,
      },
    ],
  },
  stages: [
    {
      name: 'side by side',
      stageActivation: {
        enabled: {
          minViewportsMatched: 2,
        },
      },
      viewportStructure: {
        layoutType: 'grid',
        properties: {
          rows: 1,
          columns: 2,
        },
      },
      viewports: [currentViewport, priorViewport],
    },
  ],
};
