import {
  DisplaySetService,
  HangingProtocolService,
  StateSyncService,
  UINotificationService,
  ViewportGridService,
} from '@ohif/core';
import {
  DisplaySet,
  LayoutOption,
  SplitType,
  State,
  Viewport,
  ViewportOptions,
  ViewportsByPosition,
} from '../types';
import { notifyComparisonPriorNotFound } from '../utils/uiNotifications';
import { PrenuvoHangingProtocolIds, VIEWPORT_CONSTANTS } from '../utils';
import { PatientStudyListService } from '../services/PatientStudyListService';

export function hasPrior(
  activeDisplaySet: DisplaySet,
  displaySetService: DisplaySetService,
  uiNotificationService: UINotificationService
): boolean {
  const displaySetCache: Map<string, DisplaySet> = displaySetService.getDisplaySetCache();

  const matchingDisplaySets = Array.from(displaySetCache.values()).filter(
    displaySet =>
      activeDisplaySet.SeriesDescription === displaySet.SeriesDescription &&
      displaySet.SeriesDate <= activeDisplaySet.SeriesDate
  );

  if (matchingDisplaySets.length > 1) {
    return true;
  }

  notifyComparisonPriorNotFound(uiNotificationService);
  return false;
}

export function runProtocolForComparison(
  patientStudyListService: PatientStudyListService,
  hangingProtocolService: HangingProtocolService,
  displaySetService: DisplaySetService
) {
  const activeStudyUID = patientStudyListService.activeStudyUID;
  const activeStudyIndex = patientStudyListService.studies.findIndex(
    study => study.studyInstanceUid === activeStudyUID
  );
  const activeStudy =
    activeStudyIndex !== -1 ? patientStudyListService.studies[activeStudyIndex] : null;

  const previousStudy = patientStudyListService.studies[activeStudyIndex + 1];
  hangingProtocolService.run(
    {
      studies: [activeStudy, previousStudy],
      displaySets: displaySetService.getActiveDisplaySets(),
      activeStudy: activeStudy,
    },
    PrenuvoHangingProtocolIds.COMPARISON_MODE
  );
}

export const layoutFindOrCreate = (
  hangingProtocolService: HangingProtocolService,
  viewportsByPosition: ViewportsByPosition,
  position: number,
  positionId: string,
  options: Record<string, unknown>
): Viewport => {
  const byPositionViewport = viewportsByPosition?.[positionId];
  if (byPositionViewport) {
    return { ...byPositionViewport };
  }
  const { protocolId, stageIndex } = hangingProtocolService.getState();

  // Setup the initial in display correctly for initial view/select
  if (!options.inDisplay) {
    options.inDisplay = [...viewportsByPosition.initialInDisplay];
  }

  let viewportDetails = hangingProtocolService.getMissingViewport(protocolId, stageIndex, options);

  if (viewportDetails.displaySetsInfo.length === 0) {
    viewportDetails = hangingProtocolService.getMissingViewport('default', stageIndex, options);
  }
  if (viewportDetails) {
    const displaySetInstanceUIDs = viewportDetails.displaySetsInfo.map(
      it => it.displaySetInstanceUID
    );
    return {
      displaySetInstanceUIDs,
      displaySetOptions: viewportDetails.displaySetsInfo.map(it => it.displaySetOptions),
      viewportOptions: {
        ...(viewportDetails.viewportOptions as ViewportOptions),
      },
    };
  }
};

export const updateViewportsByPosition = (
  viewports: Viewport[],
  viewportsByPosition: ViewportsByPosition
): void => {
  const initialInDisplay = [];

  viewports.forEach(viewport => {
    if (viewport.positionId) {
      const storedViewport = { ...viewport, viewportOptions: { ...viewport.viewportOptions } };
      viewportsByPosition[viewport.positionId] = storedViewport;

      if (storedViewport.displaySetInstanceUIDs) {
        initialInDisplay.push(...storedViewport.displaySetInstanceUIDs);
      }
    }
  });

  // Store the initially displayed elements
  viewportsByPosition.initialInDisplay = initialInDisplay;
};

export const findViewportsByPosition = (
  state: State,
  syncService: StateSyncService
): { viewportsByPosition: ViewportsByPosition } => {
  const viewportsArray = Array.from(state.viewports.values());
  const syncState = syncService.getState();
  const viewportsByPosition = {
    ...syncState.viewportsByPosition,
  } as unknown as ViewportsByPosition;

  updateViewportsByPosition(viewportsArray, viewportsByPosition);

  return { viewportsByPosition };
};

export const getSplitConfig = (
  splitType: SplitType,
  layout: { numCols: number; numRows: number },
  layoutToSplit: LayoutOption
) => {
  const isVertical = splitType === VIEWPORT_CONSTANTS.VERTICAL_SPLIT;
  const newSize = isVertical ? layoutToSplit.width / 2 : layoutToSplit.height / 2;

  if (newSize < VIEWPORT_CONSTANTS.MINIMUM_DIMENSION) {
    // limiting viewport size
    return;
  }

  const newLayoutEntries: LayoutOption[] = [
    {
      ...layoutToSplit,
      [isVertical ? 'width' : 'height']: newSize,
    },
    {
      ...layoutToSplit,
      [isVertical ? 'x' : 'y']: layoutToSplit[isVertical ? 'x' : 'y'] + newSize,
      [isVertical ? 'width' : 'height']: newSize,
      positionId: `${layout.numRows + (isVertical ? 0 : 1)}-${layout.numCols + (isVertical ? 1 : 0)}`,
    },
  ];

  return {
    numRows: layout.numRows + (isVertical ? 0 : 1),
    numCols: layout.numCols + (isVertical ? 1 : 0),
    newLayoutEntries,
  };
};

export const updateLayout = (
  viewportGridService: ViewportGridService,
  stateSyncService: StateSyncService,
  hangingProtocolService: HangingProtocolService,
  layoutOptions: LayoutOption[],
  numRows: number,
  numCols: number
) => {
  const state = viewportGridService.getState();
  const stateReduce = findViewportsByPosition(state, stateSyncService);
  const findOrCreateViewport = layoutFindOrCreate.bind(
    null,
    hangingProtocolService,
    stateReduce.viewportsByPosition
  );

  viewportGridService.setLayout({
    numRows,
    numCols,
    layoutOptions,
    findOrCreateViewport,
  });
};
