import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, CheckBox } from '@ohif/ui';
import { StudyListProps } from './StudyListTypes';

export function StudyList({
  seriesTableColumns,
  seriesTableDataSource,
  children,
  selectedSeriesUIds,
  setSelectedSeriesUIds,
}: StudyListProps) {
  const handleAllSeriesSelection = (isAllSeriesSelected: boolean) => {
    if (isAllSeriesSelected) {
      const allSeriesUIds = seriesTableDataSource.map(series => series.seriesInstanceUid);
      setSelectedSeriesUIds(allSeriesUIds);
    } else {
      setSelectedSeriesUIds([]);
    }
  };

  const handleSeriesSelection = seriesInstanceUid => {
    const updatedSelectedUIds = selectedSeriesUIds.includes(seriesInstanceUid)
      ? selectedSeriesUIds.filter(uid => uid !== seriesInstanceUid)
      : [...selectedSeriesUIds, seriesInstanceUid];

    setSelectedSeriesUIds(updatedSelectedUIds);
  };

  const isAllSeriesSelected =
    seriesTableDataSource.length > 0 && seriesTableDataSource.length === selectedSeriesUIds.length;

  return (
    <div className="w-full bg-black py-4 pl-0 pr-2">
      <div className="block">{children}</div>
      <div className="mt-4">
        <Table key={JSON.stringify(seriesTableDataSource)}>
          <TableHead>
            <TableRow>
              <TableCell
                className="w-12"
                cellsNum={undefined}
              >
                <CheckBox
                  checked={isAllSeriesSelected}
                  onChange={() => handleAllSeriesSelection(!isAllSeriesSelected)}
                  label={''}
                />
              </TableCell>
              {Object.keys(seriesTableColumns).map(seriesProperties => (
                <TableCell
                  key={seriesProperties}
                  cellsNum={undefined}
                >
                  {seriesTableColumns[seriesProperties]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {seriesTableDataSource.map((series, index) => (
              <TableRow key={index}>
                <TableCell
                  className="w-12"
                  cellsNum={undefined}
                >
                  <CheckBox
                    checked={series.isSelected}
                    onChange={() => handleSeriesSelection(series.seriesInstanceUid)}
                    label={''}
                  />
                </TableCell>
                {Object.keys(seriesTableColumns).map(seriesProperties => {
                  const content = series[seriesProperties];
                  return (
                    <TableCell
                      key={seriesProperties}
                      className="truncate"
                      cellsNum={undefined}
                    >
                      {content}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
