import { StateSyncService } from '@ohif/core';

interface StateOptions {
  clearOnModeExit?: boolean;
  [key: string]: number | string | boolean;
}

type State = Record<string, number | string | boolean>;

export function initializeState(
  stateSyncService: StateSyncService,
  stateName: string,
  initialState: State = {}, // Make initialState optional with a default empty object
  options: StateOptions = {}
): void {
  const currentState = stateSyncService.getState()[stateName];

  if (
    !currentState ||
    (Array.isArray(currentState)
      ? currentState.length === 0
      : Object.keys(currentState).length === 0)
  ) {
    stateSyncService.register(stateName, {
      clearOnModeExit: options.clearOnModeExit ?? true,
      ...options,
    });

    // Only store the initialState if it's not an empty object
    if (Object.keys(initialState).length > 0) {
      stateSyncService.store({
        [stateName]: initialState,
      });
    }
  }
}
