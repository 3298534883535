import React from 'react';
import type { ThumbnailProps } from './thumbnail.types';
import { ThumbnailView } from './thumbnail-view';
import { useDrag } from 'react-dnd';

/**
 * Display a thumbnail for a display set.
 */
export const Thumbnail = ({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  dragData,
  isActive,
  onDoubleClick,
  canDrag,
  isHorizontal,
  isHydratedForDerivedDisplaySet,
  componentType = 'thumbnail',
  loadingProgress,
}: ThumbnailProps) => {
  const [, drag] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function () {
      if (typeof canDrag === 'function') {
        return canDrag(displaySetInstanceUID);
      }
      return Object.keys(dragData).length !== 0;
    },
  });

  return (
    <ThumbnailView
      displaySetInstanceUID={displaySetInstanceUID}
      className={className}
      imageSrc={imageSrc}
      imageAltText={imageAltText}
      description={description}
      isActive={isActive}
      onDoubleClick={onDoubleClick}
      isHorizontal={isHorizontal}
      isHydratedForDerivedDisplaySet={isHydratedForDerivedDisplaySet}
      ref={drag}
      componentType={componentType}
      loadingProgress={loadingProgress}
    />
  );
};

Thumbnail.displayName = 'Thumbnail';
