import { getLayoutTemplateModule, preRegistration, onModeEnter, getPanelModule } from './init';
import { id } from './id';
import { commandsModule } from './commandsModule';
import { toolNames } from './initPrenuvoTools';
import { getToolbarModule } from './getToolbarModule';
import { getHangingProtocolModule } from './hp';
import { Unsubscribe } from './types';

let unsubscriptions: Unsubscribe[] = [];
/**
 * You can remove any of the following modules if you don't need them.
 */
const prenuvoDefaultExtension = {
  id,
  preRegistration,
  getLayoutTemplateModule,
  getPanelModule,
  getUtilityModule({ servicesManager }) {
    return [
      {
        name: 'tools',
        exports: {
          toolNames,
        },
      },
    ];
  },
  getCommandsModule({ servicesManager, commandsManager, extensionManager, appConfig }) {
    return commandsModule({
      servicesManager,
      commandsManager,
      extensionManager,
      appConfig,
    });
  },
  getToolbarModule,
  getHangingProtocolModule,
  onModeEnter: ({ servicesManager, commandsManager }) => {
    onModeEnter(servicesManager, commandsManager, unsubscriptions);
  },
  onModeExit(): void {
    if (unsubscriptions.length) {
      unsubscriptions.forEach(unsubscribe => unsubscribe());
      unsubscriptions = [];
    }
  },
};

export { prenuvoDefaultExtension };
