import * as Halo from '@prenuvo/halo-icon';

export const CONTEXT_MENU_TOOL_MAP = {
  'Save Key Image': Halo.CloudSave,
  Annotations: Halo.Annotate,
  'Stack Image Sync': Halo.Stack,
  'References Lines': Halo.Measure,
  'Comparison Mode': Halo.Compare,
  'Reset View': Halo.ResetView,
  'Reset Cursor': Halo.Cursor,
  'More Tools': Halo.Ellipse,
  'Edit Label': Halo.Edit,
  'Delete Measurement': Halo.Delete,
  Series: Halo.Browser,
  Length: Halo.Length,
  Arrow: Halo.ArrowDown,
  Bidirectional: Halo.Bidirectional,
  Ellipse: Halo.Ellipse,
  Angle: Halo.Angle,
  RectangleROI: Halo.Rectangle,
  'Erase All': Halo.Stop,
  'tool-measure-tab': Halo.Measure,
  'chevron-menu': Halo.ChevronRight,
  Image: Halo.Image, // series images
  'Point Cross': Halo.Cross,
  Zoom: Halo.Zoom,
  Pan: Halo.Pause,
  Magnify: Halo.Magnify,
  Invert: Halo.Invert,
  'Rotate Right': Halo.ArrowFlipRight,
  'Rotate Left': Halo.ArrowFlipLeft,
  Crosshairs: Halo.Crosshair,
  Probe: Halo.Pause,
  'Multiplanar Reconstruction (MPR)': Halo.Mpr,
};
