import {
  metaData,
  getEnabledElementByIds,
  getEnabledElement,
  StackViewport,
  VolumeViewport,
  Types as CoreTypes,
} from '@cornerstonejs/core';
import {
  AnnotationTool,
  cursors,
  drawing,
  ToolGroupManager,
  utilities,
  annotation as csToolsAnnotation,
  Types as ToolTypes,
} from '@cornerstonejs/tools';
import { vec3 } from 'gl-matrix';
import {
  InitializeViewportFunction,
  InitFunction,
  PointInViewportFunction,
  GetElementAnnotationFunction,
  OnCameraModifiedFunction,
  SyncCameraViewportsFunction,
  DrawLineWithShadowFunction,
  DrawCrossLineFunction,
  DrawCircleWithShadowFunction,
  GetViewportAnnotationFunction,
} from '../../types';
import { arraySum, equalsCheck, getImageIds } from '../../utils';

const debug = false;

// function to add unit vector to a point. Used to create a shadow in the tool
function pointPlusOne(point: CoreTypes.Point2, deltaToAdd = 1): CoreTypes.Point2 {
  const delta = [...point];

  for (let i = 0; i < point.length; i++) {
    delta[i] = deltaToAdd;
  }
  try {
    const result = arraySum(point, delta);
    return result as CoreTypes.Point2;
  } catch {
    console.log('Arrays must have the same length');
  }
}

/**
 * This object doesn't use cornerstone default annotations, but instead create
 * an internal annotation list to hold the information of each viewport marker
 */

class PointCrossSyncTool extends AnnotationTool {
  renderAnnotation(
    enabledElement: CoreTypes.IEnabledElement,
    svgDrawingHelper: ToolTypes.SVGDrawingHelper
  ): null | boolean {
    throw new Error('Method not implemented.');
  }
  toolCenter: CoreTypes.Point3;
  initializeViewport: InitializeViewportFunction;
  byPassFrameOfReferenceUIDTest: boolean;
  isActive: boolean;
  disableCameraModified: boolean;
  inOperation: boolean;
  disableTool: () => void;
  onSetToolActive: () => void;
  onSetToolEnabled: () => void;
  onSetToolPassive: () => void;
  onSetToolDisabled: () => void;
  init: InitFunction;
  pointInViewport: PointInViewportFunction;
  getElementAnnotation: GetElementAnnotationFunction;
  onCameraModified: OnCameraModifiedFunction;
  syncCameraViewports: SyncCameraViewportsFunction;
  preMouseDownCallback: (evt: ToolTypes.EventTypes.InteractionEventType) => boolean;
  mouseDragCallback: (evt: ToolTypes.EventTypes.InteractionEventType) => boolean;
  drawLineWithShadow: DrawLineWithShadowFunction;
  drawCrossLine: DrawCrossLineFunction;
  drawCircleWithShadow: DrawCircleWithShadowFunction;
  getViewportAnnotation: GetViewportAnnotationFunction;
  mouseUpCallback: (evt: ToolTypes.EventTypes.InteractionEventType) => boolean;
  addNewAnnotation: (evt: ToolTypes.EventTypes.InteractionEventType) => ToolTypes.Annotation;
  cancel(element: HTMLDivElement) {}
  handleSelectedCallback(
    evt: ToolTypes.EventTypes.InteractionEventType,
    annotation: ToolTypes.Annotation,
    handle: ToolTypes.ToolHandle,
    interactionType: ToolTypes.InteractionTypes
  ): void {}
  toolSelectedCallback(
    evt: ToolTypes.EventTypes.InteractionEventType,
    annotation: ToolTypes.Annotation,
    interactionType: ToolTypes.InteractionTypes,
    canvasCoords?: CoreTypes.Point2
  ): void {}
  isPointNearTool(
    element: HTMLDivElement,
    annotation: ToolTypes.Annotation,
    canvasCoords: CoreTypes.Point2,
    proximity: number,
    interactionType: string
  ): boolean {
    throw new Error('Method not implemented.');
  }
  alwaysChangeSlices;
  constructor(
    toolProps = {},
    defaultToolProps = {
      inOperation: false, // if mouse is in action
      isActive: false, // if the tool is enabled or active
      byPassFrameOfReferenceUIDTest: false, // allows this tool bypass FrameOfReferenceUID test
      disableCameraModified: true, // tells the tool not to listen to OnCameraModified event
      supportedInteractionTypes: ['Mouse'],
      configuration: {
        crossHairType: 1,
        color: 'rgb(0, 200, 0)',
        shadowColor: 'rgb(0, 0, 0)',
        circlePercent: [0.015, 0.05],
        crossSize: 0.01,
        lineWidth: 3,
        lowerBound: 0.05,
        upperBond: 0.95,
        autoPan: {
          enabled: false,
          panSize: 10,
        },
      },
    }
  ) {
    super(toolProps, defaultToolProps);
    this.toolCenter = [0, 0, 0];
    this.alwaysChangeSlices = false;

    // prepare a viewport for the tool
    this.initializeViewport = ({ renderingEngineId, viewportId }) => {
      const enabledElement = getEnabledElementByIds(viewportId, renderingEngineId);
      const annotationManager = csToolsAnnotation.state.getAnnotationManager();
      const { FrameOfReferenceUID, viewport } = enabledElement;
      const { viewPlaneNormal } = viewport.getCamera();

      let annotations = annotationManager.getAnnotations(this.toolGroupId, this.getToolName());

      annotations = this.filterInteractableAnnotationsById(viewportId, annotations);

      if (annotations && annotations.length) {
        annotationManager.removeAnnotation(annotations[0].annotationUID);
      }

      const canvasPoint: CoreTypes.Point2 = [
        viewport.canvas.clientWidth / 2,
        viewport.canvas.clientHeight / 2,
      ];
      const worldPoint = viewport.canvasToWorld(canvasPoint);
      const annotation = {
        highlighted: false,
        metadata: {
          lastPoint: canvasPoint,
          lastWorldPoint: worldPoint,
          FrameOfReferenceUID,
          toolName: this.getToolName(),
        },
        data: {
          viewportId,
        },
      };
      annotationManager.addAnnotation(annotation, this.toolGroupId);
      return {
        normal: viewPlaneNormal,
        point: worldPoint,
        annotation,
      };
    };

    // prepare all the associated viewports and sets the tool center
    this.init = () => {
      this.isActive = true;
      this.byPassFrameOfReferenceUIDTest = true; // allows this tool bypass FrameOfReferenceUID test
      this.disableCameraModified = true; // tells the tool not to listen to OnCameraModified event

      const viewports = ToolGroupManager.getToolGroup(this.toolGroupId).viewportsInfo;

      if (!viewports.length) {
        return null;
      }

      const viewportCenterList = [];

      viewports.forEach(viewport => {
        const { point } = this.initializeViewport(viewport);
        viewportCenterList.push(point);
      });

      const { renderingEngineId, viewportId } = viewports[0];
      const enabledElement = getEnabledElementByIds(viewportId, renderingEngineId);
      const { viewport } = enabledElement;

      if (equalsCheck(this.toolCenter, [0, 0, 0])) {
        this.toolCenter = viewport.canvasToWorld([
          viewport.canvas.clientWidth / 2,
          viewport.canvas.clientHeight / 2,
        ]);
        this.moveWorld(viewport.element, this.toolCenter);
      }
    };

    /**
     * Verifies if a world point is inside viewport
     */
    this.pointInViewport = (viewport, jumpWorld) => {
      const canvasPoint = viewport.worldToCanvas(jumpWorld);
      return (
        canvasPoint[0] >= 0 &&
        canvasPoint[0] <= viewport.canvas.clientWidth &&
        canvasPoint[1] >= 0 &&
        canvasPoint[1] <= viewport.canvas.clientHeight
      );
    };

    // get the annotation information of an element. If not created and the tool is active, create
    this.getElementAnnotation = (viewportId, renderingEngineId) => {
      const annotationManager = csToolsAnnotation.state.getAnnotationManager();
      const annotations = annotationManager.getAnnotations(this.toolGroupId, this.getToolName());
      const filteredAnnotations = this.filterInteractableAnnotationsById(viewportId, annotations);

      if (filteredAnnotations.length === 0) {
        if (this.isActive) {
          const { annotation } = this.initializeViewport({
            renderingEngineId,
            viewportId,
          });

          return annotation;
        } else {
          return null;
        }
      }

      return filteredAnnotations[0];
    };

    // move the viewports based on the type of the viewport
    this.moveWorld = (element, jumpWorld) => {
      const toRender = [];
      const {
        viewport: selectedViewport,
        renderingEngine,
        FrameOfReferenceUID: reference,
      } = getEnabledElement(element);
      const viewports = ToolGroupManager.getToolGroup(this.toolGroupId).viewportsInfo;

      viewports.forEach(({ renderingEngineId, viewportId }) => {
        const { viewport, FrameOfReferenceUID } = getEnabledElementByIds(
          viewportId,
          renderingEngineId
        );

        if (reference === FrameOfReferenceUID || this.byPassFrameOfReferenceUIDTest) {
          if (viewport !== selectedViewport) {
            if (viewport instanceof StackViewport) {
              const closestState = getClosestImageId(viewport, jumpWorld);

              if (closestState) {
                const { index } = closestState;

                if (this.pointInViewport(viewport, jumpWorld) || this.alwaysChangeSlices) {
                  if (index !== viewport.getCurrentImageIdIndex()) {
                    viewport.scroll(index - viewport.getCurrentImageIdIndex(), true);
                  }
                }
              }
            } else if (viewport instanceof VolumeViewport) {
              utilities.viewport.jumpToWorld(viewport, jumpWorld as CoreTypes.Point3);
            }
          }
          // render all viewports with same frame of reference uid
          toRender.push(viewportId);
        }
      });

      utilities.triggerAnnotationRenderForViewportIds(renderingEngine, toRender);
      return toRender;
    };

    /**
     * This function sets the toolCenter, move the viewports and returns the
     * annotation of the element
     * @param {*} evt
     * @returns
     */
    this.moveWorldToCurrent = evt => {
      const eventDetail = evt.detail;
      const { element, currentPoints } = eventDetail;
      const enabledElement = getEnabledElement(element);
      const { viewportId, renderingEngineId } = enabledElement;
      const jumpWorld = currentPoints.world;

      this.toolCenter = jumpWorld;
      this.moveWorld(element, jumpWorld);

      const selectedAnnotation = this.getElementAnnotation(viewportId, renderingEngineId);
      return selectedAnnotation;
    };

    // function required in AnnotationTool descendants
    this.addNewAnnotation = evt => {
      this.inOperation = true;
      return this.moveWorldToCurrent(evt);
    };
    // function required in AnnotationTool descendants
    this.cancel = () => {
      console.log('Not implemented yet');
    };
    // function required in AnnotationTool descendants
    this.isPointNearTool = (element, annotation, canvasCoords, proximity) => {
      return false;
    };
    // function required in AnnotationTool descendants
    this.handleSelectedCallback = (evt, annotation, handle, interactionType = 'mouse') => {
      const eventDetail = evt.detail;
      const { element } = eventDetail;

      cursors.elementCursor.hideElementCursor(element);
      evt.preventDefault();
    };

    // function required in AnnotationTool descendents
    this.toolSelectedCallback = (evt, annotation, interactionType) => {
      const eventDetail = evt.detail;
      const { element } = eventDetail;

      cursors.elementCursor.hideElementCursor(element);
      evt.preventDefault();
    };

    // this function is fired when mouse wheel stack changes the image.
    // The mouse wheel stack tool is not changed by this tool and should be the default stack one
    this.onCameraModified = evt => {
      if (this.disableCameraModified) {
        return null;
      }
      if (!this.isActive) {
        return null;
      }

      if (this.inOperation) {
        return null;
      }

      const eventDetail = evt.detail;
      const { element } = eventDetail;
      const enabledElement = getEnabledElement(element);
      const { viewport, viewportId } = enabledElement;
      const selectedAnnotation = this.getViewportAnnotation(enabledElement);
      const { lastPoint, lastWorldPoint } = selectedAnnotation.metadata;
      const jumpWorld = viewport.canvasToWorld(lastPoint);

      if (JSON.stringify(jumpWorld) === JSON.stringify(lastWorldPoint)) {
        return null;
      }

      if (debug) {
        console.log('JumpWorld : ' + viewportId);
        console.log(jumpWorld);
        console.log('Last Point:');
        console.log(lastPoint);
      }

      this.toolCenter = jumpWorld;
      this.moveWorld(element, jumpWorld);
    };

    /**
     * Based on the current viewport, indicated in the event, apply proper zoom
     * and pan, so the area of the current viewport is totally displayed in
     * other sequences opened with large extent slices
     * @param {*} evt
     * @returns
     */
    this.syncCameraViewports = evt => {
      const eventDetail = evt.detail;
      const { element } = eventDetail;
      const { viewport: baseViewport } = getEnabledElement(element);

      const viewports = ToolGroupManager.getToolGroup(this.toolGroupId).viewportsInfo;
      viewports.forEach(({ renderingEngineId, viewportId }) => {
        const { viewport } = getEnabledElementByIds(viewportId, renderingEngineId);

        if (viewport !== baseViewport) {
          syncCameraViewport(baseViewport, viewport);
        }
      });
    };

    // capturing mouse events
    this.preMouseDownCallback = evt => {
      if (this.isActive) {
        this.syncCameraViewports(evt);
      }
      return false;
    };

    this.mouseUpCallback = evt => {
      if (this.isActive) {
        this.moveWorldToCurrent(evt);
        this.inOperation = false;
        const imageNeedsUpdate = true;
        return imageNeedsUpdate;
      } else {
        return false;
      }
    };

    this.mouseDragCallback = evt => {
      if (this.isActive) {
        let imageNeedsUpdate = false;

        if (this.inOperation) {
          this.moveWorldToCurrent(evt);
          imageNeedsUpdate = true;
        }

        return imageNeedsUpdate;
      } else {
        return false;
      }
    };

    // removes the annotations in case of tool disabling or turn into passive mode
    this.disableTool = () => {
      this.isActive = false;
      const viewports = ToolGroupManager.getToolGroup(this.toolGroupId).viewportsInfo;
      const annotationManager = csToolsAnnotation.state.getAnnotationManager();
      viewports.forEach(viewportInfo => {
        const annotations = annotationManager.getAnnotations(this.toolGroupId, this.getToolName());

        if (annotations && annotations.length) {
          annotationManager.removeAnnotation(annotations[0].annotationUID);
        }
      });
    };

    // captures the tool activation
    this.onSetToolActive = () => {
      if (debug) {
        console.log('activating pointCrossSyncTool...');
      }

      this.init();
    };

    // captures the tool enabling
    this.onSetToolEnabled = () => {
      if (debug) {
        console.log('enabling pointCrossSyncTool...');
      }

      this.init();
    };

    // captures the tool change to passive mode
    this.onSetToolPassive = () => {
      if (debug) {
        console.log('changing pointCrossSyncTool...');
      }

      this.disableTool();
    };

    // captures the tool disabling
    this.onSetToolDisabled = () => {
      if (debug) {
        console.log('disabling pointCrossSyncTool...');
      }

      this.disableTool();
    };

    // get annotations given a viewportId
    this.filterInteractableAnnotationsById = (viewportId, annotations) => {
      if (!annotations || !annotations.length || !Array.isArray(annotations)) {
        return [];
      }
      const viewportUIDSpecificPointCrossSync = annotations.filter(
        annotation => annotation.data.viewportId === viewportId
      );
      return viewportUIDSpecificPointCrossSync;
    };

    this.drawLineWithShadow = (svgDrawingHelper, prefixUID, annotationUID, start, end) => {
      let lineUID = prefixUID + 's';

      drawing.drawLine(
        svgDrawingHelper,
        annotationUID,
        lineUID,
        pointPlusOne(start),
        pointPlusOne(end),
        {
          color: this.configuration.shadowColor,
          lineWidth: this.configuration.lineWidth,
        }
      );

      lineUID = prefixUID;
      drawing.drawLine(svgDrawingHelper, annotationUID, lineUID, start, end, {
        color: this.configuration.color,
        lineWidth: this.configuration.lineWidth,
      });
    };

    this.drawCrossLine = (
      svgDrawingHelper,
      annotationUID,
      referencePoint,
      initialOffset,
      finalOffsets
    ) => {
      // up
      let point1: CoreTypes.Point2 = [referencePoint[0], referencePoint[1] - initialOffset];
      let point2: CoreTypes.Point2 = [referencePoint[0], referencePoint[1] - finalOffsets[0]];
      let lineUID = '1';
      this.drawLineWithShadow(svgDrawingHelper, lineUID, annotationUID, point1, point2);

      // down
      point1 = [referencePoint[0], referencePoint[1] + initialOffset];
      point2 = [referencePoint[0], referencePoint[1] + finalOffsets[1]];
      lineUID = '2';
      this.drawLineWithShadow(svgDrawingHelper, lineUID, annotationUID, point1, point2);

      // left
      point1 = [referencePoint[0] - initialOffset, referencePoint[1]];
      point2 = [referencePoint[0] - finalOffsets[2], referencePoint[1]];
      lineUID = '3';
      this.drawLineWithShadow(svgDrawingHelper, lineUID, annotationUID, point1, point2);

      // right
      point1 = [referencePoint[0] + initialOffset, referencePoint[1]];
      point2 = [referencePoint[0] + finalOffsets[3], referencePoint[1]];
      lineUID = '4';
      this.drawLineWithShadow(svgDrawingHelper, lineUID, annotationUID, point1, point2);
    };

    this.drawCircleWithShadow = (
      svgDrawingHelper,
      annotationUID,
      prefixUID,
      referencePoint,
      radius
    ) => {
      let circleUID = prefixUID + 's';
      drawing.drawCircle(
        svgDrawingHelper,
        annotationUID,
        circleUID,
        pointPlusOne(referencePoint),
        radius,
        { color: this.configuration.shadowColor, fill: 'transparent' }
      );
      circleUID = prefixUID;
      drawing.drawCircle(svgDrawingHelper, annotationUID, circleUID, referencePoint, radius, {
        color: this.configuration.color,
        fill: 'transparent',
      });
    };

    this.getViewportAnnotation = enabledElement => {
      const { viewportId, renderingEngineId } = enabledElement;
      const annotationManager = csToolsAnnotation.state.getAnnotationManager();
      let annotations = annotationManager.getAnnotations(this.toolGroupId, this.getToolName());

      if (!annotations) {
        this.initializeViewport({
          renderingEngineId,
          viewportId,
        });
        annotations = annotationManager.getAnnotations(this.toolGroupId, this.getToolName());
      }
      let filteredToolAnnotations = this.filterInteractableAnnotationsById(viewportId, annotations);

      let viewportAnnotation = filteredToolAnnotations[0];

      // the tool has aNNotations, but not for that viewport
      if (!viewportAnnotation) {
        // create annotation for viewport
        this.initializeViewport({
          renderingEngineId,
          viewportId,
        });

        // get the update annotation List and specific viewport annotation
        annotations = annotationManager.getAnnotations(this.toolGroupId, this.getToolName());
        filteredToolAnnotations = this.filterInteractableAnnotationsById(viewportId, annotations);

        viewportAnnotation = filteredToolAnnotations[0];
      }

      return viewportAnnotation;
    };

    // draws the point cross
    this.renderAnnotation = (enabledElement, svgDrawingHelper) => {
      if (!this.isActive) {
        return null;
      }

      let renderStatus = false;
      const { viewport, viewportId } = enabledElement;
      const closestPoint = getClosestImageId(viewport, this.toolCenter);
      if (closestPoint) {
        if (!closestPoint.found) {
          return null;
        }
      }

      const viewportAnnotation = this.getViewportAnnotation(enabledElement);
      if (!viewportAnnotation || !viewportAnnotation.data) {
        return renderStatus;
      }

      const annotationUID = viewportAnnotation.annotationUID;
      const { clientWidth, clientHeight } = viewport.canvas;
      const canvasDiagonalLength = Math.sqrt(
        clientWidth * clientWidth + clientHeight * clientHeight
      );
      const centerPoint = viewport.worldToCanvas(this.toolCenter);

      viewportAnnotation.metadata.lastPoint = centerPoint;
      viewportAnnotation.metadata.lastWorldPoint = this.toolCenter;

      if (debug) {
        console.log('Last Point Saved: ' + viewportId);
        console.log(viewportAnnotation.metadata.lastPoint);
      }

      if (this.configuration.crossHairType === 1) {
        const circleRadius = canvasDiagonalLength * this.configuration.circlePercent[0];

        this.drawCircleWithShadow(svgDrawingHelper, annotationUID, '0', centerPoint, circleRadius);

        this.drawCrossLine(svgDrawingHelper, annotationUID, centerPoint, circleRadius, [
          2 * circleRadius,
          2 * circleRadius,
          2 * circleRadius,
          2 * circleRadius,
        ]);

        renderStatus = true;
      } else if (this.configuration.crossHairType === 2) {
        const circleRadius = canvasDiagonalLength * this.configuration.circlePercent[1];

        this.drawCrossLine(svgDrawingHelper, annotationUID, centerPoint, circleRadius, [
          centerPoint[1] - clientHeight * this.configuration.lowerBound,
          clientHeight * this.configuration.upperBond - centerPoint[1],
          centerPoint[0] - clientWidth * this.configuration.lowerBound,
          clientWidth * this.configuration.upperBond - centerPoint[0],
        ]);

        renderStatus = true;
      } else if (this.configuration.crossHairType === 3) {
        // up
        const lineSize = canvasDiagonalLength * this.configuration.crossSize;
        this.drawCrossLine(svgDrawingHelper, annotationUID, centerPoint, 0, [
          lineSize,
          lineSize,
          lineSize,
          lineSize,
        ]);

        renderStatus = true;
      }

      return renderStatus;
    };
  }
  moveWorld(element: HTMLDivElement, toolCenter: CoreTypes.Point3): string[] {
    throw new Error('Method not implemented.');
  }
  moveWorldToCurrent(evt: ToolTypes.EventTypes.InteractionEventType): ToolTypes.Annotation {
    throw new Error('Method not implemented.');
  }
  filterInteractableAnnotationsById(
    viewportId: string,
    annotations: ToolTypes.Annotations | ToolTypes.GroupSpecificAnnotations
  ): ToolTypes.Annotations | ToolTypes.GroupSpecificAnnotations {
    throw new Error('Method not implemented.');
  }

  // function needed by the AnnotationTool object
  getHandleNearImagePoint(element, annotation, canvasCoords, proximity) {
    return null;
  }
}

/**
 * Checks if a viewport is displaying a large extent slice sequence, based on
 * the ratio between the number of lines and columns of the slice
 * @param {*} viewport
 * @returns
 */
function isViewportDisplayingLargeExtent(viewport) {
  const dimensions = viewport.getImageData().imageData.getDimensions();
  return dimensions[1] / dimensions[0] > 2;
}
/**
 * Gets the orientation of a slice based on its normal vector
 * Returns :
 * 0, if sagital
 * 1, if coronal
 * 2, if axial
 * @param normalVector
 * @returns
 */
function getNormalVectorOrientation(normalVector) {
  let largestAxis = 0;
  for (let i = 1; i < normalVector.length; i++) {
    if (Math.abs(normalVector[i]) > Math.abs(normalVector[largestAxis])) {
      largestAxis = i;
    }
  }
  return largestAxis;
}

/**
 * This function will control the zoom range to not pass irreal values as the
 * ones obtained from sync zoom a neck, pelvis or brain
 * @param {*} calculatedZoom
 * @returns
 */
function normalizeZoom(calculatedZoom, ceilZoom = 10) {
  if (calculatedZoom > ceilZoom) {
    calculatedZoom = ceilZoom;
  }
  return calculatedZoom;
}

/**
 * Checks if syncing is based on slice or not, based on the slice extent
 * proportion of the viewports
 * @param {*} base
 * @param {*} target
 * @returns
 */
function shouldSyncBaseOnSlices(base, target) {
  return !isViewportDisplayingLargeExtent(base) && isViewportDisplayingLargeExtent(target);
}

/**
 * Resets the camera of a viewport based on initial and final points
 * @param {*} targetViewport
 * @param {*} initialPosition
 * @param {*} finalPosition
 */
function resetViewportCamera(targetViewport, initialPosition, finalPosition, slack = 30) {
  const changingOrientation = 1;

  const imageData = targetViewport.getImageData().imageData;
  const dimensions = imageData.getDimensions();
  // Accepting only pairs of baseViewport and toBeSyncedViewport if the
  // changingOrientation is in y axis
  const indexInitialPosition = imageData.worldToIndex(initialPosition);
  const indexFinalPosition = imageData.worldToIndex(finalPosition);
  let calculatedZoom =
    dimensions[changingOrientation] /
    Math.abs(indexInitialPosition[changingOrientation] - indexFinalPosition[changingOrientation]);

  calculatedZoom = normalizeZoom(calculatedZoom);

  let reference;
  if (indexInitialPosition[changingOrientation] < indexFinalPosition[changingOrientation]) {
    reference = initialPosition;
  } else {
    reference = finalPosition;
  }
  targetViewport.resetCamera();
  targetViewport.setZoom(calculatedZoom);
  const newPan = targetViewport.getPan();
  newPan[changingOrientation] +=
    slack / 2 - targetViewport.worldToCanvas(reference)[changingOrientation];
  targetViewport.setPan(newPan);
  targetViewport.render();
}

/**
 * This function sync the camera viewport of a large extent slice sequence based
 * on a viewport of a non large extent slice sequence
 * @param {*} baseViewport
 * @param {*} targetViewport
 */
function syncCameraViewportBySlices(baseViewport, targetViewport) {
  // get the imageIds
  const imageIds = getImageIds(baseViewport);
  const { imagePositionPatient: initialPosition } = metaData.get('imagePlaneModule', imageIds[0]);
  let { imagePositionPatient: finalPosition } = metaData.get(
    'imagePlaneModule',
    imageIds[imageIds.length - 1]
  );

  const baseNormal = baseViewport.getCamera().viewPlaneNormal;
  const baseOrientation = getNormalVectorOrientation(baseNormal);

  // if the base viewport doesn't display axial slices, we convert the positions
  // to axial view. The initial point remains the same, but to calculate the
  // final point we shift the world coordinates to index coordinates, using the
  // baseViewport reference, and add to this result the number of lines in y axis
  // and converts the result back to world coordinates. We do not need to reshape
  // the data as we just need the initial and final points
  if (baseOrientation !== 2) {
    const baseImageData = baseViewport.getImageData().imageData;
    const baseDimensions = baseImageData.getDimensions();
    const tempIndexFinalPosition = baseImageData.worldToIndex(initialPosition);
    tempIndexFinalPosition[1] = tempIndexFinalPosition[1] + baseDimensions[1];
    finalPosition = baseImageData.indexToWorld(tempIndexFinalPosition);
  }
  resetViewportCamera(targetViewport, initialPosition, finalPosition);
}

/**
 * This function sync the camera viewport of a large extent slice sequence based
 * on a viewport of a large extent slice sequence
 * @param {*} baseViewport
 * @param {*} targetViewport
 */
function syncCameraViewportByCurrentView(baseViewport, targetViewport) {
  resetViewportCamera(
    targetViewport,
    baseViewport.canvasToWorld([0, 0]),
    baseViewport.canvasToWorld([0, baseViewport.canvas.clientHeight])
  );
}

/**
 * This function will calculate the positions of the initial and final slices
 * of the baseViewport in the toBeSyncedViewport and set the camera accordingly.
 * i.e. setting zoom and pan
 * @param base
 * @param target
 * @param slack extra space to add to the limits of the image
 */
function syncCameraViewport(baseViewport, targetViewport) {
  // if target viewport is not large extent view exists
  if (!isViewportDisplayingLargeExtent(targetViewport)) {
    return;
  }

  if (shouldSyncBaseOnSlices(baseViewport, targetViewport)) {
    syncCameraViewportBySlices(baseViewport, targetViewport);
  } else {
    syncCameraViewportByCurrentView(baseViewport, targetViewport);
  }
}

// the following functions are used by the point cross tool to find which image corresponds
// to an specific world point
function getSpacingInNormalDirection(direction, spacing, viewPlaneNormal) {
  // Calculate size of spacing vector in normal direction
  const iVector = direction.slice(0, 3);
  const jVector = direction.slice(3, 6);
  const kVector = direction.slice(6, 9);

  const dotProducts = [
    vec3.dot(iVector, viewPlaneNormal),
    vec3.dot(jVector, viewPlaneNormal),
    vec3.dot(kVector, viewPlaneNormal),
  ];

  const projectedSpacing = vec3.create();

  vec3.set(
    projectedSpacing,
    dotProducts[0] * spacing[0],
    dotProducts[1] * spacing[1],
    dotProducts[2] * spacing[2]
  );

  const spacingInNormalDirection = vec3.length(projectedSpacing);

  return spacingInNormalDirection;
}

function imageIdDistance(imageId, viewPlaneNormal, worldPos) {
  if (!imageId) {
    return Number.POSITIVE_INFINITY; // returning a very large distance
  }
  const { imagePositionPatient } = metaData.get('imagePlaneModule', imageId);
  const dir = vec3.create();

  vec3.sub(dir, worldPos, imagePositionPatient);

  return Math.abs(vec3.dot(dir, viewPlaneNormal));
}

function checkImageId(
  imageId,
  imageIdForTool,
  viewPlaneNormal,
  worldPos,
  imageIdDistance,
  realIndex,
  halfSpacingInNormalDirection
) {
  const distance = imageIdDistance(imageId, viewPlaneNormal, worldPos);
  let willBreak = false;
  let found = false;

  if (distance < imageIdForTool.distance) {
    imageIdForTool = { distance, index: realIndex };
  } else {
    willBreak = true;
  }

  if (distance < halfSpacingInNormalDirection) {
    found = true;
    willBreak = true;
  }
  return { imageIdForTool, willBreak, found };
}

function getClosestImageId(viewport, worldPos) {
  if (!viewport) {
    return;
  }

  const imageData = viewport.getImageData();
  if (!imageData) {
    return;
  }
  const { direction, spacing } = imageData;
  const imageIds = getImageIds(viewport);
  const actualIndex = viewport.getCurrentImageIdIndex();
  const { viewPlaneNormal } = viewport.getCamera();

  if (!imageIds || !imageIds.length) {
    return;
  }

  const kVector = direction.slice(6, 9);
  const dotProducts = vec3.dot(kVector, viewPlaneNormal);

  if (Math.abs(dotProducts) < 0.99) {
    return;
  }

  const spacingInNormalDirection = getSpacingInNormalDirection(direction, spacing, viewPlaneNormal);
  const halfSpacingInNormalDirection = spacingInNormalDirection / 2;
  let imageIdForTool = {
    distance: imageIdDistance(imageIds[actualIndex], viewPlaneNormal, worldPos),
    index: actualIndex,
  };
  let found = imageIdForTool.distance < halfSpacingInNormalDirection;

  if (!found) {
    const higherImageIds = imageIds.slice(actualIndex + 1);

    for (let i = 0; i < higherImageIds.length; i++) {
      const imageId = higherImageIds[i];
      const response = checkImageId(
        imageId,
        imageIdForTool,
        viewPlaneNormal,
        worldPos,
        imageIdDistance,
        i + actualIndex + 1,
        halfSpacingInNormalDirection
      );
      imageIdForTool = response.imageIdForTool;
      found = response.found;
      if (response.willBreak) {
        break;
      }
    }
  }

  if (!found) {
    const lowerImageIds = imageIds.slice(0, actualIndex);

    for (let i = lowerImageIds.length - 1; i >= 0; i--) {
      const imageId = lowerImageIds[i];
      const response = checkImageId(
        imageId,
        imageIdForTool,
        viewPlaneNormal,
        worldPos,
        imageIdDistance,
        i,
        halfSpacingInNormalDirection
      );
      imageIdForTool = response.imageIdForTool;
      found = response.found;
      if (response.willBreak) {
        break;
      }
    }
  }

  return {
    ...imageIdForTool,
    found: imageIdForTool.distance < 3 * spacingInNormalDirection,
  };
}

PointCrossSyncTool.toolName = 'PointCross';
export { PointCrossSyncTool };
