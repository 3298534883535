import { PatientType, StudyModelType } from '@prenuvo/ui';
import { StudyInfo } from '../../types';
import moment from 'moment';

function createStudyItemsInfo(studies: StudyInfo[]) {
  return studies?.map((study, index) => {
    // The primary study (index 0) gets no prefix, others get 'P1 ', 'P2 ', etc.
    const prefix = index === 0 ? '' : `P${index} `;
    return createStudyItemInfo(study, prefix);
  });
}

function createStudyItemInfo(study: StudyInfo, prefix: string) {
  // format the study tab date to match the viewport pane title bar date format
  // ie convert 18-Apr-2019 to Apr 18,2019 so that they match
  const formattedDate = moment(study.date, 'DD-MMM-YYYY').format('MMM D, YYYY');

  return {
    label: `${prefix}${formattedDate}`,
    id: study.studyInstanceUid,
  };
}

function createPatientDetails(study: StudyInfo) {
  const [firstName, lastName]: string[] = (study?.patientName || '').split(', ');
  const patientDetails: PatientType = {
    id: study?.mrn,
    firstName: firstName || '',
    lastName: lastName || '',
  };
  return patientDetails;
}

export function createHeaderData(patientStudies: StudyInfo[]): {
  studyModels: StudyModelType[];
  patient: PatientType;
} {
  return {
    studyModels: [...createStudyItemsInfo(patientStudies)],
    patient: createPatientDetails(patientStudies?.[0]),
  };
}
