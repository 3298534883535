import { runProtocolForComparison } from '../commandsModule';
import { getViewportHeader } from '../components/Common/ViewportHeader';
import { getViewportPane } from '../components/Common/ViewportPane';
import { PrenuvoServices } from '../types';
import {
  DEFAULT_TOOLS_STATES,
  PrenuvoHangingProtocolIds,
  getAddOldMeasurementsToFrameOfReference,
  getRemoveOldMeasurementsFromAnnotations,
  initializeState,
} from '../utils';
import { initImageSliceSync } from './initImageSliceSync';

export function onModeEnter(servicesManager, commandsManager, unsubscriptions) {
  const {
    displaySetService,
    hangingProtocolService,
    viewportGridService,
    patientStudyListService,
    customizationService,
    stateSyncService,
  } = servicesManager.services as PrenuvoServices;

  customizationService.addModeCustomizations([
    getViewportHeader(),
    getAddOldMeasurementsToFrameOfReference(),
    getRemoveOldMeasurementsFromAnnotations(),
    getViewportPane(),
  ]);

  // set listener and load thumbnails when series is ready
  patientStudyListService.saveThumbnailSrcForSeries();

  // subscribe for initial hanging protocol set to know the completion of first study download and
  // start fetching other studies of the same patient
  const protocolChangeListener = hangingProtocolService.subscribe(
    hangingProtocolService.EVENTS.PROTOCOL_CHANGED,
    () => {
      protocolChangeListener.unsubscribe();
    }
  );

  // Subscribe to changes in viewport data when a series is dragged or double-clicked from a thumbnail
  // while in comparison mode
  const { unsubscribe } = viewportGridService.subscribe(
    viewportGridService.EVENTS.GRID_STATE_CHANGED,
    ({ props }) => {
      const { protocol } = hangingProtocolService.getActiveProtocol();
      if (protocol.id === PrenuvoHangingProtocolIds.COMPARISON_MODE) {
        const primaryStudyDisplaysetToCompare = displaySetService.getDisplaySetByUID(
          props[0].displaySetInstanceUIDs[0]
        );
        displaySetService.primaryStudyDisplaysetToCompare = primaryStudyDisplaysetToCompare;
        runProtocolForComparison(
          patientStudyListService,
          hangingProtocolService,
          displaySetService
        );
      }
      delete displaySetService.primaryStudyDisplaysetToCompare;
    }
  );

  initImageSliceSync({ commandsManager, servicesManager, unsubscriptions });

  initializeState(stateSyncService, 'MeasurementSaveState', {
    doSaveMeasurement: false,
  });

  initializeState(stateSyncService, 'toolsToggleState', DEFAULT_TOOLS_STATES);

  initializeState(stateSyncService, 'spineLabelStates', {
    isFirstVertebraSelected: false,
  });

  initializeState(stateSyncService, 'viewportOverlayColor');

  unsubscriptions.push(unsubscribe);
}
