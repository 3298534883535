import React from 'react';

// EmptyViewport component is used to show a blank viewport when the displayset is empty.
export function EmptyViewport({ handleRightClick, handleLeftClick }) {
  return (
    <div
      onContextMenu={handleRightClick}
      onClick={handleLeftClick}
      className="h-full w-full bg-black"
    ></div>
  );
}
