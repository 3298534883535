import { ServicesManager, PubSubService, Types, DicomMetadataStore, HPMatcher } from '@ohif/core';
import { MatchOptions, ProtocolJar } from './types/protocol-jar';
import { withScore } from '../../utils/studyDetails';
import { loadUserDefinedProtocolJar } from './protocoljarUtils';
import { convertHangingProtocols, PrenuvoServices } from '@prenuvo/extension-default';
import { HangingProtocolListItemType } from '@prenuvo/ui';
import {
  notifyProtocolJarNotFound,
  OhifHangingProtocolIds,
  PrenuvoHangingProtocolIds,
} from '../../utils';
import { DisplaySet } from '../../types';
import { getProtocolJars } from '../../apis';

const EVENTS = {
  // This event is used to notify if the protocoljar for the selected study is matched with the contraints
  // and the scored and the filtered list of protocol jars are generated.
  PROTOCOLJAR_MATCHING_COMPLETE: 'event::protocolJarService:ProtocolJarMatchingCompleted',
  // notify hanging protocol tab switching
  PROTOCOL_TAB_CHANGED: 'event::protocolJarService:ProtocolTabSwitched',
};

export class ProtocolJarService extends PubSubService {
  public static REGISTRATION = {
    name: 'protocolJarService',
    altName: 'ProtocolJarService',
    create: ({ servicesManager }) => {
      return new ProtocolJarService(servicesManager);
    },
  };

  // service manager
  private readonly services: PrenuvoServices;

  // all the available protocol jars
  private _protocolJars: ProtocolJar[] = [];
  stagesToSkip: string[] = [];

  public get protocolJars(): ProtocolJar[] {
    return this._protocolJars;
  }

  private set protocolJars(value: ProtocolJar[]) {
    this._protocolJars = value;
  }

  // after executing of the hp matcher the list of protocoljars that
  // matches constraints with the study
  matchingProtocolJars: ProtocolJar[] = null;

  // selectedProtocolJarIndex holds the index of the current selected protocoljar
  // by default 0th index of matchingProtocolJars will be the selected one.
  private _selectedProtocolJarIndex = 0;

  public get selectedProtocolJarIndex() {
    return this._selectedProtocolJarIndex;
  }

  private set selectedProtocolJarIndex(value) {
    this._selectedProtocolJarIndex = value;
  }

  get selectedHPs() {
    return this._selectedHPs || [];
  }
  // selectedHPs hold the list of expanded values of hanging protocols mentioned
  // in the selected protocoljar
  private _selectedHPs: HangingProtocolListItemType[];

  constructor(servicesManager: ServicesManager) {
    super(EVENTS);
    this.services = servicesManager.services as PrenuvoServices;
  }

  async loadProtocolJars() {
    // TODO: fetch protocol jars from the server
    // If failed, fallback to default defined in `protocol-jars.ion

    // TODO: Do we still need this?  Perhaps replace this with ProtocolService?
    const getProtocolJarPromises = [getProtocolJars(), loadUserDefinedProtocolJar()];
    const [defaultPJ, userDefinedPJs]: ProtocolJar[][] = await Promise.all(getProtocolJarPromises);

    // TODO: Add ProtocolJar validator

    this.protocolJars = [...defaultPJ, ...userDefinedPJs];
  }

  setSelectedProtocolJarIndex(index: number) {
    this.selectedProtocolJarIndex = index;
    this.setSelectedHPs();
    this.addProtocolsToHpService();
  }

  setSelectedHPs() {
    if (this.matchingProtocolJars.length > 0) {
      this.validateStages();
      this._selectedHPs = this.findSelectedHPs();
    } else {
      this._selectedHPs = []; // to avoid lauching with previous study's matched protocoljar's HPs
    }
  }

  private findSelectedHPs() {
    const { prenuvoHangingProtocols } = this.services.hangingProtocolService;

    const hpItems: HangingProtocolListItemType[] = convertHangingProtocols(
      prenuvoHangingProtocols,
      this.stagesToSkip
    );
    const selectedProtocolJar: ProtocolJar | undefined = this.matchingProtocolJars.at(
      this.selectedProtocolJarIndex
    );
    return selectedProtocolJar?.protocols.flatMap(hpItem =>
      hpItems.filter(protocol => hpItem.name === protocol.protocolId)
    );
  }

  private validateStages() {
    const { hangingProtocolService, displaySetService, patientStudyListService } = this.services;
    const { prenuvoHangingProtocols } = hangingProtocolService;
    const protocolsWithStageActivation = prenuvoHangingProtocols?.filter(hp =>
      hp?.protocol?.stages.some(stage => 'stageActivation' in stage)
    );
    const stagesToSkip: string[] = [];
    protocolsWithStageActivation.forEach(hp => {
      hp?.protocol?.stages?.forEach(stage => {
        if (stage?.stageActivation?.disabled) {
          stagesToSkip.push(stage.name);
        } else {
          const { displaySetSelectorsMatched: stageDSSelectors } =
            stage?.stageActivation?.passive ?? stage?.stageActivation?.enabled;
          const stageSeriesMatchingRules = stageDSSelectors?.map(
            stageSelector => hp?.protocol?.displaySetSelectors[stageSelector]?.seriesMatchingRules
          );
          const displaySet: DisplaySet[] = displaySetService.getDisplaySetsBy(
            ds => ds.StudyInstanceUID === patientStudyListService.activeStudyUID
          );
          const firstDS = displaySet?.[0];
          const firstDSImage = firstDS?.images?.[0];
          if (firstDS && firstDSImage) {
            const options = {
              instance: firstDSImage,
              studies: [null],
              displaySets: [null],
            };
            const match = HPMatcher.match(firstDS, stageSeriesMatchingRules.flat(), {}, options);
            if (match.score === 0) {
              stagesToSkip.push(stage.name);
            }
          }
        }
      });
    });
    this.stagesToSkip = stagesToSkip;
  }

  private setActiveProtocol(protocolId: string) {
    const { hangingProtocolService } = this.services;
    hangingProtocolService.setProtocol(protocolId);
    hangingProtocolService.setActiveProtocolIds(protocolId);
  }

  addProtocolsToHpService() {
    const { hangingProtocolService } = this.services;
    const selectedProtocolJar: ProtocolJar | undefined = this.matchingProtocolJars?.at(
      this.selectedProtocolJarIndex
    );
    const { protocol } = hangingProtocolService.getActiveProtocol();

    const hpFullObjs = hangingProtocolService?.prenuvoHangingProtocols || [];
    hangingProtocolService.protocols.forEach(
      (value: Types.HangingProtocol.Protocol, key: string) => {
        // this is with the assumption that all the prenuvo hanging protocols will start with '@prenuvo'
        // all those should be removed from the hangingprotocolservice.protocols
        if (key.startsWith('@prenuvo')) {
          hangingProtocolService.protocols.delete(key);
        }
      }
    );
    if (selectedProtocolJar?.protocols) {
      selectedProtocolJar.protocols.forEach((hpItem, index) => {
        const hpFullObjItem = hpFullObjs.find(protocol => protocol.name === hpItem.name);
        if (hpFullObjItem) {
          const clonedFullObj = JSON.parse(JSON.stringify(hpFullObjItem));
          hangingProtocolService.addProtocol(clonedFullObj.name, clonedFullObj.protocol);
          if (index === 0 && protocol?.id !== PrenuvoHangingProtocolIds.COMPARISON_MODE) {
            this.setActiveProtocol(hpFullObjItem.protocol.id);
          }
        }
      });
    } else if (protocol?.id !== PrenuvoHangingProtocolIds.COMPARISON_MODE) {
      this.setActiveProtocol(OhifHangingProtocolIds.DEFAULT);
    }
    if (this.stagesToSkip.length > 0) {
      this.removeInvalidStagesFromService();
    }
    this._broadcastEvent(this.EVENTS.PROTOCOLJAR_MATCHING_COMPLETE, {});
  }

  public switchProtocolTab(protocol: HangingProtocolListItemType) {
    this._broadcastEvent(this.EVENTS.PROTOCOL_TAB_CHANGED, protocol);
  }

  private removeInvalidStagesFromService() {
    const { hangingProtocolService } = this.services;
    Array.from(hangingProtocolService.protocols.values()).forEach(
      (hp: Types.HangingProtocol.Protocol) => {
        for (let i = hp?.stages?.length - 1; i >= 0; i--) {
          if (this.stagesToSkip.includes(hp.stages[i].name)) {
            hp.stages.splice(i, 1);
          }
        }
      }
    );
  }

  async matchProtocolJarWithStudy(displaySets: Types.DisplaySet[]) {
    const { uiNotificationService, patientStudyListService } = this.services;
    const studyDetails = DicomMetadataStore.getStudy(patientStudyListService.activeStudyUID);

    const option: MatchOptions = {
      studies: [studyDetails],
      displaySets: displaySets,
    };

    const matchingProtocolJars = this.protocolJars
      .map(jar => (jar.enabled ? withScore(jar, studyDetails, option) : null))
      .filter(Boolean)
      .sort((a, b) => b.result.score - a.result.score)
      .map(protocolJarWithScoreItem => protocolJarWithScoreItem.protocolJar);
    console.log('matching Protocol Jars=', this.matchingProtocolJars);
    this.matchingProtocolJars = matchingProtocolJars;
    this.setSelectedHPs();

    if (this.matchingProtocolJars.length === 0) {
      notifyProtocolJarNotFound(uiNotificationService);
    } else {
      this.selectedProtocolJarIndex = 0;
    }
  }

  onModeExit() {
    this._selectedHPs = [];
    this.matchingProtocolJars = [];
    this.selectedProtocolJarIndex = 0;
  }
}
