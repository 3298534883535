import React from 'react';

import { Stack } from '@prenuvo/halo-web';
import { PatientInfoProps } from './header.types';

export const PatientInfo = ({ patient: { lastName, firstName, id } }: PatientInfoProps) => {
  return (
    <Stack className="text-sm text-neutral-600">
      <Stack className="flex">
        <p data-cy="patient-last-name">{lastName},</p>
        <p
          data-cy="patient-first-name"
          className="ml-1"
        >
          {firstName}
        </p>
      </Stack>
      <Stack className="mx-2">
        <p data-cy="patient-id">{id}</p>
      </Stack>
    </Stack>
  );
};

PatientInfo.displayName = 'PatientInfo';
