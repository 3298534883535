import React, { FC, MouseEvent, useState } from 'react';
import classnames from 'classnames';
import { useDrop, useDrag } from 'react-dnd';
import { ViewportPaneProps } from './viewportPane.type';
import { useDragScrollbar } from '../../hooks/useDragScrollbar';

export const ViewportPane: FC<ViewportPaneProps> = ({
  children,
  className,
  customStyle,
  isActive,
  onDrop,
  onDoubleClick,
  onInteraction = () => {},
  acceptDropsFor,
  displaySets,
  studyColor,
  viewportId,
}) => {
  const [dropElement, setDropElement] = useState<HTMLDivElement | null>(null);
  const displaySet = displaySets[0] || {};

  const { isDraggingScrollbar, handleMouseDown } = useDragScrollbar();

  const [{ isHovered: isDropHovered }, drop] = useDrop({
    accept: [acceptDropsFor, 'displaysetForViewportSwap'],
    drop: (droppedItem: string, monitor) => {
      const canDrop = monitor.canDrop();
      const isOver = monitor.isOver();
      if (canDrop && isOver && onDrop) {
        onInteractionHandler();
        onDrop(droppedItem, monitor.getItemType());
      }
    },
    collect: monitor => ({
      isHighlighted: monitor.canDrop(),
      isHovered: monitor.isOver(),
    }),
  });

  const dragData = {
    type: 'displaysetForViewportSwap',
    displaySetInstanceUID: displaySet.displaySetInstanceUID,
    currentViewportId: viewportId,
  };

  const [, drag] = useDrag({
    type: 'displaysetForViewportSwap',
    item: { ...dragData },
    canDrag: () => !isDraggingScrollbar, // Disable dragging when interacting with scrollbar
  });

  const focus = () => {
    if (dropElement) {
      dropElement.focus();
    }
  };

  const onInteractionHandler = (event?: MouseEvent<HTMLDivElement>) => {
    focus();
    onInteraction(event);
  };

  const refHandler = (element: HTMLDivElement) => {
    drop(element);
    setDropElement(element);
  };

  return (
    <div
      ref={refHandler}
      onMouseDown={handleMouseDown}
      onDoubleClick={onDoubleClick}
      onClick={onInteractionHandler}
      onScroll={onInteractionHandler}
      onWheel={onInteractionHandler}
      className={classnames(
        'group h-full w-full overflow-hidden rounded-md transition duration-300',
        {
          'border-2': isActive,
          'border-1 opacity-5': isDropHovered,
          'border-1 border border-dotted': !isActive,
        },
        className
      )}
      style={{
        ...customStyle,
        borderColor: studyColor,
      }}
    >
      <div
        ref={drag}
        className={classnames('h-full w-full overflow-hidden rounded-md', className)}
      >
        {children}
      </div>
    </div>
  );
};
