import { HotkeysManager } from '@ohif/core';
import { HangingProtocolListItemType } from '@prenuvo/ui';
import Hotkey from 'platform/core/src/classes/Hotkey';
const MAX_HP_HOTKEY_NUMBERS = 9;
export const setHotKeysForHPs = (
  hotkeysManager: HotkeysManager,
  hps: HangingProtocolListItemType[]
) => {
  const hotkeyDefinitions: Hotkey[] = [];
  hps.forEach((hp, index) => {
    if (index <= MAX_HP_HOTKEY_NUMBERS) {
      hotkeyDefinitions.push({
        commandName: 'selectHPTab',
        label: hp.label,
        keys: [index + 1 + ''],
        commandOptions: hp,
      });
    }
  });
  // setHotkeys function was reseting the localStorage which clears the default hot keys and sets only the hanging protocol hotkeys. So changed this to registerHotkeys which works correctly.
  hotkeyDefinitions.forEach(definition => hotkeysManager.registerHotkeys(definition, ''));
};
