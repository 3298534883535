import { cva } from 'class-variance-authority';

export const tabItemVariants = cva('flex h-full flex-row text-sm', {
  variants: {
    isSelected: {
      false: 'text-neutral-400 border-b-2 border-stone-900',
      true: 'text-neutral-50 border-b-2 border-blue-500',
    },
  },
});
