import { ExtensionManager } from '@ohif/core';
import { ExportsFromCornerStoneExtension } from '@prenuvo/core';

export function getImageSrcFromImageId(
  imageId: string,
  isSelectedStudy: boolean,
  extensionManger: ExtensionManager
) {
  const utilities = extensionManger.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.common'
  ) as ExportsFromCornerStoneExtension;
  const { cornerstone } = utilities.exports.getCornerstoneLibraries();
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const imageFetchParam = { canvas, imageId, thumbnail: true };
    if (isSelectedStudy) {
      imageFetchParam['priority'] = 1;
    }
    cornerstone.utilities
      .loadImageToCanvas(imageFetchParam)
      .then(imageId => {
        resolve(canvas.toDataURL());
      })
      .catch(reject);
  });
}
