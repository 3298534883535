import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@ohif/ui';
import { Icon as HaloIcon, cn, Stack, Typography } from '@prenuvo/halo-web';
import { SeriesInfoProps } from './ViewportTitleBar.types';
import { VIEWPORT_TITLE_BAR_TOOL_MAP } from './ViewportTitlebarToolMap';

export const SeriesInfo = ({
  seriesDescription,
  date,
  studyColor,
  priorPrefix,
}: SeriesInfoProps) => {
  const { formatMessage } = useIntl();

  return (
    <Stack className="paragraph-xs flex max-w-[calc(100%-177px)] items-center pl-[4px] text-[#CECECE]">
      <HaloIcon
        aria-label="tool-swap"
        source={VIEWPORT_TITLE_BAR_TOOL_MAP['tool-swap']}
        className={cn('mr-2 flex h-[25px] w-[25px] cursor-pointer items-center fill-[#A8A8A8]')}
        size="lg"
      />
      {seriesDescription && (
        <Tooltip
          content={seriesDescription}
          className="max-w-[calc(100%-41px)]"
        >
          <Stack className="truncate whitespace-nowrap">
            <Typography data-cy="viewport-title-series-desc">
              {formatMessage({ id: 'SERIES' })}: {seriesDescription}
            </Typography>
            {date && (
              <Typography data-cy="viewport-title-series-date">
                {formatMessage({ id: 'DATE' })}:{' '}
                <span style={{ color: studyColor }}>{`${priorPrefix} ${date}`}</span>
              </Typography>
            )}
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};
