import { PrenuvoHangingProtocolIds } from '@prenuvo/extension-default';
import { hpcomparison } from './hpcomparison';
import { hplayout } from './predefinedLayouts';
import { customProtocol } from './customLayout';
import { LOCAL_STORAGE_ITEMS } from '../utils';

const layoutProtocol = {
  ...hplayout,
  stages: localStorage.getItem(LOCAL_STORAGE_ITEMS.CUSTOM_LAYOUTS)
    ? [...hplayout.stages, ...customProtocol]
    : hplayout.stages,
};

export function getHangingProtocolModule() {
  return [
    {
      name: PrenuvoHangingProtocolIds.COMPARISON_MODE,
      protocol: hpcomparison,
    },
    {
      name: PrenuvoHangingProtocolIds.PREDEFINED_LAYOUT,
      protocol: layoutProtocol,
    },
  ];
}
