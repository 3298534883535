import { ServicesManager } from '@ohif/core';
import { PrenuvoServices } from '../../types';

let servicesManager: ServicesManager;

export function setServicesManager(serviceManager) {
  servicesManager = serviceManager;
}

export function _getDisplaySets() {
  const { displaySetService, patientStudyListService } =
    servicesManager.services as PrenuvoServices;
  const displaySets = [
    ...displaySetService.getDisplaySetsBy(
      ds => ds.StudyInstanceUID === patientStudyListService?.activeStudyUID
    ),
  ];
  const displaySetsToPrefetch = this._getSortedDisplaySetsToPrefetch(displaySets);
  return { displaySets, displaySetsToPrefetch };
}
