import moment from 'moment';
import { StudyInfo } from '../types/StudyListItem';

export function sortStudiesByDate(studies: StudyInfo[]) {
  return studies.sort((a, b) => {
    const dateA = moment(a.date, 'DD-MMM-YYYY');
    const dateB = moment(b.date, 'DD-MMM-YYYY');
    return dateB.diff(dateA);
  });
}

/**
 * Returns display sets ordered by seriesDate and seriesTime from the most recent to the oldest.
 * Display sets that does not have this information should be sorted at the end.
 */
export const sortBySeriesDateAndTime = (displaySetA, displaySetB) => {
  const { seriesDate: seriesDateA = 0, seriesTime: seriesTimeA = 0 } = displaySetA;
  const { seriesDate: seriesDateB = 0, seriesTime: seriesTimeB = 0 } = displaySetB;

  if (seriesDateA + seriesTimeA < seriesDateB + seriesTimeB) {
    return 1;
  } else {
    return -1;
  }
};
