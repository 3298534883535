import { getEnabledElement, triggerEvent, eventTarget } from '@cornerstonejs/core';
import { Enums } from '@cornerstonejs/tools';
import type { Types } from '@cornerstonejs/tools';

const Events = Enums.Events;
const ChangeTypes = Enums.ChangeTypes;

export function triggerAnnotationModified(
  annotation: Types.Annotation,
  element: HTMLDivElement,
  changeType = ChangeTypes.HandlesUpdated
): void {
  const enabledElement = getEnabledElement(element);
  const { viewportId, renderingEngineId } = enabledElement;
  const eventType = Events.ANNOTATION_MODIFIED;
  const eventDetail: Types.EventTypes.AnnotationModifiedEventDetail = {
    annotation,
    viewportId,
    renderingEngineId,
    changeType,
  };

  triggerEvent(eventTarget, eventType, eventDetail);
}
