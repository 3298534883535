import React from 'react';
import { Tooltip } from '@ohif/ui';
import { Icon as HaloIcon, cn } from '@prenuvo/halo-web';
import { VIEWPORT_TITLE_BAR_TOOL_MAP } from './ViewportTitlebarToolMap';
import { ViewportToolsItem } from './ViewportTitleBar.types';

interface ViewportToolIconProps {
  viewportToolbarItem: ViewportToolsItem;
}

export const ViewportToolButtonIcons: React.FC<ViewportToolIconProps> = ({
  viewportToolbarItem,
}) => {
  const {
    id,
    icon,
    label,
    disabled,
    disabledText,
    toolTipPosition,
    toolTipDisabled,
    onButtonClick,
    commands,
  } = viewportToolbarItem;
  return (
    <div
      onClick={() => {
        onButtonClick(id, commands);
      }}
    >
      <Tooltip
        content={disabled ? disabledText || 'Button Disabled' : label || 'Missing Label'}
        position={toolTipPosition || 'bottom'}
        isDisabled={toolTipDisabled || false}
      >
        {VIEWPORT_TITLE_BAR_TOOL_MAP[icon] ? (
          <HaloIcon
            aria-label={icon}
            source={VIEWPORT_TITLE_BAR_TOOL_MAP[icon]}
            className={cn(
              'hover:fill[#00A5EB] flex h-[25px] w-[25px] cursor-pointer items-center justify-center fill-[#A8A8A8]'
            )}
            size="lg"
          />
        ) : (
          <div>{label || 'Missing Icon'}</div>
        )}
      </Tooltip>
    </div>
  );
};
