// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-button input:checked ~ .switch-button-outer {
  border-color: hsla(198, 100%, 46%, 1)
}
.switch-button input:checked ~ .switch-button-dot {
  background-color: hsla(198, 100%, 46%, 1);
  -webkit-transform: translateX(12px);
          transform: translateX(12px)
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/SwitchButton/switchButton.css"],"names":[],"mappings":"AACE;EAAA;AAA2B;AAG3B;EAAA,yCAAuB;EACvB,mCAA2B;UAA3B;AADuB","sourcesContent":[".switch-button input:checked ~ .switch-button-outer {\n  @apply border-primary-light;\n}\n.switch-button input:checked ~ .switch-button-dot {\n  @apply bg-primary-light;\n  transform: translateX(12px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
