import React, { useCallback } from 'react';
import { Stack, Typography } from '@prenuvo/halo-web';
import { cn } from '@prenuvo/halo-web';
import { LayoutSelector as OHIFLayoutSelector } from '@ohif/ui';
import { LayoutPreviewBuilder } from './layout-preview-builder';
import { LayoutSelectorProps } from './layout-selector.type';

export const LayoutSelector: React.FC<LayoutSelectorProps> = ({
  commandsManager,
  layoutItems,
  layOutProtocolName,
  activeHangingProtocol,
  stageName,
  onClickHandler,
}) => {
  const handleOhifLayoutSelectorClick = useCallback(
    (props: object) => {
      commandsManager.run({
        commandName: 'setViewportGridLayout',
        commandOptions: { ...props },
      });
    },
    [commandsManager]
  );

  return (
    <Stack className={cn('z-20 inline-block rounded-[8px] border border-neutral-800 bg-stone-900')}>
      <Stack
        className={cn(
          'border-b-1 h-[40px] border border-t-0 border-l-0 border-r-0 border-neutral-800'
        )}
      >
        <Typography
          className={cn('ml-2 flex select-none items-center text-[12px] font-bold text-[#CECECE]')}
        >
          Layout
        </Typography>
      </Stack>
      <Stack className={cn('p-[12px]')}>
        <div className={cn('grid gap-[8px]', `grid-cols-3`)}>
          {layoutItems.map((item, key) => (
            <LayoutPreviewBuilder
              key={key}
              layoutItem={item}
              layOutProtocolName={layOutProtocolName}
              activeHangingProtocol={activeHangingProtocol}
              stageName={stageName}
              onClick={() => {
                onClickHandler(key);
              }}
            />
          ))}

          <OHIFLayoutSelector onSelection={handleOhifLayoutSelectorClick} />
        </div>
      </Stack>
    </Stack>
  );
};
