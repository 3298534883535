import * as Halo from '@prenuvo/halo-icon';
import { ThreeVerticalDots, Collapse } from '../icons';

export const TOOL_MAP = {
  'tool-ellipse': Halo.Ellipse,
  'tool-length': Halo.Length,
  'tool-bidirectional': Halo.Bidirectional,
  'tool-annotate': Halo.Annotate,
  'tool-angle': Halo.Angle,
  'tool-reset': Halo.ResetView,
  'tool-rotate-right': Halo.ArrowUpTurnRight,
  'tool-flip-horizontal': Halo.ArrowFlipRight,
  'tool-invert': Halo.Invert,
  'tool-cine': Halo.Cine,
  'tool-window-level': Halo.Wl,
  'tool-zoom': Halo.Zoom,
  'tool-expand': Halo.Expand2,
  'tool-crosshair': Halo.Crosshair,
  'tool-stack-scroll': Halo.Stack,
  'tool-mpr': Halo.Mpr,
  'tool-compare': Halo.Compare,
  'tool-capture': Halo.Camera,
  'tool-magnify': Halo.Magnify,
  'tool-layout': Halo.Grid,
  'tool-key-image': Halo.Image,
  'tool-measure-tab': Halo.Measure,
  // TODO: Update this icon from Halo when available
  // special case where this icon is missing
  'tool-more': ThreeVerticalDots,
  'tool-collapse': Collapse,
  'tool-spine': Halo.SpineMd,
};
