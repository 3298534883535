import React from 'react';
import { Switch, Stack, Icon, Typography, cn } from '@prenuvo/halo-web';
import { CommandsManager, HotkeysManager } from '@ohif/core';
import { CursorTooltip } from '@prenuvo/ui';
import {
  COMMANDS,
  DEFAULT_SUPPORTED_TOOLS,
  getStudyUidFromUrl,
  getToolsToggleMenuItems,
  TOOL_NAMES,
  TOOLS_TOGGLE_STATE,
  ToolsToggleMenuItemsProps,
} from '@prenuvo/extension-default';
import './ToolsToggleMenu.css';

const MENU_BACKGROUND_COLOR = '#1C1C1E';
const ICON_COLOR = '#999';

interface ToolsToggleMenuProps {
  hotkeysManager: HotkeysManager;
  commandsManager: CommandsManager;
  toolsToggleState: object;
  setToolsToggleState: (value: object | null) => void;
}

export const ToolsToggleMenu: React.FC<ToolsToggleMenuProps> = ({
  commandsManager,
  hotkeysManager,
  toolsToggleState,
  setToolsToggleState,
}): JSX.Element => {
  const toolsToggleMenuItems = getToolsToggleMenuItems(hotkeysManager);

  const handleToggleClick = (menuItem: ToolsToggleMenuItemsProps) => {
    setToolsToggleState(prevToolsToggleStates => {
      switch (menuItem.commandName) {
        case COMMANDS.TOGGLE_LOAD_MEASUREMENTS:
          commandsManager.run({
            commandName: COMMANDS.TOGGLE_LOAD_MEASUREMENTS,
            commandOptions: {
              activeStudyUID: getStudyUidFromUrl(),
              toolsToIgnore: [TOOL_NAMES.SPINELABEL],
            },
          });
          break;
        case COMMANDS.TOGGLE_LOAD_SPINE_LABELS:
          commandsManager.run({
            commandName: COMMANDS.TOGGLE_LOAD_SPINE_LABELS,
            commandOptions: {
              activeStudyUID: getStudyUidFromUrl(),
              toolsToIgnore: DEFAULT_SUPPORTED_TOOLS,
            },
          });
          break;
        case COMMANDS.TOGGLE_STACK_SYNC_SCROLL:
          commandsManager.run({
            commandName: COMMANDS.TOGGLE_STACK_SYNC_SCROLL,
          });
          break;
        default:
          console.warn(`Unhandled command name: ${menuItem.commandName}`);
          break;
      }

      return {
        ...prevToolsToggleStates,
        [menuItem.id]:
          prevToolsToggleStates[menuItem.id] === TOOLS_TOGGLE_STATE.SHOW
            ? TOOLS_TOGGLE_STATE.HIDE
            : TOOLS_TOGGLE_STATE.SHOW,
      };
    });
  };

  return (
    <Stack
      className={cn(
        `flex w-[291px] flex-col rounded-[4px] bg-[${MENU_BACKGROUND_COLOR}] py-[12px]`
      )}
    >
      {toolsToggleMenuItems.map((menuItem: ToolsToggleMenuItemsProps, index: number) => {
        return (
          <Stack
            key={index}
            className={cn(
              'border-b-1 relative border border-t-0 border-l-0 border-r-0 border-neutral-800'
            )}
            onClick={() => handleToggleClick(menuItem)}
          >
            <CursorTooltip content={menuItem.hotkey}>
              <Stack
                className={cn(
                  'flex h-[56px] w-[291px] flex-row items-center justify-between gap-[12px] px-[8px] py-[12px]'
                )}
              >
                <Stack className="flex items-center">
                  <Icon
                    className={cn(
                      `text-primary-active h-[20px] w-[20px] fill-[${ICON_COLOR}] stroke-1`
                    )}
                    source={menuItem.icon}
                  />
                  <Typography className="text-white">{menuItem.name}</Typography>
                </Stack>
                <Stack className={cn('width-[48px] height-[48px] top-[-8px] left-[12px]')}>
                  <Switch
                    className={
                      toolsToggleState[menuItem.id] === TOOLS_TOGGLE_STATE.SHOW
                        ? 'toolsToggleActiveSwitch'
                        : 'toolsToggleInactiveSwitch'
                    }
                    isChecked={toolsToggleState[menuItem.id] === TOOLS_TOGGLE_STATE.SHOW}
                  />
                </Stack>
              </Stack>
            </CursorTooltip>
          </Stack>
        );
      })}
    </Stack>
  );
};
