import { UINotificationService } from '@ohif/core';

export const notifyProtocolJarNotFound = (uiNotificationService: UINotificationService) => {
  uiNotificationService.show({
    title: 'Protocol jar unavailable',
    message: 'No matching protocol jar found for the study.',
    type: 'error',
    autoClose: false,
  });
};
