import { annotation } from '@cornerstonejs/tools';
import { Measurement, Annotation } from '../types';

type FrameOfReferenceAnnotations = Record<string, Annotation[]>;

function addOldMeasurementsToFrameOfReference(
  measurementData: Measurement[],
  frameOfReferenceAnnotations: FrameOfReferenceAnnotations
): FrameOfReferenceAnnotations {
  measurementData.forEach(measurement => {
    if (measurement.isOldMeasurement) {
      const toolName = measurement.toolName;

      if (!frameOfReferenceAnnotations[toolName]) {
        frameOfReferenceAnnotations[toolName] = [];
      }

      const newAnnotation: Annotation = {
        annotationUID: measurement.uid,
        metadata: {
          toolName: toolName,
          FrameOfReferenceUID: measurement.FrameOfReferenceUID,
          referencedImageId: measurement.referencedImageId,
        },
        data: {
          handles: {
            points: measurement.points,
            textBox: measurement.textBox,
          },
          cachedStats: measurement.data,
          label: measurement.label || '',
        },
        highlighted: false,
        invalidated: false,
        isLocked: false,
        isVisible: true,
      };

      frameOfReferenceAnnotations[toolName].push(newAnnotation);
    }
  });

  return frameOfReferenceAnnotations;
}

function removeOldMeasurementsFromAnnotations(measurementData: Measurement[]): void {
  const annotationManager = annotation.state.getAnnotationManager();
  measurementData.forEach(measurement => {
    if (measurement.isOldMeasurement) {
      annotationManager.removeAnnotation(measurement.uid);
    }
  });
}

export function getAddOldMeasurementsToFrameOfReference() {
  return {
    id: 'addOldMeasurementsToFrameOfReference',
    addOldMeasurementsToFrameOfReference,
  };
}

export function getRemoveOldMeasurementsFromAnnotations() {
  return {
    id: 'removeOldMeasurementsFromAnnotations',
    removeOldMeasurementsFromAnnotations,
  };
}
