import React, { useState, useEffect } from 'react';
import {
  COMMANDS,
  getImageSrcFromImageId,
  MODALITIES,
  notifyViewportUpdateFailed,
} from '../../utils';
import { PrenuvoServices, Viewport } from '../../types';
import { KeyImageThumbnail } from './KeyImageThumbnail';
import { KeyImagePanelProps } from './KeyImageTypes';
import { DisplaySetService, ExtensionManager, HangingProtocolService } from '@ohif/core';
import { DicomDataset } from 'dcmjs';

const getKoDisplaySet = (
  displaySetService: DisplaySetService,
  hangingProtocolService: HangingProtocolService
): DicomDataset | null => {
  const { activeStudy } = hangingProtocolService?.getActiveProtocol();
  const activeDisplaySets: DicomDataset = displaySetService.activeDisplaySets;

  const currentStudyKoDisplaySet: DicomDataset | null =
    activeDisplaySets?.find(
      displaySet =>
        displaySet.Modality === MODALITIES.KO &&
        displaySet.studyInstanceUid === activeStudy?.StudyInstanceUID
    ) || null;

  return currentStudyKoDisplaySet;
};

const handleSetThumbnails = async (
  imageId: string,
  extensionManager: ExtensionManager,
  setThumbnails: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const imageSrc = (await getImageSrcFromImageId(imageId, false, extensionManager)) as string;
  setThumbnails(prevThumbnails => [...prevThumbnails, imageSrc]);
};

export function KeyImagePanel({
  commandsManager,
  extensionManager,
  servicesManager,
}: KeyImagePanelProps) {
  const { displaySetService, hangingProtocolService, viewportGridService, uiNotificationService } =
    servicesManager.services as PrenuvoServices;

  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState<number | null>(null);
  const [displaySetInstanceUid, setDisplaySetInstanceUid] = useState<string>('');

  useEffect(() => {
    const koDisplaySet = getKoDisplaySet(displaySetService, hangingProtocolService);

    if (koDisplaySet) {
      setDisplaySetInstanceUid(koDisplaySet.displaySetInstanceUID);

      const loadThumbnails = async () => {
        try {
          const thumbnailPromises = koDisplaySet.images.map(koImage =>
            handleSetThumbnails(koImage.imageId, extensionManager, setThumbnails)
          );

          await Promise.all(thumbnailPromises);
        } catch (error) {
          console.error('Error loading thumbnails:', error);
        }
      };

      loadThumbnails();
    }
  }, [displaySetService, extensionManager, hangingProtocolService]);

  useEffect(() => {
    const { unsubscribe } = displaySetService.subscribe(
      displaySetService.EVENTS.DISPLAY_SETS_ADDED,
      async () => {
        const koDisplaySet = getKoDisplaySet(displaySetService, hangingProtocolService);
        if (koDisplaySet) {
          const newlyAddedKoImage: DicomDataset = koDisplaySet.images?.at(-1);
          await handleSetThumbnails(newlyAddedKoImage.imageId, extensionManager, setThumbnails);
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [displaySetService, extensionManager, hangingProtocolService]);

  const handleSelectedThumbnail = async (index: number) => {
    setSelectedThumbnailIndex(index);
    const { activeViewportId } = viewportGridService?.getState();
    const jumpToIndex: { imageIndex: number; viewport: Viewport } = {
      imageIndex: index,
      viewport: undefined,
    };

    try {
      const updatedViewports = hangingProtocolService?.getViewportsRequireUpdate(
        activeViewportId,
        displaySetInstanceUid
      );
      viewportGridService?.setDisplaySetsForViewports(updatedViewports);
    } catch (error) {
      console.warn(error);
      notifyViewportUpdateFailed(uiNotificationService);
    }

    setTimeout(() => {
      commandsManager.runCommand(COMMANDS.JUMP_TO_IMAGE, jumpToIndex);
    });
  };

  return (
    <div className="ohif-scrollbar invisible-scrollbar flex flex-1 flex-col overflow-auto">
      {thumbnails.map((thumbnail, index) => (
        <KeyImageThumbnail
          key={index}
          imageIndex={index}
          thumbnail={thumbnail}
          handleSelectedThumbnail={handleSelectedThumbnail}
          isSelected={selectedThumbnailIndex === index}
          displaySetInstanceUid={displaySetInstanceUid}
        />
      ))}
    </div>
  );
}
