import { AXIS, DIMENSIONS, DIRECTIONS, PrenuvoTypes } from '@prenuvo/extension-default';
import { NeighborInfo } from '../../types';

/**
 * Updates the layout configuration by merging the dimensions of neighboring viewports
 * with the active viewport and adjusts their positions accordingly.
 *
 * @param viewportIndexToClose - The index of the currently active viewport in the layout.
 * @param layoutOptions - An array of layout configurations for each viewport.
 * @param neighbors - An array of NeighborInfo objects indicating the neighboring viewports and their directions.
 * @param viewportArray - An array of viewport IDs used to locate the indexes of neighbors.
 * @returns - Returns updated layout options
 */
export const getLayoutOptionsAfterClose = (
  viewportIndexToClose: number,
  layoutOptions: PrenuvoTypes.LayoutOption[],
  neighbors: NeighborInfo[],
  viewportArray: string[]
) => {
  const newLayoutOptions = [...layoutOptions];
  const { width, height, x, y } = newLayoutOptions[viewportIndexToClose];
  neighbors.forEach(neighbor => {
    const neighborIndex = viewportArray.indexOf(neighbor.viewportId);
    if (neighbor.direction === DIRECTIONS.TOP) {
      newLayoutOptions[neighborIndex][DIMENSIONS.HEIGHT] += height;
    } else if (neighbor.direction === DIRECTIONS.BOTTOM) {
      newLayoutOptions[neighborIndex][DIMENSIONS.HEIGHT] += height;
      newLayoutOptions[neighborIndex][AXIS.Y] = y;
    } else if (neighbor.direction === DIRECTIONS.LEFT) {
      newLayoutOptions[neighborIndex][DIMENSIONS.WIDTH] += width;
    } else if (neighbor.direction === DIRECTIONS.RIGHT) {
      newLayoutOptions[neighborIndex][DIMENSIONS.WIDTH] += width;
      newLayoutOptions[neighborIndex][AXIS.X] = x;
    }
  });
  newLayoutOptions.splice(viewportIndexToClose, 1);
  return newLayoutOptions;
};
