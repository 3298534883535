import React, { useState } from 'react';
import { ThumbnailSkeleton } from './thumbnail-skeleton';
import type { ThumbnailImageProps } from './thumbnail.types';

export const ThumbnailImage = ({ imageSrc, imageAltText, isHorizontal }: ThumbnailImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  /*
  For certain hanging protocols, the service will build up
  a large array over time and initially the thumbnails will have an undefined imageSrc
  until the service is finished building the array and all the thumbnails are hydrated with imageSrc.

  We can use this undefined imageSrc to display a loading skeleton.
  */

  return (
    <>
      {!isImageLoaded || !imageSrc ? <ThumbnailSkeleton /> : null}
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={imageAltText}
          // maintain aspect ratio for thumbnail
          {...(!isHorizontal ? { height: '96px' } : {})}
          {...(isHorizontal ? { width: '96px' } : {})}
          onLoad={() => setIsImageLoaded(true)}
        />
      ) : null}
    </>
  );
};

ThumbnailImage.displayName = 'ThumbnailImage';
