import { Types as coreTypes } from '@ohif/core';

type Instances = coreTypes.InstanceMetadata & {
  ContentDate: number;
  ContentTime: number;
};
export function getLatestInstance(instances: Instances[] | coreTypes.InstanceMetadata[]) {
  return instances.reduce(
    (acc, item) =>
      item.ContentDate + item.ContentTime > acc.ContentDate + acc.ContentTime ? item : acc,
    {
      ContentDate: 0,
      ContentTime: 0,
      SOPInstanceUID: '',
      InstanceNumber: '',
    }
  );
}
