import { ServicesManager, CommandsManager, PubSubService } from '@ohif/core';
import { CustomDefaultUserPreferences, UserPreferencesState } from './types/userPreferences';
import { getDefaultUserPreferences } from './getDefaultUserPreferences';

const EVENTS = {
  // load
  USER_PREFERENCES_LOAD_COMPLETE: 'event::userPreferencesService:UserPreferencesLoadComplete',
  USER_PREFERENCES_LOAD_ERROR: 'event::userPreferencesService:UserPreferencesLoadError',
  // create
  USER_PREFERENCES_CREATE_COMPLETE: 'event::userPreferencesService:UserPreferencesCreateComplete',
  USER_PREFERENCES_CREATE_ERROR: 'event::userPreferencesService:UserPreferencesCreateError',
  // update
  USER_PREFERENCES_UPDATE_COMPLETE: 'event::userPreferencesService:UserPreferencesCreateComplete',
  USER_PREFERENCES_UPDATE_ERROR: 'event::userPreferencesService:UserPreferencesCreateError',
  // reset
  USER_PREFERENCES_RESET_COMPLETE: 'event::userPreferencesService:UserPreferencesResetComplete',
  USER_PREFERENCES_RESET_ERROR: 'event::userPreferencesService:UserPreferencesResetError',
};

/**
 * UserPreferencesService class that supports management of user defined settings.
 * The backend API is not ready yet, so we are using static data for now to simulate the API calls.
 * There are 4 main groups of user preferences:
 * - `hotkeyBindings`: Bindings for user-configured hotkeys.
 * - `mouseBindings`: Bindings for user-configured mouse.
 * - `hangingProtocolSettings`: Settings related to the hanging protocol.
 * - `textOverlaySettings`: Settings for text overlays.
 * We create one json payload for all these preferences and store them in the database as a single document.
 */
export class UserPreferencesService extends PubSubService {
  public static REGISTRATION = {
    name: 'userPreferencesService',
    altName: 'UserPreferencesService',
    create: ({ commandsManager, servicesManager }) => {
      return new UserPreferencesService(servicesManager, commandsManager);
    },
  };

  // the service should provide both the custom and default (potentially admin managed) user preferences
  userPreferences: CustomDefaultUserPreferences = { custom: null, default: null };

  _servicesManager: ServicesManager;
  _commandsManager: CommandsManager;

  constructor(servicesManager: ServicesManager, commandsManager: CommandsManager) {
    super(EVENTS);
    this._servicesManager = servicesManager;
    this._commandsManager = commandsManager;
  }

  async loadDefaultUserPreferences() {
    try {
      /*
      once the API is ready, we can fetch the default user preferences from the server

      const userPreferences = await this._servicesManager.services.httpService.get(
        '/user-preferences/default'
      );
      */

      const userPreferences = getDefaultUserPreferences();
      this.userPreferences = { custom: userPreferences, default: userPreferences };
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_LOAD_COMPLETE, null);
    } catch (error) {
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_LOAD_ERROR, {
        message: 'Error loading default user preferences - invalid response',
      });
    }
  }

  async createUserPreferences(userId, updateUserPreferences) {
    try {
      // TODO: Once the service is ready, implement the API call to create the user preferences
      /*
      const userPreferences = await this._servicesManager.services.httpService.post(
        '/user-preferences',
        { userId, updateUserPreferences }
      );*/
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_CREATE_COMPLETE, null);
    } catch (error) {
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_CREATE_ERROR, {
        message: 'Error creating user preferences - invalid response',
      });
    }
  }

  async loadUserPreferences(userId: string) {
    try {
      const userPreferences =
        await this._servicesManager.services.httpService.get('/user-preferences');
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_LOAD_COMPLETE, userPreferences);
    } catch (error) {
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_LOAD_ERROR, {
        message: 'Error loading user preferences',
      });
    }
  }

  async updateUserPreferences(userId, updatedUserPreferences) {
    // validate userId
    if (!userId) {
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_LOAD_ERROR, {
        message: 'Error updating preferences - invalid user id',
      });
    }

    // TODO: once we have a full schema, we check for the presence of all required keys not just the top level keys
    if (
      !updatedUserPreferences ||
      !updatedUserPreferences.hotkeyBindings ||
      !updatedUserPreferences.mouseBindings ||
      !updatedUserPreferences.hangingProtocolPreferences ||
      !updatedUserPreferences.textOverlayPreferences
    ) {
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_UPDATE_ERROR, {
        message: 'Error updating preferences - invalid user preferences',
      });
    }

    console.log('updateUserPreferences pass pref validation');

    try {
      /* TODO: when it's ready, implement the API call to update the user preferences
      const userPreferences = await this._servicesManager.services.httpService.put(
        '/user-preferences',
        { userId, updatedUserPreferences }
      );
      */
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_UPDATE_COMPLETE, {
        custom: updatedUserPreferences,
        default: getDefaultUserPreferences(),
      });
    } catch (error) {
      this._broadcastEvent(this.EVENTS.USER_PREFERENCES_UPDATE_COMPLETE, {
        message: 'Error updating user preferences - invalid response',
      });
    }
  }

  public getState(): UserPreferencesState {
    if (!this.userPreferences) {
      return;
    }
    return {
      custom: this.userPreferences.custom,
      default: this.userPreferences.default,
      fallbackDefaults: getDefaultUserPreferences(),
    };
  }
}
