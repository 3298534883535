import React, { useState } from 'react';
import { Stack, Icon, Typography, cn } from '@prenuvo/halo-web';
import * as Halo from '@prenuvo/halo-icon';
import { StateSyncService, UIModalService } from '@ohif/core';
import { Types } from '@cornerstonejs/tools';

interface SetInitialLabelModalProps {
  uiModalService: UIModalService;
  toolGroup: Types.IToolGroup;
  toolName: string;
  stateSyncService: StateSyncService;
}

export const SetInitialSpineLabelModal: React.FC<SetInitialLabelModalProps> = ({
  uiModalService,
  toolGroup,
  toolName,
  stateSyncService,
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<number>(0);
  const [selectedVertebrae, setSelectedVertebrae] = useState<number>(0);

  const handleButtonClick = () => {
    toolGroup?.setToolConfiguration(toolName, {
      selectedSpineLabel: spineLabels[selectedMenuItem].subItems[selectedVertebrae],
    });
    stateSyncService.store({
      spineLabelStates: {
        isFirstVertebraSelected: true,
      },
    });
    uiModalService.hide();
  };

  const spineLabels = [
    {
      menuItem: 'Cervical',
      menuIcon: Halo.SpineMd,
      subItems: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7'],
    },
    {
      menuItem: 'Thoracic',
      menuIcon: Halo.SpineMd,
      subItems: ['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'T10', 'T11', 'T12'],
    },
    {
      menuItem: 'Lumbar',
      menuIcon: Halo.SpineMd,
      subItems: ['L1', 'L2', 'L3', 'L4', 'L5'],
    },
    {
      menuItem: 'Sacral',
      menuIcon: Halo.SpineMd,
      subItems: ['S1', 'S2', 'S3', 'S4', 'S5'],
    },
  ];

  return (
    <Stack className={cn('flex flex-col')}>
      <Stack className="flex">
        <Stack className="flex-col gap-2 text-sm font-medium text-gray-500">
          {spineLabels.map((item, key) => {
            return (
              <Stack
                key={key}
                className={cn(
                  `inline-flex h-[50px] w-full items-center bg-gray-800 px-4 py-3 text-white hover:bg-blue-500`,
                  { 'bg-blue-500': key === selectedMenuItem }
                )}
                onClick={() => {
                  setSelectedMenuItem(key);
                }}
              >
                <Icon
                  className={cn(`text-primary-active fill-grey-400 h-[20px] w-[20px] stroke-1`)}
                  source={item.menuIcon}
                />
                <Typography className="text-green-400">{item.menuItem}</Typography>
              </Stack>
            );
          })}
        </Stack>
        <Stack className={cn('text-medium flex h-[224px] w-full flex-col overflow-y-auto')}>
          {spineLabels[selectedMenuItem].subItems.map((item, key) => {
            return (
              <Stack
                key={key}
                className={cn('w-full bg-gray-800 px-4 py-2 text-green-400 hover:bg-blue-500', {
                  'bg-blue-500': key === selectedVertebrae,
                })}
                onClick={() => {
                  setSelectedVertebrae(key);
                }}
              >
                <Typography className="text-green-400">{item}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Stack className="mt-2 flex justify-between">
        <Typography className="mt-2 text-lg font-bold text-green-400">{`Selected Vertebrae : ${spineLabels[selectedMenuItem].menuItem} - ${spineLabels[selectedMenuItem].subItems[selectedVertebrae]}`}</Typography>
        <button
          type="button"
          className="me-2 text-md mb-2 bg-gray-800 px-5 py-2.5 font-medium text-white hover:bg-blue-500"
          onClick={handleButtonClick}
        >
          Proceed
        </button>
      </Stack>
    </Stack>
  );
};
