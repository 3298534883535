import { StateSyncService, UIModalService } from '@ohif/core';
import { SetInitialSpineLabelModal } from './ui';
import { Types } from '@cornerstonejs/tools';

export const setInitialLabel = (
  uiModalService: UIModalService,
  toolGroup: Types.IToolGroup,
  toolName: string,
  stateSyncService: StateSyncService
) => {
  if (uiModalService) {
    uiModalService.show({
      content: SetInitialSpineLabelModal,
      title: 'Select a Vertebra to Begin Labeling',
      closeButton: false,
      shouldCloseOnEsc: false,
      contentProps: {
        uiModalService,
        toolGroup,
        toolName,
        stateSyncService,
      },
      containerDimensions: 'w-[100%] max-w-[600px]',
    });
  }
};
