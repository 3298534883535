import { HotkeysManager, Types as CoreTypes } from '@ohif/core';
import { COMMANDS, TOGGLE_TOOLS } from './constants';
import * as Halo from '@prenuvo/halo-icon';
import React from 'react';

export interface ToolsToggleMenuItemsProps {
  id: string;
  commandName: string;
  name: string;
  icon: React.FC;
  hotkey: string;
}

function getHotkeys(hotkeysManager: HotkeysManager, commandName: string) {
  const { hotkeyDefinitions } = hotkeysManager as HotkeysManager & {
    hotkeyDefinitions: CoreTypes.Hotkey[];
  };
  const matchingHotkey = Object.values(hotkeyDefinitions).find(
    hotkey => hotkey.commandName === commandName
  );

  if (matchingHotkey && matchingHotkey.keys) {
    return matchingHotkey.keys.join(' + ');
  }
  return;
}

export function getToolsToggleMenuItems(
  hotkeysManager: HotkeysManager
): ToolsToggleMenuItemsProps[] {
  const toolsToggleMenuItems = [
    {
      id: TOGGLE_TOOLS.MEASUREMENTS_VISIBILITY_STATE,
      commandName: COMMANDS.TOGGLE_LOAD_MEASUREMENTS,
      name: 'Measurements',
      icon: Halo.Measure,
      hotkey: getHotkeys(hotkeysManager, COMMANDS.TOGGLE_LOAD_MEASUREMENTS),
    },
    {
      id: TOGGLE_TOOLS.STACK_SYNC_TOOL_STATE,
      commandName: COMMANDS.TOGGLE_STACK_SYNC_SCROLL,
      name: 'Stack Image Sync',
      icon: Halo.Stack,
      hotkey: getHotkeys(hotkeysManager, COMMANDS.TOGGLE_STACK_SYNC_SCROLL),
    },
    {
      id: TOGGLE_TOOLS.SPINE_LABELS_VISIBILITY_STATE,
      commandName: COMMANDS.TOGGLE_LOAD_SPINE_LABELS,
      name: 'Spine Labels',
      icon: Halo.Spine,
    },
  ];

  return toolsToggleMenuItems;
}
