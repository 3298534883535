import React, { FC, useEffect, createContext } from 'react';
import { ViewportPane, ViewportPaneProps, ColorSchemes } from '@prenuvo/ui';
import { PrenuvoServices } from '@prenuvo/extension-default';
import { getStudyContext } from './ViewportPaneWrapper.util';

export const StudyContext = createContext({ studyColor: ColorSchemes.Primary, priorPrefix: '' });
const StudyContextProvider = StudyContext.Provider;

export const ViewportPaneWrapper: FC<ViewportPaneProps> = ({
  children,
  className,
  customStyle,
  isActive,
  onDrop,
  onDoubleClick,
  onInteraction,
  acceptDropsFor,
  displaySets,
  viewportId,
  servicesManager,
}) => {
  const { patientStudyListService, stateSyncService } =
    servicesManager?.services as PrenuvoServices;
  const patientStudies = patientStudyListService.studies || [];
  const displaySetStudyInstanceUID =
    displaySets[0]?.studyInstanceUid || displaySets[0]?.StudyInstanceUID;

  const { studyColor, priorPrefix } = getStudyContext(patientStudies, displaySetStudyInstanceUID);

  useEffect(() => {
    if (viewportId) {
      const currentState = stateSyncService?.getState().viewportOverlayColor || {};
      const updatedState = {
        ...currentState,
        [viewportId]: {
          studyColor: studyColor,
        },
      };
      stateSyncService?.store({
        viewportOverlayColor: updatedState,
      });
    }
  }, [viewportId, studyColor, stateSyncService]);

  return (
    <ViewportPane
      className={className}
      customStyle={customStyle}
      isActive={isActive}
      onDrop={onDrop}
      onDoubleClick={onDoubleClick}
      onInteraction={onInteraction}
      acceptDropsFor={acceptDropsFor}
      displaySets={displaySets}
      studyColor={studyColor}
      viewportId={viewportId}
    >
      <StudyContextProvider value={{ studyColor, priorPrefix }}>{children}</StudyContextProvider>
    </ViewportPane>
  );
};
