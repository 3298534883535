import React, { FC } from 'react';
import { ToolbarButton } from '@prenuvo/ui';
import { HotkeysManager, Types as CoreTypes } from '@ohif/core';
import { PrenuvoTypes } from '@prenuvo/extension-default';

interface ToolbarButtonWrapperProps extends PrenuvoTypes.OhifToolbarButtonProps {
  hotkeysManager: HotkeysManager;
}

/**
 * A wrapper component that enhances the ToolbarButton with tooltip label which has hotkey information.
 *
 * @param {ToolbarButtonWrapperProps} props - The properties for the ToolbarButtonWrapper component.
 * @returns {JSX.Element} The rendered ToolbarButton with hotkey information.
 */
export const ToolbarButtonWrapper: FC<ToolbarButtonWrapperProps> = ({
  id,
  icon,
  label,
  commands,
  onInteraction,
  dropdownContent = null,
  className,
  disabled,
  disabledText,
  size,
  toolTipClassName,
  disableToolTip = false,
  isAlignedBottom = true,
  ...rest
}) => {
  const { hotkeyDefinitions } = rest?.hotkeysManager as HotkeysManager & {
    hotkeyDefinitions: CoreTypes.Hotkey[];
  };

  let labelWithHotkey = label;

  if (hotkeyDefinitions) {
    const matchingHotkey = Object.values(hotkeyDefinitions).find(h => h.label === label);

    if (matchingHotkey?.keys) {
      labelWithHotkey += ' (' + matchingHotkey.keys.join(' + ') + ')';
    }
  }

  return (
    <ToolbarButton
      id={id}
      icon={icon}
      label={labelWithHotkey}
      commands={commands}
      onInteraction={onInteraction}
      dropdownContent={dropdownContent}
      className={className}
      disabled={disabled}
      disabledText={disabledText}
      size={size}
      toolTipClassName={toolTipClassName}
      disableToolTip={disableToolTip}
      isAlignedBottom={isAlignedBottom}
      {...rest}
    />
  );
};
